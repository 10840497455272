export const MuiAutoComplete = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiAutocomplete-popupIndicator': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '& .MuiAutocomplete-clearIndicator': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
};
