import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { Add as AddIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';

import CustomButton from 'components/Button';
import { WorklogDayType } from 'types/WorkLogTypes';
interface PredefinedData {
  date?: Dayjs;
  startTime?: Dayjs;
  endTime?: Dayjs;
}
interface AddWorklogCardProps {
  data: WorklogDayType;
  showAddWorklog: () => void;
  setPredefinedData: (data: PredefinedData) => void;
}

const AddWorklogCard = ({ data, showAddWorklog, setPredefinedData }: AddWorklogCardProps) => {
  const theme = useTheme();
  const latestWorklog = data.worklogs[data.worklogs.length - 1];

  return (
    <CustomButton
      variant="outlined"
      text="Add Worklog"
      endIcon={<AddIcon sx={{ color: theme.palette.busmanColors.lightGrey, width: '15px', fontWeight: 'bold' }} />}
      width="100%"
      toolTip={`Add Worklog ${dayjs(data.date).format('dddd, Do')}`}
      onClick={() => {
        showAddWorklog();
        setPredefinedData({
          date: dayjs(data.date),
          startTime: dayjs(latestWorklog?.end_time),
          endTime: dayjs(latestWorklog?.end_time).isBefore(dayjs(latestWorklog?.date).set('hour', 23))
            ? dayjs(latestWorklog?.end_time).add(1, 'hour')
            : dayjs(latestWorklog?.end_time),
        });
      }}
    />
  );
};

export default AddWorklogCard;
