import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { Add as AddIcon } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

import CustomButton from 'components/Button';
import { WorklogDayBox, WorklogDayHeader } from 'components/WorklogDayCard/styles';
import { WorklogDayType } from 'types/WorkLogTypes';
interface PredefinedData {
  date?: Dayjs;
  startTime?: Dayjs;
  endTime?: Dayjs;
}
interface EmptyWorklogDayCardProps {
  data: WorklogDayType;
  showAddWorklog: () => void;
  setPredefinedData: (data: PredefinedData) => void;
}

const EmptyWorklogDayCard = ({ data, showAddWorklog, setPredefinedData }: EmptyWorklogDayCardProps) => {
  const theme = useTheme();

  return (
    <WorklogDayBox>
      <WorklogDayHeader>
        <Box display="flex" alignItems="flex-end">
          <Typography variant="subtitle1">{dayjs(data.date).format('ddd, D MMM, YYYY')}</Typography>
        </Box>
        <Box display="flex" alignItems="flex-end" gap={theme.spacing(2)}>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              BILLABLE HRS
            </Typography>
            <Typography variant="subtitle1">0</Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              TOTAL HRS
            </Typography>
            <Typography variant="subtitle1">0</Typography>
          </Box>
        </Box>
      </WorklogDayHeader>
      <CustomButton
        variant="outlined"
        text="Add Worklog"
        endIcon={<AddIcon sx={{ color: theme.palette.busmanColors.lightGrey, width: '15px', fontWeight: 'bold' }} />}
        width="100%"
        height="208px"
        toolTip={`Add Worklog ${dayjs(data.date).format('dddd, Do')}`}
        customSx={{
          '&:focus': {
            borderColor: '#9531FF',
            outline: 'none',
            boxShadow: 'none',
          },
        }}
        onClick={() => {
          showAddWorklog();
          setPredefinedData({
            date: dayjs(data.date),
            startTime: dayjs(dayjs().set('hour', 8).format('YYYY-MM-DD HH')),
            endTime: dayjs(dayjs().set('hour', 16).format('YYYY-MM-DD HH')),
          });
        }}
      />
    </WorklogDayBox>
  );
};

export default EmptyWorklogDayCard;
