import { styled, ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  color: theme.palette.primary.light,
  width: '50%',
  border: 'none',
  textTransform: 'none',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
  },
  borderRadius: '2px',
  '&:hover': { backgroundColor: 'transparent', color: '#9E9E9E' },
}));

export const ToggleButtonPanel = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected': {
    borderRadius: '2px',
  },
  height: '40px',
  width: '100%',
  marginBottom: theme.spacing(2),
  borderRadius: '2px',
  border: `4px solid ${theme.palette.background.default}`,
  backgroundColor: theme.palette.background.default,
}));
