import { Box, Select, styled } from '@mui/material';

export const DateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '45%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const DateRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: 'auto',
    gap: theme.spacing(1),
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(2),
}));

export const JobSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.busmanColors.busmanPrimary,
  color: theme.palette.primary.contrastText,
  fontWeight: 700,
  fontSize: '11px',
  height: '32px',
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: '120px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
}));

export const JobStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  padding: theme.spacing(2),
}));
