import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CustomButton from 'components/Button';
import { FilterButtons } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import PasswordTextField from 'components/PasswordTextField';
import SnackBar, { MessageType } from 'components/SnackBar';
import SuccessModal from 'components/SuccessModal';
import { useResetPasswordMutation } from 'services/busmanApi';

import { ChangePasswordContainer, ChangePasswordForm } from './styles';

const ResetPassword = () => {
  const theme = useTheme();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState<MessageType | null>(null);
  const [success, setSuccess] = useState(false);
  const [passwordError, setPasswordError] = React.useState('');

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const resetAllStates = () => {
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    const userToken = location.search.match(/&token=([^&]+)/);
    const uuid = location.search.match(/&uidb64=([^&]+)/);

    const data = {
      userId: uuid && uuid[1],
      token: userToken && userToken[1],
      newPass: { new_password: newPassword },
    };

    const result = await resetPassword(data);

    if ('error' in result) setMessage({ type: 'error', msg: 'Error changing password' });

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'Password changed successfully' });
      setSuccess(true);
      resetAllStates();
    }
  };

  return (
    <>
      {success && <SuccessModal purpose="Login" message="Your password has been changed" url="/login" />}
      <ChangePasswordContainer>
        <Loader open={isLoading} />
        <ChangePasswordForm>
          <Typography sx={{ mt: 2 }} component="h1" variant="h3">
            Reset your password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              mt: 4,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}>
            <PasswordTextField
              name="newPassword"
              label="New Password"
              size="medium"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <PasswordTextField
              name="confirmPassword"
              label="Confirm Password"
              size="medium"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {passwordError && (
              <Typography variant="subtitle1" sx={{ color: 'red' }}>
                {passwordError}
              </Typography>
            )}
            <FilterButtons sx={{ marginLeft: 'auto', mt: 0 }}>
              <CustomButton type="submit" text="Save New Password" variant="contained" width="large" />
            </FilterButtons>
          </Box>
        </ChangePasswordForm>
        <SnackBar message={message} onClose={() => setMessage(null)} />
      </ChangePasswordContainer>
    </>
  );
};

export default ResetPassword;
