import * as React from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';

interface PasswordTextFieldProps {
  label?: string | null;
  name: string;
  value: string;
  margin?: 'none' | 'dense' | 'normal';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [otherProps: string]: unknown;
}

export default function PasswordTextField({
  name,
  label = null,
  margin = 'none',
  value,
  onChange,
  ...otherProps
}: PasswordTextFieldProps) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <TextField
      required
      fullWidth
      margin={margin}
      type={showPassword ? 'text' : 'password'}
      id={`${name}`}
      name={name}
      label={label}
      size="small"
      value={value}
      onChange={onChange}
      {...otherProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
              <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}
