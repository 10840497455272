import { Box, Button, Grid, styled } from '@mui/material';

export const TeamsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
  marginLeft: '78px',
  width: '100%',
  height: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '@media print': {
    margin: '0',
  },
}));

export const AsideContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  boxShadow: theme.shadows[2],
  marginBottom: theme.spacing(4),
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  top: theme.spacing(1),
  position: 'sticky',
  height: '95vh',
  borderRadius: '5px',
  maxWidth: '312px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    maxWidth: '250px',
  },
}));

export const TeamBanner = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const TeamsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  height: '95vh',
  maxWidth: '928px',
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

export const SortButton = styled(Button)(({ theme }) => ({
  width: '132px',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
