import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos, Clear as ClearIcon, Search } from '@mui/icons-material';
import { SwapVert as SwapVertIcon } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, OutlinedInput, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { FilterButtons, FilterPanelContainer, NoDataBox, PageContainer } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import AddNewTeam from 'pages/TeamDetail/Components/AddNewTeamModal';
import { useGetTeamsQuery, useGetUserListQuery } from 'services/busmanApi';
import { getAllTeamMembers } from 'utils/teams';

import { RootState } from '../../store';

import { TeamBanner, TeamsBox } from './styles';

const Teams = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const [newTeamModal, setNewTeamModal] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const [message, setMessage] = useState<MessageType | null>(null);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: userList } = useGetUserListQuery({});

  const { user } = useSelector((state: RootState) => state.auth);
  const { data: teams, isFetching } = useGetTeamsQuery(user?.user_id);
  const isAdmin = user.user_role === 'A';

  const filteredTeams = sortAscending
    ? teams &&
      teams
        .filter((team) => team.team_name.toLocaleLowerCase().includes(searchInput))
        .sort((a, b) => (a.team_name > b.team_name ? 1 : -1))
    : teams &&
      teams
        .filter((team) => team.team_name.toLocaleLowerCase().includes(searchInput))
        .sort((a, b) => (a.team_name < b.team_name ? 1 : -1));

  const handleClearSearch = () => {
    setSearchInput('');
  };

  const handleNavigate = (id: string) => {
    navigate(`/teams/${id}`);
  };

  const aside = () => {
    return (
      <>
        {filterPanel && (
          <FilterPanelContainer>
            {!isMobileScreen && (
              <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
                Teams
              </Typography>
            )}

            <OutlinedInput
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
              placeholder="Search Teams"
              endAdornment={
                <InputAdornment position="end">
                  {searchInput ? (
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <Search />
                  )}
                </InputAdornment>
              }
              sx={{
                height: '32px',
                width: '100%',
              }}
            />
            <FilterButtons sx={{ marginLeft: isAdmin ? 0 : 'auto' }}>
              <CustomButton
                onClick={() => setSortAscending(!sortAscending)}
                text={sortAscending ? 'Descending' : 'Ascending'}
                variant="contained_grey"
                endIcon={<SwapVertIcon />}
                width={isAdmin ? '100%' : 'medium'}
              />
              {isAdmin && (
                <CustomButton
                  width="100%"
                  variant="contained"
                  text="Add Team"
                  onClick={() => {
                    setNewTeamModal(true);
                  }}
                />
              )}
            </FilterButtons>
          </FilterPanelContainer>
        )}
      </>
    );
  };

  return (
    <>
      <Loader open={isFetching} />
      {newTeamModal && (
        <AddNewTeam employeeList={userList} onClose={() => setNewTeamModal(false)} setMessage={setMessage} />
      )}
      <PageContainer>
        {aside()}
        {!isFetching && filteredTeams?.length === 0 ? (
          <NoDataBox>
            <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
              {searchInput ? 'No teams found !' : 'No teams...'}
            </Typography>
          </NoDataBox>
        ) : (
          <TeamsBox>
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              sx={{ mb: theme.spacing(2) }}>
              <GridItem title={'Teams'} seenOrIsHeading={true} breakpoint={4} />
              {!isMobileScreen && <GridItem title={'Manager(s)'} seenOrIsHeading={true} breakpoint={3} />}
              <GridItem title={'Pods'} seenOrIsHeading={true} breakpoint={2} />
              <GridItem title={'Members'} seenOrIsHeading={true} breakpoint={2.5} />
            </Grid>
            {filteredTeams?.map((team) => {
              const podsTotal = team.members.filter((member) => member.is_team);
              return (
                <TeamBanner
                  container
                  justifyContent="flex-start"
                  key={team.team_id}
                  onClick={() => handleNavigate(team.team_id)}
                  sx={{ border: `1px solid ${theme.palette.background.default}`, height: '56px' }}>
                  <GridItem title={team.team_name} seenOrIsHeading={false} breakpoint={4} />
                  {!isMobileScreen && (
                    <GridItem title={team.managers[0].full_name} seenOrIsHeading={false} breakpoint={3} />
                  )}
                  <GridItem title={podsTotal.length.toString()} seenOrIsHeading={false} breakpoint={2} />
                  <GridItem title={getAllTeamMembers(team).size.toString()} seenOrIsHeading={false} breakpoint={2} />
                  <ArrowForwardIos
                    sx={{
                      color: theme.palette.text.disabled,
                      fontSize: '0.8rem',
                      marginLeft: 'auto',
                      marginRight: theme.spacing(2),
                    }}
                  />
                </TeamBanner>
              );
            })}
          </TeamsBox>
        )}
        <SnackBar message={message} onClose={() => setMessage(null)} />
      </PageContainer>
    </>
  );
};

export default Teams;
