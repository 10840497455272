export const MuiTextField = {
  MuiTextField: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '.MuiButtonBase-root ': {
          height: '32px',
          width: '32px',
          ':hover': {
            backgroundColor: '#F2F2F2',
          },
          '&.Mui-readOnly': {
            '&:hover': {
              border: '1px solid #F2F2F2',
              borderRadius: '2px',
            },
          },
          '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
            fontSize: '16px',
            color: '#9E9E9E',
          },
        },
      },
    },
  },
};
