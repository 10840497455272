import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function DeleteDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} variant="h4">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface DeleteConfirmationDialogState {
  title: string;
  description: string;
  onClose: () => void;
}

const DeleteConfirmationDialog = ({
  title = '',
  description = '',
  onClose = () => {},
}: DeleteConfirmationDialogState) => {
  const DialogModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      minWidth: '512px',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <DialogModal onClose={onClose} aria-labelledby="customized-dialog-title" open={true}>
      <DeleteDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DeleteDialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle1">{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained_red" sx={{ width: '152px' }}>
          OK
        </Button>
      </DialogActions>
    </DialogModal>
  );
};

export default DeleteConfirmationDialog;
