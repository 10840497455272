import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ClientContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderRadius: '2px',
  backgroundColor: theme.palette.background.paper,
  width: '608px',
  height: 'fit-content',
  maxHeight: '95vh',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    maxHeight: '90dvh',
  },
}));

export const ClientMYOBDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
  },
}));

export const JobsSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  height: '100%',
  gap: theme.spacing(1),
  overflow: 'scroll',
  scrollbarWidth: 'thin',
  overflowX: 'hidden',
  width: '100%',
  '::-webkit-scrollbar': {
    outterWidth: '1px',
  },
}));

export const ClientDetailsField = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '32px',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: '2px',
}));

export const MyobFields = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
  },
}));
