/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import dayjs from 'dayjs';

import { worklogActions } from 'features/worklog/worklogSlice';
import {
  useGetActiveJobsQuery,
  useGetActivitiesQuery,
  useGetClientsQuery,
  useGetTaggingListWorklogsQuery,
} from 'services/busmanApi';

const GlobalFetchData = () => {
  const dispatch = useDispatch();
  const clientsResult = useGetClientsQuery({});
  const jobsResult = useGetActiveJobsQuery({});
  const activitiesResult = useGetActivitiesQuery({});
  const tagList = useGetTaggingListWorklogsQuery({});

  // check public holiday data in localstorage
  const holidays = localStorage.getItem('public-holiday');

  // Fetch Client details
  React.useEffect(() => {
    if (!clientsResult.isLoading && !clientsResult.error && clientsResult.data) {
      dispatch(worklogActions.setClients(clientsResult.data));
    }
  }, [clientsResult]);

  // Fetch job details
  React.useEffect(() => {
    if (!jobsResult.isLoading && !jobsResult.error && jobsResult.data) {
      dispatch(worklogActions.setJobs(jobsResult.data));
    }
  }, [jobsResult]);

  React.useEffect(() => {
    if (!activitiesResult.isLoading && !activitiesResult.error && activitiesResult.data) {
      dispatch(worklogActions.setActivities(activitiesResult.data));
    }
  }, [activitiesResult]);

  React.useEffect(() => {
    if (!tagList.error && tagList.data) {
      dispatch(worklogActions.setTagList(tagList.data));
    }
  }, [tagList]);

  React.useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await axios.get(
          'https://data.gov.au/data/api/3/action/datastore_search?resource_id=9e920340-0744-4031-a497-98ab796633e8&q=wa&limit=20',
        );

        if (response.data) {
          const waHolidayRecords = response.data.result.records;
          const publicHolidayDates = waHolidayRecords.map((record: any) => dayjs(record.Date).format('YYYY-MM-DD'));
          localStorage.setItem('public-holiday', JSON.stringify(publicHolidayDates));
        }
      } catch (error) {
        // Handle errors
        console.error('Error fetching public holidays data');
      }
    };

    if (!holidays) fetchHolidays();
  }, []);
  return <React.Fragment></React.Fragment>;
};

export default GlobalFetchData;
