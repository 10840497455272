import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmployeeState } from './types';

export const initialState: EmployeeState = {
  selectedEmployee: null,
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setSelectedEmployee(state, action: PayloadAction<any>) {
      state.selectedEmployee = action.payload;
    },
    resetSelectedEmployee(state) {
      state.selectedEmployee = null;
    },
  },
});

export const { actions: employeeActions, reducer } = employeeSlice;
export default employeeSlice.reducer;
