import React, { useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

import CustomButton from 'components/Button';
import { FilterButtons } from 'components/GlobalStyles/styles';
import { MessageType } from 'components/SnackBar';
import { useAddTeamMutation } from 'services/busmanApi/teamsEndpoints';
import { UserType } from 'types/WorkLogTypes';

import { AddTeamModal, CloseButton, EmployeeList, ModalPageContainer, SectionHeader, TeamMemberBox } from './style';

interface AddNewTeamProps {
  employeeList?: UserType[];
  onClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<MessageType | null>>;
}

const AddNewTeam = ({ employeeList, onClose, setMessage }: AddNewTeamProps) => {
  const theme = useTheme();
  const [teamName, setTeamName] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [addTeam] = useAddTeamMutation();
  const [teamNameError, setTeamNameError] = useState<string | null>(null);
  const [managerError, setManagerError] = useState<string | null>(null);

  const filteredEmployeeList =
    employeeList &&
    employeeList
      .filter((employee) => employee.user_role === 'M' || employee.user_role === 'A')
      .filter((employee) => employee.full_name.toLocaleLowerCase().includes(searchInput.toLowerCase()));

  const handleAddTeam = async () => {
    if (teamName === '') {
      return setTeamNameError('Please enter a team name');
    }
    if (selectedEmployees.length === 0) {
      return setManagerError('Select a manager for this team');
    }
    setTeamNameError(null);
    setManagerError(null);
    const newTeamData = {
      team_name: teamName,
      manager_id: selectedEmployees,
    };

    const response: any = await addTeam(newTeamData);
    if ('error' in response) {
      setMessage({ type: 'error', msg: 'Error creating team' });
      const errorData = response.error?.data?.errors;
      let teamError;
      if (typeof response.errors === 'string') {
        teamError = errorData;
      }
      if (typeof errorData === 'string') {
        teamError = errorData;
      }
      if (errorData && 'team_name' in errorData) {
        teamError = errorData.team_name[0];
      }
      setTeamNameError(teamError);
    } else {
      setMessage({ type: 'success', msg: 'Team added successfully!' });
      onClose();
    }
  };

  const handleSelectEmployee = (selectedEmployee: string) => {
    if (selectedEmployees.includes(selectedEmployee))
      setSelectedEmployees([...selectedEmployees].filter((employee) => employee !== selectedEmployee));
    else setSelectedEmployees([...selectedEmployees, selectedEmployee]);
  };

  const renderEmployeeList = () => {
    return (
      <>
        {filteredEmployeeList && filteredEmployeeList.length < 1 ? (
          <SectionHeader sx={{ borderBottom: 'none' }}>
            <Typography variant="body1">No employee matches search.</Typography>
          </SectionHeader>
        ) : (
          <EmployeeList>
            {filteredEmployeeList &&
              filteredEmployeeList.map((employee) => {
                return (
                  <TeamMemberBox
                    key={employee.user_id}
                    onClick={() => handleSelectEmployee(employee.user_id)}
                    sx={
                      selectedEmployees.includes(employee.user_id)
                        ? { backgroundColor: theme.palette.busmanColors.busmanGreen }
                        : {}
                    }>
                    <Typography>{employee.full_name}</Typography>
                  </TeamMemberBox>
                );
              })}
          </EmployeeList>
        )}
      </>
    );
  };

  return (
    <ModalPageContainer open onClose={onClose}>
      <AddTeamModal onClick={(e) => e.stopPropagation()}>
        <SectionHeader>
          <Typography variant="h5">New Team</Typography>
          <CloseButton onClick={() => onClose()} />
        </SectionHeader>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', padding: '16px' }}>
          <Typography variant="body2">Team Name</Typography>
          <TextField
            value={teamName}
            fullWidth
            onChange={(event) => setTeamName(event.target.value)}
            sx={{
              '.MuiOutlinedInput-input': { p: 1 },
            }}></TextField>
          {teamNameError !== null && (
            <Typography sx={{ color: theme.palette.busmanColors.busmanRed }} variant="body2">
              *{teamNameError}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', padding: '8px 16px 0px 16px' }}>
          <Typography variant="body2">Add Managers</Typography>
          <TextField
            value={searchInput}
            fullWidth
            placeholder="Search Managers"
            onChange={(event) => setSearchInput(event.target.value)}
            sx={{
              '.MuiOutlinedInput-input': { p: 1 },
            }}></TextField>
          {managerError !== null && (
            <Typography sx={{ color: theme.palette.busmanColors.busmanRed }} variant="body2">
              *{managerError}
            </Typography>
          )}
        </Box>
        {renderEmployeeList()}
        <FilterButtons sx={{ marginLeft: 'auto', padding: '16px', marginTop: 'auto' }}>
          <CustomButton variant="contained" text={'Add Team'} width="medium" onClick={() => handleAddTeam()} />
        </FilterButtons>
      </AddTeamModal>
    </ModalPageContainer>
  );
};

export default AddNewTeam;
