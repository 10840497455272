import * as React from 'react';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import { MessageType } from 'components/SnackBar';
import { useAddJobMutation } from 'services/busmanApi';
import { PrefillDataType } from 'types/QuoteTypes';
import { ActivitiesProps, UserType } from 'types/WorkLogTypes';
import { DESCRIPTION_LIMIT } from 'utils/constants';
import { formatDate } from 'utils/date';

import { RootState } from '../../store';

import { DateBox, DateRangeBox, Header, JobSelect, JobStatusBox } from './styles';

type BillingCycle = {
  period: string;
  days: number;
};

const BILLING_CYCLES: BillingCycle[] = [
  { period: 'Weekly', days: 7 },
  { period: 'Fortnightly', days: 14 },
  { period: 'Monthly', days: 30 },
];

interface StateTypes {
  header: string;
  jobName: string;
  jobType: string;
  maxCost: string;
  clientId: string;
  jobNumber: string;
  jobManager: string;
  isActive: boolean;
  jobStatus: string;
  description: string;
  quoteNumber: string;
  orderNumber: string;
  quotedHours: string;
  billingCycle: BillingCycle;
  endDate: Dayjs | null;
  primaryContact: string;
  jobContactName: string;
  startDate: Dayjs | null;
}

interface AllowableActivitiesType {
  activityId: string;
  activityName: string;
}

const DEFAULT_DATA = {
  header: '',
  maxCost: '',
  jobName: '',
  clientId: '',
  jobNumber: '',
  jobManager: '',
  isActive: true,
  quoteNumber: '',
  orderNumber: '',
  quotedHours: '',
  description: '',
  billingCycle: BILLING_CYCLES[0],
  jobType: 'Quoted',
  primaryContact: '',
  jobContactName: '',
  jobStatus: 'InProgress',
  endDate: dayjs(dayjs().format('YYYY-MM-DD HH')),
  startDate: dayjs(dayjs().format('YYYY-MM-DD HH')),
};

interface NewJobProps {
  close: () => void;
  showMessage: (args: MessageType) => void;
  jobManagers: Array<UserType>;
  preFillData?: PrefillDataType;
}

const SearchActivitiesModal = React.lazy(() => import('components/SearchActivities'));

const NewJob = ({ close, showMessage, jobManagers, preFillData }: NewJobProps) => {
  const theme = useTheme();
  const [addJob] = useAddJobMutation();
  const [formValues, setFormValues] = React.useState<StateTypes>(DEFAULT_DATA);
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [allowableActivities, setAllowableActivities] = React.useState<AllowableActivitiesType[]>([]);

  React.useEffect(() => {
    if (preFillData) {
      setFormValues({ ...formValues, clientId: preFillData.client?.client_id });
      Object.entries(preFillData).forEach(([key, value]) => {
        if (key === 'allowableActivities') {
          setAllowableActivities(value);
        } else if (key !== 'client') {
          setFormValues((prevValues) => ({ ...prevValues, [key]: value }));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFillData]);

  const {
    header,
    endDate,
    jobType,
    jobName,
    maxCost,
    clientId,
    isActive,
    startDate,
    jobStatus,
    jobNumber,
    jobManager,
    description,
    quoteNumber,
    orderNumber,
    quotedHours,
    billingCycle,
    primaryContact,
    jobContactName,
  } = formValues;
  const { activities, clients } = useSelector((state: RootState) => state.worklogs);

  let filteredActivities: ActivitiesProps[] = activities;

  if (allowableActivities.length) {
    filteredActivities = activities.filter((activity) => {
      return !allowableActivities.some(
        (allowedActivity: AllowableActivitiesType) => allowedActivity.activityId === activity.activity_id,
      );
    });
  }

  const removeActivity = (id: string) => {
    const newActivities = allowableActivities.filter((activity: AllowableActivitiesType) => activity.activityId !== id);
    setAllowableActivities(newActivities);
  };

  const handleChange = (key: string, value: string | BillingCycle) => {
    if (error) setError(null);

    setFormValues({ ...formValues, [key]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dayjs.extend(isSameOrAfter);
    if (!endDate?.isSameOrAfter(startDate)) {
      setError('Error: End date cannot be before start date !');
      return;
    }

    const newJobData = {
      job_type: jobType,
      client_id: clientId,
      is_active: isActive,
      job_status: jobStatus,
      job_number: jobNumber,
      invoice_header: header,
      job_manager: jobManager,
      job_description: description,
      end_date: formatDate(endDate),
      start_date: formatDate(startDate),
      job_billing_cycle: jobType === 'TimeBilled' ? billingCycle?.period : null,
      allowed_activities: allowableActivities?.map((activity) => activity.activityId),
      job_metadata: {
        job_name: jobName,
        primary_contact: primaryContact,
        contact_name: jobContactName,
        quote_number: quoteNumber,
        order_number: orderNumber,
        quoted_hours: quotedHours,
        max_cost: maxCost,
      },
      sync_to_myob: false,
    };

    const result = await addJob(newJobData);

    if ('error' in result) {
      setError('Error adding job!');
    }

    if ('data' in result) {
      close();
      showMessage({ type: 'success', msg: 'New Job added' });
    }
  };

  const setStartDate = (value: Dayjs | null) => {
    if (error) setError(null);

    setFormValues({ ...formValues, startDate: value });
  };

  const setEndDate = (value: Dayjs | null) => {
    if (error) setError(null);

    setFormValues({ ...formValues, endDate: value });
  };

  const onSelectActivity = (jobActivities: ActivitiesProps[]) => {
    const selectedActivities = jobActivities.map((activity) => {
      return {
        activityId: activity.activity_id,
        activityName: activity.activity_name,
      };
    });
    setAllowableActivities((prevActivities) => [...prevActivities, ...selectedActivities]);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[2],
        borderRadius: '5px',
        backgroundColor: theme.palette.background.paper,
        height: 'fit-content',
        maxHeight: '95vh',
        overflowY: 'auto',
        width: '500px',

        position: 'sticky',
        top: theme.spacing(1),
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          maxWidth: '100vw',
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          maxHeight: '90dvh',
          marginTop: '6.5dvh',
        },
      }}>
      <Header>
        <Typography variant="h4"> New Job </Typography>
        <CloseIcon
          onClick={() => close()}
          sx={{
            color: '#9E9E9E',
            ':hover': {
              color: theme.palette.primary.main,
              cursor: 'pointer',
            },
          }}
        />
      </Header>
      <JobStatusBox>
        <JobSelect onChange={(event) => handleChange('jobType', event.target.value as string)} value={jobType}>
          <MenuItem key="quoted" value="Quoted">
            Quoted
          </MenuItem>
          <MenuItem key="timeBilled" value="TimeBilled">
            Time Billed
          </MenuItem>
          <MenuItem key="internal" value="Internal">
            Internal
          </MenuItem>
        </JobSelect>
        <JobSelect onChange={(event) => handleChange('jobStatus', event.target.value as string)} value={jobStatus}>
          <MenuItem key="inprogress" value="InProgress">
            In Progress
          </MenuItem>
          <MenuItem key="completed" value="Completed">
            Completed
          </MenuItem>
          <MenuItem key="billed" value="Billed">
            Billed
          </MenuItem>
        </JobSelect>
        <JobSelect onChange={(event) => handleChange('isActive', event.target.value as string)} value={isActive}>
          <MenuItem key="active" value="true">
            Active
          </MenuItem>
          <MenuItem key="inactive" value="false">
            Inactive
          </MenuItem>
        </JobSelect>
      </JobStatusBox>
      <DateRangeBox>
        <DateBox>
          <Typography variant="body2" gutterBottom>
            START DATE
          </Typography>
          <DatePicker allowMaxDate onChange={setStartDate} value={startDate} />
        </DateBox>
        <DateBox>
          <Typography variant="body2" gutterBottom>
            END DATE
          </Typography>
          <DatePicker allowMaxDate onChange={setEndDate} value={endDate} />
        </DateBox>
      </DateRangeBox>
      <Box sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          CLIENT
        </Typography>
        <Autocomplete
          size="small"
          value={clients.find((client) => client.client_id === clientId) || null}
          options={clients.map(({ client_name, client_id }) => {
            return { client_name, client_id };
          })}
          getOptionLabel={(option) => option?.client_name}
          isOptionEqualToValue={(option, value) => option.client_name === value.client_name}
          onChange={(event, value) => handleChange('clientId', value?.client_id || '')}
          ListboxProps={{
            style: {
              fontSize: '12px',
            },
          }}
          renderInput={(params) => <TextField {...params} required />}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          JOB NUMBER
        </Typography>
        <TextField
          required
          fullWidth
          name="jobNumber"
          size="small"
          onChange={(e) => handleChange('jobNumber', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          DESCRIPTION
        </Typography>
        <TextField
          InputProps={{
            multiline: true,
            rows: 4,
          }}
          fullWidth
          name="description"
          sx={{ height: '85px', mb: 1 }}
          onChange={(e) => handleChange('description', e.target.value)}
          inputProps={{ maxLength: DESCRIPTION_LIMIT }}
          helperText={`${description.length}/${DESCRIPTION_LIMIT}`}
          FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
          INVOICE HEADER
        </Typography>
        <TextField
          InputProps={{
            rows: 4,
            multiline: true,
          }}
          fullWidth
          name="header"
          sx={{ height: '85px', mb: 1 }}
          onChange={(e) => handleChange('header', e.target.value)}
          inputProps={{ maxLength: DESCRIPTION_LIMIT }}
          helperText={`${header.length}/${DESCRIPTION_LIMIT}`}
          FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
          JOB MANAGER
        </Typography>
        <Autocomplete
          size="small"
          options={jobManagers.map(({ full_name, user_id }) => {
            return { full_name, user_id };
          })}
          getOptionLabel={(option) => option?.full_name}
          isOptionEqualToValue={(option, value) => option.full_name === value.full_name}
          onChange={(event, value) => handleChange('jobManager', value?.user_id || '')}
          ListboxProps={{
            style: {
              fontSize: '12px',
            },
          }}
          renderInput={(params) => <TextField {...params} required />}
        />
      </Box>
      {jobType === 'TimeBilled' && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
              mt: 1,
              mb: 1,
              padding: 2,
            }}>
            Billing
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ mt: 1, paddingInline: 2 }}>
            BILLING PERIOD
          </Typography>
          <Select
            size="small"
            onChange={(event) => handleChange('billingCycle', event.target.value)}
            value={billingCycle}
            sx={{ marginInline: 2 }}>
            {BILLING_CYCLES.map((billing: any) => {
              return (
                <MenuItem key={billing.period} value={billing}>
                  {billing.period}
                </MenuItem>
              );
            })}
          </Select>
          <Typography variant="body2" gutterBottom sx={{ mt: 2, paddingInline: 2 }}>
            NO. DAYS IN BILLING CYCLE
          </Typography>
          <TextField
            fullWidth
            value={billingCycle ? billingCycle?.days : BILLING_CYCLES[0].days}
            name="billingDays"
            size="small"
            sx={{ paddingInline: 2 }}
          />
        </>
      )}
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
          mt: 1,
          mb: 1,
          padding: 2,
        }}>
        Allowable Activities
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          mb: 1,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}>
        <List sx={{ width: '100%', maxHeight: '200px', overflowY: 'auto', mb: 2 }}>
          {allowableActivities?.map((activity: AllowableActivitiesType) => (
            <ListItem
              key={activity.activityId}
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                height: '36px',
                bgcolor: theme.palette.background.default,
                mb: 0.5,
              }}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => removeActivity(activity.activityId)}>
                  <ClearIcon sx={{ fontSize: 'small' }} />
                </IconButton>
              }>
              <ListItemText primary={activity.activityName} />
            </ListItem>
          ))}
        </List>
        <CustomButton variant="contained_grey" onClick={() => setOpen(true)} width="large" text="Add Activity" />
      </Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
          mb: 1,
          pt: 2,
          pb: 2,
          padding: 2,
        }}>
        Optional Metadata
      </Typography>
      <Box sx={{ padding: 2, pt: 0 }}>
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          EEA PRIMARY CONTACT (MUST BE A VALID USERNAME)
        </Typography>
        <TextField
          fullWidth
          name="EEAcontact"
          size="small"
          onChange={(e) => handleChange('primaryContact', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          JOB NAME
        </Typography>
        <TextField
          inputProps={{ maxLength: 50 }}
          fullWidth
          name="jobName"
          size="small"
          onChange={(e) => handleChange('jobName', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          JOB CONTACT NAME
        </Typography>
        <TextField
          fullWidth
          name="jobContactName"
          size="small"
          onChange={(e) => handleChange('jobContactName', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          EEA QUOTE NUMBER
        </Typography>
        <TextField
          fullWidth
          value={quoteNumber}
          name="quoteNo"
          size="small"
          onChange={(e) => handleChange('quoteNumber', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          PURCHASE ORDER NUMBER
        </Typography>
        <TextField
          fullWidth
          name="orderNumber"
          size="small"
          onChange={(e) => handleChange('orderNumber', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          QUOTED HOURS
        </Typography>
        <TextField
          fullWidth
          value={quotedHours}
          name="quotedHours"
          size="small"
          onChange={(e) => handleChange('quotedHours', e.target.value)}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          MAXIMUM COST (EXCLUSIVE GST)
        </Typography>
        <TextField fullWidth name="maxCost" size="small" onChange={(e) => handleChange('maxCost', e.target.value)} />
        {error && (
          <Typography
            variant="subtitle2_italic"
            gutterBottom
            sx={{
              color: 'red',
              mb: 1,
              width: '100%',
              textAlign: 'center',
            }}>
            {error}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            mt: 2,
          }}>
          <CustomButton type="submit" variant="contained" width="large" text="Submit" />
        </Box>
        <React.Suspense fallback={<Loader open={true} />}>
          <SearchActivitiesModal
            activities={filteredActivities}
            open={open}
            onClose={() => setOpen(false)}
            onSelectMultiple={onSelectActivity}
          />
        </React.Suspense>
      </Box>
    </Box>
  );
};

export default NewJob;
