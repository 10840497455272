import { WorklogDayType, WorklogType } from 'types/WorkLogTypes';
import { WORKLOGS_LIMIT } from 'utils/constants';
import { COMPANY_ID } from 'utils/constants';
import { SortWorkLogsByAscDate, SortWorkLogsByDescDate } from 'utils/date';
import { regroupLogsByDate } from 'utils/worklog';

import { busmanApiSlice } from './busmanApiSlice';
import {
  GetCommentResponse,
  GetProductivityResponse,
  GetWorkLogByID,
  GetWorklogByJobResponse,
  GetWorklogResponse,
} from './types';

interface GetWorklogDraftType {
  sortedWorklogs: WorklogDayType[];
  totalPages: number;
}

export const worklogsEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetches worklogs by employee ID, start and end dates
    getWorklogs: builder.query({
      query: ({ empID, startDate, endDate, page, limit = WORKLOGS_LIMIT }) =>
        `activity/worklogs/?employee_id=${empID}&start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) return newItems;

        const allWorklogsDay = JSON.parse(JSON.stringify([...currentCache.sortedWorklogs, ...newItems.sortedWorklogs]));

        const getUniqueWorklogs = (existingWorklogs: WorklogType[], newWorklogs: WorklogType[]) => {
          const existingIds = newWorklogs.map((worklog) => worklog.slip_id);
          const uniqueWorklogs = existingWorklogs.filter((worklog) => !existingIds.includes(worklog.slip_id));
          return uniqueWorklogs;
        };

        const mergedWorklogs: WorklogDayType[] = [];

        // Check for duplicate date worklogs due to pagination
        for (const worklogsDay of allWorklogsDay) {
          const existingWorklog = mergedWorklogs.find((w) => w.date === worklogsDay.date);
          if (existingWorklog) {
            const uniqueWorklogs = getUniqueWorklogs(existingWorklog.worklogs, worklogsDay.worklogs);
            existingWorklog.worklogs = [...uniqueWorklogs, ...worklogsDay.worklogs];
          } else {
            mergedWorklogs.push({ ...worklogsDay });
          }
        }

        return { ...newItems, sortedWorklogs: mergedWorklogs };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.empID !== previousArg?.empID ||
          currentArg.startDate !== previousArg?.startDate ||
          currentArg.endDate !== previousArg?.endDate ||
          currentArg.page !== previousArg?.page
        );
      },
      transformResponse: (response: GetWorklogResponse) => {
        const { worklog_list: worklogs, total_pages: totalPages } = response.data;
        let sortedWorklogs = worklogs.slice().sort(SortWorkLogsByDescDate);

        sortedWorklogs = sortedWorklogs.map((item) => {
          return {
            ...item,
            worklogs: item?.worklogs?.slice().sort(SortWorkLogsByAscDate),
          };
        });

        return { sortedWorklogs, totalPages };
      },
      providesTags: ['Worklog'],
    }),

    // Fetch worklogs by job ID
    getWorklogsByJobId: builder.query({
      query: ({ jobID, startDate, endDate }) =>
        `/activity/worklogs/byjob?job_id=${jobID}&start_date=${startDate}&end_date=${endDate}`,
      transformResponse: (response: GetWorklogByJobResponse) => response.data,
      providesTags: ['JobWorklog'],
    }),

    //Fetch worklogs by worklogId
    getWorklogsById: builder.query({
      query: (worklogID) => `/activity/worklogs/worklog_id=${worklogID}`,
      transformResponse: (response: GetWorkLogByID) => response.data,
    }),

    // Adds a new worklog
    addWorklog: builder.mutation({
      query: (newWorklog) => ({
        url: 'activity/worklogs/',
        method: 'post',
        body: newWorklog,
      }),
      invalidatesTags: ['Productivity', 'JobWorklog', 'WorklogCount'],
    }),

    // Updates worklog
    updateWorklog: builder.mutation({
      query: (updatedWorklog) => {
        return {
          url: 'activity/worklogs/update/',
          method: 'put',
          body: updatedWorklog,
        };
      },
      invalidatesTags: ['Productivity', 'JobWorklog', 'WorklogCount'],
    }),

    // Approve/Disapprove worklogs
    updateWorklogStatus: builder.mutation({
      query: ({ jobManager = false, list }) => {
        return {
          url: `activity/worklogs/approval/?job_manager=${jobManager}`,
          method: 'put',
          body: list,
        };
      },
      invalidatesTags: ['JobWorklog', 'WorklogCount'],
    }),

    // Delete worklog
    deleteWorklog: builder.mutation({
      query: (id) => {
        return {
          url: 'activity/worklogs/delete/',
          method: 'delete',
          body: { slip_id: id },
        };
      },
      invalidatesTags: ['Productivity', 'JobWorklog', 'WorklogCount'],
    }),

    //Unsync Worklog
    unsyncWorklog: builder.mutation({
      query: (params) => {
        return {
          url: '/activity/worklogs/unsync/',
          method: 'post',
          body: { ...params, company_id: COMPANY_ID },
        };
      },
      invalidatesTags: ['Productivity', 'Worklog', 'JobWorklog', 'WorklogCount'],
    }),

    // Worklogs Comments
    getWorklogComments: builder.query({
      query: ({ id, page = 1, limit = 5 }) => `/activity/worklogs/comments?slip_id=${id}&page=${page}&limit=${limit}`,
      transformResponse: (response: GetCommentResponse) => response.data,
      providesTags: ['LogComments'],
    }),

    addWorklogComment: builder.mutation({
      query: (newComment) => ({
        url: '/activity/worklogs/comments',
        method: 'post',
        body: newComment,
      }),
      // Handle manual cache updates - Add +1 in comments field in worklog cache when added comments
      async onQueryStarted(newComment, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            worklogsEndpoints.util.updateQueryData('getWorklogs', undefined, (draft: GetWorklogDraftType) => {
              const worklogSlips = draft.sortedWorklogs.flatMap((item: WorklogDayType) => item.worklogs);
              // Find the index of the slip to be updated
              const slipIndex = worklogSlips.findIndex((slip: WorklogType) => slip.slip_id === newComment?.slip_id);

              if (slipIndex !== -1) {
                worklogSlips[slipIndex] = {
                  ...worklogSlips[slipIndex],
                  comments: worklogSlips[slipIndex].comments + 1,
                };

                const newWorklogs = regroupLogsByDate(worklogSlips);
                return { ...draft, sortedWorklogs: newWorklogs };
              }
            }),
          );
        } catch (error) {
          console.log('Error', error);
        }
      },
      invalidatesTags: ['LogComments'],
    }),

    deleteWorklogComment: builder.mutation({
      query: ({ commentId, slipID }) => ({
        url: '/activity/worklogs/comments',
        method: 'delete',
        body: { comment_id: commentId },
      }),
      // Handle manual cache updates - Subtract 1 from comments field in worklog cache when deleted comment
      async onQueryStarted({ commentId, slipID }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            worklogsEndpoints.util.updateQueryData('getWorklogs', undefined, (draft: GetWorklogDraftType) => {
              const worklogSlips = draft.sortedWorklogs.flatMap((item: WorklogDayType) => item.worklogs);
              // Find the index of the worklog to be updated
              const slipIndex = worklogSlips.findIndex((slip: WorklogType) => slip.slip_id === slipID);

              if (slipIndex !== -1) {
                worklogSlips[slipIndex] = {
                  ...worklogSlips[slipIndex],
                  comments: worklogSlips[slipIndex].comments - 1, // Subtract 1 from comments
                };

                const newWorklogs = regroupLogsByDate(worklogSlips);
                return { ...draft, sortedWorklogs: newWorklogs };
              }
            }),
          );
        } catch (error) {
          console.log('Error', error);
        }
      },
      invalidatesTags: ['LogComments'],
    }),

    updateWorklogComment: builder.mutation({
      query: (updatedComment) => {
        return {
          url: '/activity/worklogs/comments',
          method: 'put',
          body: updatedComment,
        };
      },
      invalidatesTags: ['LogComments'],
    }),

    // Worklog productivity
    getProductivity: builder.query({
      query: ({ empID, month }) => `/activity/breakdown/?employee_id=${empID}&month=${month}`,
      transformResponse: (response: GetProductivityResponse) => response.data,
      providesTags: ['Productivity'],
    }),
  }),
});

export const {
  useAddWorklogMutation,
  useDeleteWorklogMutation,
  useUnsyncWorklogMutation,
  useUpdateWorklogMutation,
  useUpdateWorklogStatusMutation,
  useGetProductivityQuery,
  useGetWorklogsQuery,
  useGetWorklogsByIdQuery,
  useGetWorklogsByJobIdQuery,
  useLazyGetWorklogCommentsQuery,
  useGetWorklogCommentsQuery,
  useAddWorklogCommentMutation,
  useDeleteWorklogCommentMutation,
  useUpdateWorklogCommentMutation,
} = worklogsEndpoints;
