import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const SyncBox = styled(Box)(({ theme }) => ({
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

export const MYOBSyncContainer = styled(Box)(({ theme }) => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '928px',
  maxHeight: '95vh',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    overflowY: 'auto',
    height: 'fit-content',
    maxHeight: 'none',
  },
}));

export const ErrorLog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: '100%',
  maxHeight: '100%',
  width: '100%',
  overflowY: 'auto',
  borderRadius: '2px',
}));
