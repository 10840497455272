import React from 'react';

import { useTheme } from '@mui/material';
import { Box, Typography } from '@mui/material';

const ServerError = () => {
  const theme = useTheme();
  const [count, setCount] = React.useState(30);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    if (count === 0) {
      clearInterval(timer);
      window.location.reload();
    }

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.error.light,
        padding: theme.spacing(1),
      }}>
      <Typography variant="h6" color="white">
        Server Error - Trying to reconnect in {count} seconds
      </Typography>
    </Box>
  );
};

export default ServerError;
