import { Card, styled } from '@mui/material';

export const WorkLogCardContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  height: 'auto',
  minWidth: '100%',
  borderRadius: '2px',
  marginBottom: theme.spacing(1),
  overflow: 'visible',
}));
