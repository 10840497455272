import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  paddingLeft: '78px',
  paddingRight: '16px',
  paddingTop: theme.spacing(1),
  width: '100vw',
  height: '100%',
  gap: theme.spacing(3),
  marginRight: theme.spacing(1),
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100dvw',
    flexDirection: 'column',
    paddingLeft: '16px',
    paddingTop: '6.5dvh',
    padingRight: '16px',
    paddingBottom: theme.spacing(1),
    gap: 0,
    height: '100dvh',
    overflowY: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    padingRight: '0',
  },
  '@media print': {
    marginLeft: '0',
    width: '210mm',
    paddingLeft: '0',
    overflow: 'visible',
  },
}));

export const MobileFilterBtn = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  height: '8svh',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  position: 'sticky',
  top: 0,
  zIndex: 999,
}));

export const FilterPanelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: '0px',
  height: '95vh',
  borderRadius: '2px',
  width: '312px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    maxWidth: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    maxWidth: '100%',
    width: '100%',
    height: 'fit-content',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  '@media print': {
    display: 'none',
  },
}));

export const DateRangeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
  },
}));

export const FilterButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

export const ItemListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flexGrow: 2,
  height: '95vh',
  maxWidth: '960px',
  padding: theme.spacing(2),
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    // marginTop: theme.spacing(2),
    height: '100%',
  },
}));

export const WorklogsContainer = styled(Box)(({ theme }) => ({
  flexGrow: 2,
  display: 'flex',
  maxWidth: '650px',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    maxWidth: '500px',
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    marginTop: '8px',
  },
}));

export const HeaderUnderline = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '48px',
  width: '100%',
  borderBottom: '1px solid #F2F2F2',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '48px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: 2,
  padding: theme.spacing(2),
}));

export const HeaderBack = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: theme.spacing(2),
}));
export const NoDataBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxHeight: '48px',
  maxWidth: '600px',
  borderRadius: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '8px',
  },
}));

export const WarningBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'fit-content',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: 'rgba(217, 83, 79, 0.4)',
  color: theme.palette.busmanColors.busmanRed,
  border: `2px solid ${theme.palette.error.main}`,
  padding: theme.spacing(1),
  paddingInline: theme.spacing(2),
  borderRadius: '2px',
  width: '100%',
  gap: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(217, 83, 79, 0.6)',
    cursor: 'pointer',
  },
}));
