import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Row = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '56px',
  ':hover': {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

export const EmployeeBox = styled(Box)(({ theme }) => ({
  width: '496px',
  maxHeight: '95vh',
  display: 'flex',
  overflowY: 'scroll',
  borderRadius: '2px',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    height: '88dvh',
    marginTop: theme.spacing(1),
  },
}));
