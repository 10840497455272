import * as React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Tooltip, useTheme } from '@mui/material';

interface AddLogButtonProps {
  showNewWorklog: () => void;
}

const AddLogButton = ({ showNewWorklog }: AddLogButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '48px',
        position: 'sticky',
        display: 'flex',
        bottom: '16px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginLeft: '8px',
        marginRight: '8px',
        [theme.breakpoints.up('sm')]: {
          top: '90vh',
        },
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          left: '0',
          marginLeft: '0',
          paddingInline: '16px',
          maxWidth: '100%',
          width: '100%',
          height: '48px',
          marginRight: '0',
        },
      }}>
      <Button
        variant="contained_purple"
        onClick={showNewWorklog}
        sx={{
          width: '40px',
          height: '40px',
          minWidth: '40px',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '48px',
          },
        }}>
        <Tooltip title="New WorkLog Entry">
          <AddIcon />
        </Tooltip>
      </Button>
    </Box>
  );
};

export default AddLogButton;
