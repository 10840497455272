import { Box, Checkbox, styled, Switch } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const UserDetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '78px',
  marginTop: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '8px',
    marginTop: '15dvh',
  },
}));

export const CustomCheckbox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': {
    width: '10px',
    height: '10px',
  },
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.busmanColors.busmanPrimary,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

const Formbox = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[2],
  borderRadius: '5px',
  backgroundColor: theme.palette.background.paper,
  height: 'fit-content',
  maxHeight: '90vh',
  overflowY: 'auto',
  width: '600px',
  padding: theme.spacing(2),
  position: 'sticky',
  top: theme.spacing(1),
  marginRight: '10px',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    maxWidth: '100vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
});

export { Formbox };
