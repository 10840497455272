import * as React from 'react';

import { Backdrop, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({ open, label }: { open: boolean; label?: string }) {
  if (!open) return null;

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
      open={open}>
      <CircularProgress color="inherit" />
      {label && (
        <Typography gutterBottom variant="subtitle2_italic">
          {label}
        </Typography>
      )}
    </Backdrop>
  );
}
