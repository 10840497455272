import React from 'react';
import { Dayjs } from 'dayjs';

import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface DocHeaderDetailsProps {
  toDate: Dayjs | null;
  fromDate: Dayjs | null;
  invoiceNumber: string;
  customerPO: string;
  handleSetFormValues: (key: string, value: string) => void;
}

const DocHeaderDetails = ({
  toDate,
  fromDate,
  invoiceNumber,
  customerPO,
  handleSetFormValues,
}: DocHeaderDetailsProps) => {
  const theme = useTheme();

  const dates = {
    textAlign: 'right',
    fontWeight: 'bold',
    width: '100px',
    padding: '4px 0 0 0',
  };

  const invoiceField = {
    textAlign: 'right',
    width: '100px',
    border: `1px solid ${theme.palette.background.default}`,
    padding: theme.spacing(1),
    '& fieldset': { border: 'none', fontSize: '10px' },
    '@media print': {
      border: 'none',
      padding: '4px 0 0 0',
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    handleSetFormValues(key, event.target.value.toUpperCase());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(4),
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography textAlign={'right'} variant="body2">
          FROM
        </Typography>
        <Typography sx={dates} variant="body1">
          {fromDate?.format('DD/MM/YYYY').toString()}
        </Typography>
        <Typography mt={2} textAlign={'right'} variant="body2">
          INVOICE NUMBER
        </Typography>
        <TextField
          sx={invoiceField}
          inputProps={{
            style: {
              textAlign: 'right',
              fontSize: '10px',
              padding: '0',
            },
          }}
          InputProps={{
            style: {
              fontWeight: 'bold',
            },
          }}
          size="small"
          variant="outlined"
          value={invoiceNumber}
          onChange={(event) => handleSetFormValues('invoiceNumber', event.target.value.toUpperCase())}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography textAlign={'right'} variant="body2">
          TO
        </Typography>
        <Typography sx={dates} textAlign={'right'} variant="body1">
          {toDate?.format('DD/MM/YYYY').toString()}
        </Typography>
        <Typography mt={2} textAlign={'right'} variant="body2">
          CUSTOMERPO
        </Typography>
        <TextField
          sx={invoiceField}
          inputProps={{
            style: {
              textAlign: 'right',
              fontSize: '10px',
              padding: '0',
            },
          }}
          InputProps={{
            style: {
              fontWeight: 'bold',
            },
          }}
          size="small"
          variant="outlined"
          value={customerPO}
          onChange={(event) => handleChange(event, 'customerPO')}
        />
      </Box>
    </Box>
  );
};

export default DocHeaderDetails;
