import * as React from 'react';
import dayjs from 'dayjs';

import { Tooltip, Typography } from '@mui/material';

import { ExtendedWorklogType, WorklogType } from 'types/WorkLogTypes';

import { ActivityContainer, CardContainer, CloseButton, DateTimeBox } from './styles';

interface ApproveWorkLogCardProps {
  worklog: ExtendedWorklogType;
  onRemove: (workLogId: string) => void;
}

const ApproveWorkLogCard = ({ onRemove, worklog }: ApproveWorkLogCardProps) => {
  const {
    activity,
    date,
    end_time: endTime,
    payroll_category: leaveCategory,
    slip_id: slipID,
    start_time: startTime,
  } = worklog;

  // is grouped log if groupedIds are present
  const isMultipleLog = !!worklog?.groupedIds;

  return (
    <CardContainer>
      <Tooltip title="Remove" sx={{ cursor: 'pointer', position: 'absolute', right: '-7px', top: '-7px' }}>
        <CloseButton onClick={() => onRemove(slipID)} />
      </Tooltip>
      <DateTimeBox>
        <Typography variant="h6">
          {/* show date range for grouped logs */}
          {!isMultipleLog
            ? dayjs(date).format('D/MM/YYYY')
            : `${dayjs(worklog.startDate).format('D/MM/YYYY')} - ${dayjs(worklog.endDate).format('D/MM/YYYY')}`}
        </Typography>
        <Typography variant="h6">
          {dayjs(startTime).format('LT')} - {dayjs(endTime).format('LT')}
        </Typography>
      </DateTimeBox>
      <ActivityContainer>
        <Typography variant="h6">{activity?.activity_name || leaveCategory?.category_name}</Typography>
      </ActivityContainer>
    </CardContainer>
  );
};

export default ApproveWorkLogCard;
