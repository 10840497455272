import { Box, styled } from '@mui/material';

export const ForgotPasswordBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  height: 'fit-content',
  borderRadius: '4px',
  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
  width: '500px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    width: '100%',
    marginInline: theme.spacing(2),
  },
}));

export const ForgotPasswordContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.busmanColors.gradient,
  [theme.breakpoints.down('sm')]: {},
}));

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.background.default}`,
}));
