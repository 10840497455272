import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

import CustomButton from 'components/Button';

interface SaveAndExitModalTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function SaveAndExitModalTitle(props: SaveAndExitModalTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} variant="h4">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface SaveAndExitModalProps {
  title: string;
  description: string;
  onClose: () => void;
  onExit: () => void;
  onSave: () => void;
  exitBtnText?: string;
  saveBtnText?: string;
  secondaryText?: string;
}
const SaveAndExitModal = ({
  title = '',
  description = '',
  onClose = () => {},
  onExit = () => {},
  onSave = () => {},
  exitBtnText = 'Exit Without Saving',
  saveBtnText = 'Save',
  secondaryText,
}: SaveAndExitModalProps) => {
  const DialogModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      minWidth: '512px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '80dvw',
      },
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <DialogModal onClose={onClose} aria-labelledby="customized-dialog-title" open={true}>
      <SaveAndExitModalTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </SaveAndExitModalTitle>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle1">{description}</Typography>
        <Typography variant="subtitle1">{secondaryText}</Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="contained" text={saveBtnText} onClick={onSave} width="large" />
        <CustomButton variant="contained_red" text={exitBtnText} onClick={onExit} width="large" />
      </DialogActions>
    </DialogModal>
  );
};

export default SaveAndExitModal;
