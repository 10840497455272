import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../types';
import { generateTheme } from '../themes';

import { initialState } from '.';

export const selectTheme = createSelector([(state: RootState) => state?.theme || initialState], () => {
  // For Theme switching
  // if (theme?.selected === 'system') {
  //   return isSystemDark ? generateTheme('dark') : generateTheme('light');
  // }
  // return generateTheme(theme?.selected);

  return generateTheme('light');
});

export const selectThemeKey = createSelector(
  [(state: RootState) => state?.theme || initialState],
  (theme) => theme.selected,
);
