import { Box, styled } from '@mui/material';

export const Worklog = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50%',
  minWidth: '500px',
  maxWidth: '640px',
  flexDirection: 'column',
  justifyContent: 'start',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minWidth: '0',
    width: '100%',
  },
}));
