import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Job = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '64px',
  marginBottom: theme.spacing(1),
  ':hover': {
    backgroundColor: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
}));
