import { PaletteMode } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { MuiAccordion as MuiAccordionBase } from './themes/base/components/MuiAccordion';
import { MuiAccordionSummary as MuiAccordionSummaryBase } from './themes/base/components/MuiAccordionSummary';
import { MuiAutoComplete as MuiAutoCompleteBase } from './themes/base/components/MuiAutoComplete';
import { MuiButton as MuiButtonBase } from './themes/base/components/MuiButton';
import { MuiButtonBase as MuiButtonBaseBase } from './themes/base/components/MuiButtonBase';
import { MuiDatePicker as MuiDatePickerBase } from './themes/base/components/MuiDatePicker';
import { MuiMenuItem as MuiMenuItemBase } from './themes/base/components/MuiMenuItem';
import { MuiOutlinedInput as MuiOutlinedInputBase } from './themes/base/components/MuiOutlinedInput';
import { MuiSelect as MuiSelectBase } from './themes/base/components/MuiSelect';
import { MuiTextField as MuiTextFieldBase } from './themes/base/components/MuiTextField';
import { MuiTypography as MuiTypographyBase } from './themes/base/typography/MuiTypography';
import { primary as primaryDark } from './themes/dark/palette/primary';
import { MuiTypography as MuiTypographyDark } from './themes/dark/typography/MuiTypography';
import { background as backgroundLight } from './themes/light/palette/background';
import { primary as primaryLight } from './themes/light/palette/primary';
import { secondary as secondaryLight } from './themes/light/palette/secondary';
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    busmanColors: {
      approvedLog: string;
      busmanPrimary: string;
      busmanSecondary: string;
      butmanAccent: string;
      busmanLight: string;
      busmanRed: string;
      busmanGreen: string;
      busmanWarn: string;
      darkGrey: string;
      lightGrey: string;
      busmanBlue: string;
      gradient: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle2_italic: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    contained_blue: true;
    contained_red: true;
    contained_lightBlue: true;
    contained_orange: true;
    contained_grey: true;
    contained_purple: true;
    contained_transparent: true;
  }
}

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    primary: mode === 'dark' ? { ...primaryDark } : { ...primaryLight },
    secondary: {
      ...secondaryLight,
    },
    background: {
      ...backgroundLight,
    },
    busmanColors: {
      approvedLog: '#c8e6c9',
      busmanPrimary: '#9531FF',
      busmanSecondary: '#ED988C',
      busmanAccent: '#F2AB50',
      busmanLight: '#ca99ff',
      busmanGreen: '#5CB85C',
      busmanRed: '#D9534F',
      busmanWarn: '#ffda47',
      darkGrey: '#616161',
      lightGrey: '#9E9E9E',
      busmanBlue: '#00A7FF',
      gradient: 'var(--gradient-bus-min-purple-vertical, linear-gradient(0deg, #9531FF 0%, #D930FF 100%))',
    },
  },
  typography: mode === 'dark' ? { ...MuiTypographyDark } : { ...MuiTypographyBase },
  components: {
    ...MuiButtonBaseBase,
    ...MuiButtonBase,
    ...MuiAccordionBase,
    ...MuiAccordionSummaryBase,
    ...MuiTextFieldBase,
    ...MuiAutoCompleteBase,
    ...MuiSelectBase,
    ...MuiMenuItemBase,
    ...MuiOutlinedInputBase,
    ...MuiDatePickerBase,
  },
});

export const generateTheme = (ThemePalette: PaletteMode) => {
  let theme = createTheme(getDesignTokens(ThemePalette));
  theme = responsiveFontSizes(theme);
  return theme;
};
