import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { DateRange as DateRageIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface DatePickerProps {
  onChange?: (arg: Dayjs | null) => void;
  value: Dayjs | null;
  allowMaxDate?: boolean;
  disabled?: boolean;
  minDate?: Dayjs;
}

export default function BasicDatePicker({
  allowMaxDate = false,
  disabled = false,
  value,
  onChange = () => {},
  minDate,
}: DatePickerProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [adornmentFocus, setAdornmentFocus] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleWindowBlur = () => {
      setOpen(false);
    };
    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          minDate={minDate ? minDate : undefined}
          disabled={disabled}
          inputFormat="DD/MM/YYYY"
          open={open}
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          onError={(e) => {
            if (e === null) setError(false);
            else setError(true);
          }}
          InputProps={{
            sx: {
              pl: 0,
            },
          }}
          onClose={() => setOpen(false)}
          maxDate={allowMaxDate ? undefined : dayjs()}
          InputAdornmentProps={{
            position: 'start',
            onFocus: () => setAdornmentFocus(true),
            onBlur: () => setAdornmentFocus(false),
            sx: {
              '.MuiIconButton-root': { margin: 0 },
              backgroundColor: theme.palette.background.default,
              height: '100%',
              width: '32px',
              padding: 0,
              maxHeight: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `1px ${theme.palette.background.default} solid`,
            },
          }}
          PopperProps={{
            placement: 'bottom-start',
          }}
          components={{
            OpenPickerIcon: () => <DateRageIcon />,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onTouchStart={() => setOpen(true)}
              fullWidth
              onClick={() => setOpen(true)}
              sx={{
                svg: { '&:hover': {}, border: adornmentFocus ? `1px solid ${theme.palette.primary.light}` : '' },
                '.MuiOutlinedInput-input': { pt: 1, pb: 1, color: error ? 'red' : 'black' },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

// import * as React from 'react';
// import { useEffect, useState } from 'react';
// import dayjs, { Dayjs } from 'dayjs';

// import { DateRange as DateRageIcon } from '@mui/icons-material';
// import Box from '@mui/material/Box';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// interface DatePickerProps {
//   onChange?: (arg: Dayjs | null) => void;
//   value: Dayjs | null;
//   allowMaxDate?: boolean;
//   disabled?: boolean;
// }

// export default function BasicDatePicker({
//   allowMaxDate = false,
//   disabled = false,
//   value,
//   onChange = () => {},
// }: DatePickerProps) {
//   const [open, setOpen] = useState(false);

//   useEffect(() => {
//     const handleWindowBlur = () => {
//       setOpen(false);
//     };
//     window.addEventListener('blur', handleWindowBlur);
//     return () => {
//       window.removeEventListener('blur', handleWindowBlur);
//     };
//   }, []);

//   return (
//     <Box>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DatePicker
//           // format="DD/MM/YYYY"
//           disabled={disabled}
//           open={open}
//           value={value}
//           onClose={() => setOpen(false)}
//           onChange={(newValue) => {
//             onChange(newValue);
//           }}
//           maxDate={allowMaxDate ? undefined : dayjs()}
//           slots={{
//             openPickerIcon: () => <DateRageIcon />,
//           }}
//           sx={{}}
//           slotProps={{
//             textField: {
//               variant: 'outlined',
//               fullWidth: true,
//               onClick: () => setOpen(!open),
//               sx: {
//                 '.MuiOutlinedInput-input': { p: 1 },
//                 '.MuiOutlinedInput-notchedOutline': {
//                   border: 'none',
//                 },
//               },
//             },
//             inputAdornment: { position: 'start' },
//           }}
//         />
//       </LocalizationProvider>
//     </Box>
//   );
// }
