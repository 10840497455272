import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import DataNotFound from 'components/DataNotFound';
import { PageContainer } from 'components/GlobalStyles/styles';
import { Header } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import { useGetClientByIdQuery, useGetJobsByClientQuery } from 'services/busmanApi';

import { ClientContainer, ClientDetailsField, ClientMYOBDetails, JobsSection, MyobFields } from './styles';

const ClientDetail = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const { data: selectedClient, isFetching } = useGetClientByIdQuery(params.clientID);

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: jobs, isLoading, isSuccess } = useGetJobsByClientQuery(params.clientID);

  const renderMYOBDetails = () => {
    if (!selectedClient) return null;
    return (
      <>
        <MyobFields>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2">CONTACT NAME</Typography>
            <ClientDetailsField>
              <Typography variant="body1">{selectedClient.address.contact_name}</Typography>
            </ClientDetailsField>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2">ADDRESS</Typography>
            <ClientDetailsField>
              <Typography variant="body1">
                {selectedClient.address.street !== undefined &&
                  `${selectedClient.address.street} ${selectedClient.address.state} ${selectedClient.address.postal_code}`}
              </Typography>
            </ClientDetailsField>
          </Box>
        </MyobFields>
        <MyobFields>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2">PHONE</Typography>
            <ClientDetailsField>
              <Typography variant="body1">{selectedClient.address.phone_number}</Typography>
            </ClientDetailsField>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2">EMAIL</Typography>
            <ClientDetailsField>
              <Typography variant="body1">{selectedClient.address.email}</Typography>
            </ClientDetailsField>
          </Box>
        </MyobFields>
      </>
    );
  };

  return (
    <>
      <Loader open={isLoading || isFetching} />
      {!isLoading && !selectedClient && !isSuccess ? (
        <DataNotFound errorMessage="Client not found" url={'clients'} />
      ) : (
        <>
          <PageContainer>
            <ClientContainer>
              {selectedClient && (
                <>
                  <Header>
                    <Typography variant="h3">{selectedClient.client_name}</Typography>
                  </Header>
                  <Header sx={{ borderBottom: `1px solid ${theme.palette.background.default}` }}>
                    <Typography variant="h4">MYOB Contact Info</Typography>
                  </Header>
                  <ClientMYOBDetails>{renderMYOBDetails()}</ClientMYOBDetails>

                  {!isLoading && jobs && jobs.length < 1 ? (
                    <Header sx={{ borderBottom: `1px solid ${theme.palette.background.default}` }}>
                      <Typography variant="h4">No Jobs Found</Typography>
                    </Header>
                  ) : (
                    <Header sx={{ borderBottom: `1px solid ${theme.palette.background.default}` }}>
                      <Typography variant="h4">Jobs</Typography>
                    </Header>
                  )}
                </>
              )}
              {jobs && jobs.length > 0 && (
                <>
                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent={'space-between'}
                    padding={theme.spacing(2)}
                    paddingBottom={0}>
                    <GridItem title="NUMBER" seenOrIsHeading={true} breakpoint={3} />
                    <GridItem title="NAME" seenOrIsHeading={true} breakpoint={5} />
                    {!isMobileScreen && <GridItem title="STATUS" seenOrIsHeading={true} breakpoint={2} />}
                  </Grid>
                  <JobsSection>
                    {isLoading && <Typography variant="h4">Loading Jobs...</Typography>}

                    {jobs.map((job, idx) => {
                      return (
                        <Grid
                          key={`job${idx}`}
                          container
                          display="flex"
                          flexDirection="row"
                          justifyContent={'space-between'}
                          onClick={() => navigate(`/jobs/${job.job_id}`)}
                          sx={{
                            backgroundColor: theme.palette.background.default,
                            paddingY: theme.spacing(2),
                            borderRadius: '2px',
                            '&:hover': {
                              backgroundColor: '#9E9E9E',
                              cursor: 'pointer',
                            },
                          }}>
                          <GridItem title={job.job_number} seenOrIsHeading={false} breakpoint={3} />
                          <GridItem title={job.job_name} seenOrIsHeading={false} breakpoint={5} />
                          {!isMobileScreen && (
                            <GridItem title={job.job_status} seenOrIsHeading={false} breakpoint={2} />
                          )}
                        </Grid>
                      );
                    })}
                  </JobsSection>
                </>
              )}
            </ClientContainer>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default ClientDetail;
