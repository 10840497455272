import { Box, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '45%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const DateRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: 'auto',
    gap: theme.spacing(1),
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const JobSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.busmanColors.busmanPrimary,
  color: theme.palette.primary.contrastText,
  fontWeight: 700,
  fontSize: '11px',
  height: '32px',
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: '120px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.busmanColors.darkGrey,
    color: '#9E9E9E',
    opacity: 0.5,
    fontWeight: 700,
    fontSize: '11px',
    height: '32px',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '120px',
    '& .MuiSvgIcon-root': {
      color: theme.palette.busmanColors.lightGrey,
    },
  },
}));

export const JobStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  marginTop: theme.spacing(2),
}));
