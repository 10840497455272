import dayjs, { Dayjs } from 'dayjs';

export const convertDateToMills = (givenDate: Date) => {
  return dayjs(givenDate).valueOf();
};

export const formatDate = (value: Dayjs | null, date?: Dayjs | null) => {
  return dayjs(value)
    .set('year', dayjs(date || value).get('year'))
    .set('month', dayjs(date || value).get('month'))
    .set('date', dayjs(date || value).get('date'))
    .format();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortWorkLogsByDescDate = (dataA: any, dataB: any) => {
  const dateA = dayjs(dataA?.start_time || dataA?.date);
  const dateB = dayjs(dataB?.start_time || dataB?.date);
  // Compare the 2 dates
  if (dateB < dateA) return -1;
  if (dateB > dateA) return 1;
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortWorkLogsByAscDate = (dataA: any, dataB: any) => {
  const dateA = dayjs(dataA?.start_time || dataA?.date);
  const dateB = dayjs(dataB?.start_time || dataB?.date);
  // Compare the 2 dates
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  return 0;
};

export const CalculateTotalHours = (startTime: Dayjs | null, endTime: Dayjs | null, breakTime: number) => {
  if (startTime !== null && endTime !== null) {
    if (breakTime > 0) {
      const newEndTime = endTime.subtract(breakTime, 'minute');
      return newEndTime.diff(startTime, 'hour', true);
    } else {
      return endTime.diff(startTime, 'minute') / 60;
    }
  }

  return 0;
};

export const generateDateRange = (from: Dayjs | null, to: Dayjs | null) => {
  if (from === null || to === null) return [];

  // Array to store generated dates
  const generatedDates = [];

  // Iterate through the date range and generate dates from the range
  let currentDate = from;

  while (currentDate.startOf('day').isSameOrBefore(to.startOf('day'))) {
    generatedDates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return generatedDates;
};

export const getBusinessDaysInRange = (fromDate: Dayjs | null, toDate: Dayjs | null) => {
  const selectedDateRange = generateDateRange(fromDate, toDate);
  const publicHolidayData = localStorage.getItem('public-holiday');
  const holidays = publicHolidayData ? JSON.parse(publicHolidayData) : [];

  // Our public holiday data will only return data for a specific year, or a range of years
  // In the case of range of years 2021-2025 is the current max. This will only return 2021 holidays as there has been no update to the data beyond 2021
  // We will format the data to only show month and day to compare with our selected date range
  // https://data.gov.au/dataset/ds-dga-b1bc6077-dadd-4f61-9f8c-002ab2cdff10/details
  const parsedHolidayData = holidays.map((holiday: string) => dayjs(holiday).format('MM-DD'));

  const totalDays = selectedDateRange?.filter(
    (date) =>
      dayjs(date).day() !== 0 && dayjs(date).day() !== 6 && !parsedHolidayData.includes(dayjs(date).format('MM-DD')),
  );

  return totalDays;
};
