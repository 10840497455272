export const MuiTypography = {
  h1: {
    fontSize: '34px',
  },
  h3: {
    fontSize: '20px',
    fontWeight: '400',
  },
  h4: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#282828',
  },
  h5: {
    fontSize: '14px',
    fontWeight: '400',
  },
  h6: {
    fontSize: '12px',
    fontWeight: '500',
  },
  subtitle1: {
    fontSize: '12px',
    fontWeight: '400',
  },
  subtitle2: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#9E9E9E',
  },
  subtitle2_italic: {
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'italic',
  },
  body1: {
    fontSize: '10px',
    fontWeight: '500',
  },
  body2: {
    fontSize: '10px',
    fontWeight: '400',
    color: '#9E9E9E',
  },
};
