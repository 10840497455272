import React from 'react';
import { useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RootState } from 'store';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Box,
  TableCell,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material';

import CustomButton from 'components/Button';
import { LineItem } from 'types/QuoteTypes';
import { getHistoricalRate } from 'utils/activities';

import { CloseButton, DuplicateIcon } from '../styles';

import ActivityIcon from './ActivityIcon';
import InventoryIcon from './InventoryIcon';
import TextFieldBox from './TextFieldBox';

const tableCell = {
  borderBottom: 'none',
  padding: '8px',
  overFlow: 'hidden',
};

interface LineItemProps {
  row: LineItem;
  lineHeaderIdx: number;
  idx: number;
  handleSetLineItemValues: (key: string, value: any, lineHeaderIdx: number, idx: number) => void;
  handleDuplicateLine: (idx: number) => void;
  handleDeleteLine: (key: string, lineHeaderIdx: number, idx: number) => void;
  quotedAtDate: string;
}

const NewLineItem = ({
  row,
  lineHeaderIdx,
  idx,
  handleSetLineItemValues,
  handleDuplicateLine,
  handleDeleteLine,
  quotedAtDate,
}: LineItemProps) => {
  const theme = useTheme();
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const filteredActivities = activities.filter((activity) => activity.status === 'Chargeable');
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.id,
    transition: {
      duration: 200,
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  });
  const descriptionRef = React.useRef(null);

  const getLineItemTotal = (lineItem: LineItem) => {
    if (lineItem.lineType === 'Inventory') return lineItem.units * Number(lineItem.customCost) - lineItem.discount;

    const lineRate =
      lineItem.activityDetail && getHistoricalRate(activities, lineItem.activityDetail.activityId, quotedAtDate);
    return Number(lineItem.units) * Number(lineRate) - lineItem.discount;
  };

  const handleCustomRateSwitch = () => {
    if (row.lineType === 'Inventory') {
      handleSetLineItemValues('customCost', null, lineHeaderIdx, idx);
      handleSetLineItemValues('lineType', 'Activity', lineHeaderIdx, idx);
    }

    if (row.lineType === 'Activity') {
      handleSetLineItemValues('activityDetail', null, lineHeaderIdx, idx);
      handleSetLineItemValues('lineType', 'Inventory', lineHeaderIdx, idx);
    }
  };

  const draggableStyle = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    width: '100%',
  };

  return (
    <TableRow sx={{ verticalAlign: 'top', backgroundColor: 'white' }} ref={setNodeRef} style={draggableStyle}>
      <TableCell sx={tableCell}>
        <TextFieldBox
          required
          label="00"
          placeholderText={true}
          value={row.units}
          purpose=""
          onChangeFn={(key, value) => handleSetLineItemValues(key, value, lineHeaderIdx, idx)}
          formKey="units"
          type="Number"
        />
      </TableCell>
      <TableCell sx={tableCell}>
        <Box>
          <TextField
            required
            inputProps={{ min: 0 }}
            multiline
            inputRef={descriptionRef}
            sx={{
              width: '100%',
              height: '100%',
              direction: 'ltr',
              'input::-webkit-inner-spin-button': {
                ' -webkit-appearance': 'auto',
              },
            }}
            size="small"
            variant="outlined"
            placeholder="DESCRIPTION"
            defaultValue={row.description}
            onBlur={(e) => handleSetLineItemValues('description', e.target.value, lineHeaderIdx, idx)}
          />
        </Box>
      </TableCell>
      <TableCell sx={tableCell}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #F2F2F2',
          }}>
          {row.lineType === 'Inventory' ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                'input::-webkit-inner-spin-button': {
                  ' -webkit-appearance': 'none ',
                },
                '.MuiOutlinedInput-root': { paddingRight: 0, border: 'none', '&:hover': { border: 'none' } },
              }}>
              <TextField
                inputProps={{ min: 0 }}
                type="number"
                size="small"
                variant="outlined"
                placeholder={'CUSTOM RATE'}
                value={row.customCost}
                onChange={(e) => {
                  handleSetLineItemValues('customCost', e.target.value, lineHeaderIdx, idx);
                }}
              />
            </Box>
          ) : (
            <Autocomplete
              size="small"
              sx={{
                width: '75%',
                border: 'none',
              }}
              value={row.activityDetail}
              options={filteredActivities.map((activity) => {
                const rate = getHistoricalRate(activities, activity.activity_id, quotedAtDate);
                return {
                  activityName: activity.activity_name,
                  rate: rate,
                  activityId: activity.activity_id,
                };
              })}
              popupIcon={<ExpandMoreIcon fontSize="small" />}
              getOptionLabel={(option) => option.activityName || ''}
              isOptionEqualToValue={(option, value) => option.activityName === value.activityName}
              onChange={(event, value) => {
                handleSetLineItemValues('activityDetail', value, lineHeaderIdx, idx);
              }}
              ListboxProps={{
                style: {
                  fontSize: '10px',
                  textAlign: 'left',
                  padding: '0',
                },
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="SELECT ACTIVITY"
                  sx={{ '.MuiOutlinedInput-root': { border: 'none' } }}
                  {...params}
                  required
                />
              )}
            />
          )}
          <ToggleButtonGroup
            sx={{
              height: '24px',
              width: '46px',
              backgroundColor: theme.palette.background.default,
              display: 'flex',
              justifyContent: 'space-between',
            }}
            value={row.lineType}
            exclusive
            onChange={() => handleCustomRateSwitch()}>
            <Tooltip title="Activity Item" sx={{ cursor: 'pointer' }}>
              <ToggleButton
                sx={{ border: 'none', width: '50%', padding: 'none' }}
                onMouseUp={(e) => e.currentTarget.blur()}
                value="activity">
                <ActivityIcon lineType={row.lineType} />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Inventory Item" sx={{ cursor: 'pointer' }}>
              <ToggleButton
                sx={{ border: 'none', width: '50%' }}
                onMouseUp={(e) => e.currentTarget.blur()}
                value="inventory">
                <InventoryIcon lineType={row.lineType} />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Box>
      </TableCell>
      <TableCell sx={tableCell}>
        <TextFieldBox
          label="discount"
          placeholderText={true}
          purpose=""
          onChangeFn={(key, value) => handleSetLineItemValues(key, value, lineHeaderIdx, idx)}
          formKey="discount"
          value={row.discount}
          type="Number"
        />
      </TableCell>
      <TableCell sx={{ ...tableCell, textAlign: 'right' }}>
        <Box
          sx={{
            textAlign: 'left',
            width: '100%',
            backgroundColor: '#F2F2F2',
            height: '32px',
            padding: '8px 16px 8px 16px',
          }}>
          ${getLineItemTotal(row).toFixed(2)}
        </Box>
      </TableCell>
      <TableCell sx={{ ...tableCell, position: 'relative' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <CustomButton
            variant="contained_transparent"
            width="small"
            toolTip="Duplicate Item"
            text={<DuplicateIcon sx={{ fontSize: '22px' }} />}
            onClick={(e) => handleDuplicateLine(idx)}
          />
          <CustomButton
            variant="contained_transparent"
            width="small"
            toolTip="Remove Item"
            text={<CloseButton sx={{ fontSize: '30px' }} />}
            onClick={(e) => handleDeleteLine('lineHeaders', lineHeaderIdx, idx)}
          />
          <Box {...attributes} {...listeners}>
            <CustomButton
              variant="contained_transparent"
              width="small"
              text={
                <DragHandleIcon
                  sx={{
                    fontSize: '20px',
                    color: theme.palette.primary.light,
                    cursor: 'grab',
                    padding: '0 !important',
                  }}
                />
              }
            />
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default NewLineItem;
