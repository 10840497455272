import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import dayjs, { Dayjs } from 'dayjs';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DatePicker from 'components/DatePicker';
import {
  FilterPanelContainer,
  HeaderUnderline,
  ItemListContainer,
  NoDataBox,
  PageContainer,
} from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import { findMissingLogDays } from 'pages/WorkLog/helper';
import { useGetTeamsQuery, useGetWorklogsQuery } from 'services/busmanApi';
import { Team } from 'services/busmanApi/types';
import { WorklogType } from 'types/WorkLogTypes';
import { SortWorkLogsByAscDate } from 'utils/date';
import { sortLists } from 'utils/sort';

import { RootState } from '../../store';

import { EmployeeBox, Row } from './styles';

interface LogType {
  date: string;
  totalHours: number;
}

interface EmployeeOption {
  id: string;
  name: string;
}

interface CustomTeam {
  team_id: string;
  team_name: string;
  members: Array<EmployeeOption>;
}

const MissingLogsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(2, 'week'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const [selectedTeam, setSelectedTeam] = React.useState<CustomTeam | null>(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState<EmployeeOption | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: teams, isFetching } = useGetTeamsQuery(user?.user_id);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const logParams = selectedEmployee && {
    empID: selectedEmployee?.id,
    startDate: dayjs(fromDate).format('YYYY-MM-DD'),
    endDate: dayjs(toDate).format('YYYY-MM-DD'),
    page: 1,
  };
  const { data: { sortedWorklogs } = { sortedWorklogs: [] } } = useGetWorklogsQuery(logParams, {
    skip: !selectedEmployee,
  });

  // Get all teams(parent/child) with members only employee
  const getAllTeams = (data: Array<Team>) => {
    const newTeams: any[] = [];

    function processTeam(team: Team) {
      newTeams.push({
        team_id: team.team_id,
        team_name: team.team_name,
        members: team.members
          ?.map((member) => {
            if (member.is_team) {
              return processTeam(member.team); // Recursively process nested teams
            } else {
              return { id: member.employee.user_id, name: member.employee.full_name }; // Only include employees, not teams
            }
          })
          .flat()
          .filter((member) => member),
      });
    }

    data.forEach((team) => processTeam(team));

    // Filter team with sorted members
    const sortedTeam = newTeams
      .map((teamData) => {
        if (teamData.team_id && teamData.members?.length) {
          return { ...teamData, members: sortLists(teamData.members, 'name') };
        }
        return null;
      })
      .filter((team) => team);

    return sortedTeam;
  };

  // Returns all log days with date and totalhr excluding weekends and public holiday
  const getAllLogsDay = () => {
    const missingLogDays = findMissingLogDays(sortedWorklogs, fromDate, toDate);

    const logDates = sortedWorklogs.map((data) => {
      const hours = data.worklogs?.reduce((totalhrs: number, worklog: WorklogType) => {
        return totalhrs + worklog.worklog_hours;
      }, 0);

      return { date: data.date, totalHours: hours };
    });

    let allLogs = [...logDates];

    if (missingLogDays.length) {
      const missingLogs = missingLogDays.map((date: string) => {
        return { date: date, totalHours: 0 };
      });
      allLogs = [...logDates, ...missingLogs];
    }

    return allLogs.sort(SortWorkLogsByAscDate);
  };

  const teamsArray = React.useMemo(() => {
    return teams?.length ? getAllTeams(teams) : [];
  }, [teams]);

  const allLogDays = React.useMemo(() => {
    return getAllLogsDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedWorklogs, fromDate, toDate]);

  const employeeTotalHours = allLogDays?.reduce((hours: number, log: LogType) => {
    return hours + log?.totalHours;
  }, 0);

  const setSelectionInSession = (teamName?: string, employeeName?: string) => {
    sessionStorage.setItem('missingLogs-selection', JSON.stringify({ teamName: teamName, employeeName: employeeName }));
  };

  const checkSessionForSelections = () => {
    let selectTeam = null;
    let selectEmployee = null;
    if (sessionStorage.getItem('missingLogs-selection')) {
      const selection = JSON.parse(sessionStorage.getItem('missingLogs-selection') || '');
      selectTeam = teamsArray.find((team) => team.team_name === selection?.teamName);
      selectEmployee = selectTeam?.members.find((member: EmployeeOption) => member.name === selection?.employeeName);
    }
    return { sessionTeam: selectTeam, sessionEmployee: selectEmployee };
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    setFromDate(value);
  };

  const handleToDateChange = (value: Dayjs | null) => {
    setToDate(value);
  };

  const handleSelectTeam = (team: CustomTeam | null) => {
    setSelectedTeam(team);
    const selectInitialEmp = team?.members?.length ? team?.members[0] : null;
    setSelectedEmployee(selectInitialEmp);
    if (team) setSelectionInSession(team?.team_name, selectInitialEmp?.name);
  };

  const handleSelectEmployee = (value: EmployeeOption | null) => {
    setSelectedEmployee(value);
    if (value) setSelectionInSession(selectedTeam?.team_name, value?.name);
  };

  React.useEffect(() => {
    if (teamsArray?.length) {
      const { sessionTeam, sessionEmployee } = checkSessionForSelections();

      setSelectedTeam(sessionTeam || teamsArray[0]);
      setSelectedEmployee(sessionEmployee || teamsArray[0].members[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const renderLogLists = () => {
    const rowRenderer = ({ index, style, data }: ListChildComponentProps<LogType[]>) => {
      const log = data[index];
      return (
        <Row
          container
          key={log.date}
          style={style}
          onClick={() => navigate(`/employeeworklogs/${selectedEmployee?.id}`)}
          sx={{
            border: 1,
            color: theme.palette.background.default,
          }}>
          <GridItem title={log.date} seenOrIsHeading={false} breakpoint={6} />
          <GridItem
            title={log.totalHours.toString()}
            seenOrIsHeading={false}
            breakpoint={4}
            textColor={log.totalHours < 6 ? theme.palette.busmanColors.busmanRed : ''}
          />
          <Grid
            alignItems="center"
            display="flex"
            flexDirection="row"
            item
            justifyContent="end"
            // lg={1}
            md={2}
            sm={2}
            xs={2}
            sx={{ paddingInlineEnd: theme.spacing(2) }}>
            <ArrowForwardIos sx={{ color: theme.palette.text.disabled, fontSize: '0.8rem' }} />
          </Grid>
        </Row>
      );
    };

    return (
      <ItemListContainer>
        <Grid container display="flex" flexDirection="row">
          <GridItem title={'DATE'} seenOrIsHeading={true} breakpoint={6} />
          <GridItem title={'TOTAL HOURS'} seenOrIsHeading={true} breakpoint={4} />
        </Grid>

        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemCount={allLogDays?.length}
              itemData={allLogDays}
              itemSize={60}
              className="no-scrollbars">
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </ItemListContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-MissingLogs" />
      </Helmet>
      <Loader open={isFetching} />
      <PageContainer>
        <>
          {filterPanel && (
            <FilterPanelContainer>
              {!isMobileScreen && (
                <Typography
                  gutterBottom
                  sx={{
                    mb: theme.spacing(4),
                  }}
                  variant="h4">
                  Missing Worklogs
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography
                  sx={{
                    mb: theme.spacing(0.5),
                    color: theme.palette.text.disabled,
                  }}
                  variant="body2">
                  TEAM
                </Typography>
                <Autocomplete
                  size="small"
                  value={selectedTeam}
                  options={teamsArray}
                  getOptionLabel={(option: CustomTeam) => `${option.team_name}`}
                  isOptionEqualToValue={(option, value) => option.team_name === value.team_name}
                  onChange={(event, value) => handleSelectTeam(value)}
                  ListboxProps={{
                    style: {
                      fontSize: '12px',
                    },
                  }}
                  popupIcon={<ExpandMoreIcon fontSize="small" />}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}>
                <Typography
                  sx={{
                    mb: theme.spacing(0.5),
                    color: theme.palette.text.disabled,
                  }}
                  variant="body2">
                  EMPLOYEE
                </Typography>
                <Autocomplete
                  size="small"
                  value={selectedEmployee}
                  options={selectedTeam?.members || []}
                  getOptionLabel={(option: EmployeeOption) => `${option.name}`}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  onChange={(event, value) => handleSelectEmployee(value)}
                  ListboxProps={{
                    style: {
                      fontSize: '12px',
                    },
                  }}
                  popupIcon={<ExpandMoreIcon fontSize="small" />}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography variant="body2" gutterBottom>
                  FROM
                </Typography>
                <DatePicker allowMaxDate onChange={handleFromDateChange} value={fromDate} />
                <Typography variant="body2" gutterBottom mt={1}>
                  TO
                </Typography>
                <DatePicker allowMaxDate onChange={handleToDateChange} value={toDate} />
              </Box>
            </FilterPanelContainer>
          )}
          {!selectedEmployee ? (
            <NoDataBox>
              <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
                No Employee Selected
              </Typography>
            </NoDataBox>
          ) : (
            <EmployeeBox>
              <HeaderUnderline>
                <Typography variant="h6">{selectedEmployee?.name}</Typography>
              </HeaderUnderline>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '244px',
                    padding: theme.spacing(2),
                  }}>
                  <Typography variant="body2">TOTAL WORKING HOURS</Typography>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      mb: theme.spacing(2),
                    }}>
                    <Typography variant="body1" sx={{ pl: theme.spacing(1) }}>
                      {employeeTotalHours}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {renderLogLists()}
            </EmployeeBox>
          )}
        </>
      </PageContainer>
    </>
  );
};

export default MissingLogsPage;
