import { Box, styled } from '@mui/material';

export const Client = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '64px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const ClientsList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flexGrow: 2,
  height: '95vh',
  maxWidth: '928px',
  padding: theme.spacing(2),
  overflow: 'auto',
}));
