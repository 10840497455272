import { Box, styled } from '@mui/material';

export const ApprovalList = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  overflowY: 'scroll',
  overflowX: 'hidden',
}));

export const ApprovalListContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  // padding: theme.spacing(2),
  width: 'auto',
  height: '94%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    maxWidth: '250px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    height: 'fit-content',
    marginRight: theme.spacing(1),
  },
}));

export const ApproveWorklogPanel = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: theme.spacing(1),
  height: '95vh',
  width: 'auto',
  borderRadius: '2px',
  maxWidth: '300px',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    maxWidth: '250px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    height: 'fit-content',
    marginRight: theme.spacing(1),
  },
}));
