import { styled } from '@mui/material';
import { Box, ToggleButton as MuiToggleButton, ToggleButtonGroup } from '@mui/material';

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  color: theme.palette.primary.light,
  border: 'none',
  width: '100%',
  justifyContent: 'flex-start',
  textTransform: 'none',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
  },
  borderRadius: '2px',
  '&:hover': { backgroundColor: 'transparent', color: '#9E9E9E' },
}));

export const ToggleButtonPanel = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected': {
    borderRadius: '2px',
  },
  width: '100%',
  marginBottom: theme.spacing(4),
  borderRadius: '2px',
  gap: '8px',
  border: `4px solid ${theme.palette.background.default}`,
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const MYOBContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  width: '928px',
  maxWidth: '928px',
  overflowY: 'auto',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
  },
}));

export const MYOBFilterPanel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '95vh',
  marginBottom: theme.spacing(4),
  maxWidth: '312px',
  padding: theme.spacing(2),
  width: 'auto',

  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    maxWidth: '250px',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'fit-content',
    marginRight: theme.spacing(1),
    maxWidth: '100%',
  },
}));

export const MainBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  height: 'fit-content',
  maxWidth: '928px',
  overflowY: 'auto',
  padding: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const CategoryMapBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
