import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { FilterButtons, FilterPanelContainer, PageContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import MYOBSync from 'features/MYOBSync';
import {
  useGetBusmanPayrollCategoriesQuery,
  useGetMYOBPayrollCategoriesQuery,
  useUpdateCategoriesMutation,
  useUpdateLeaveCategoriesMutation,
} from 'services/busmanApi';
import { ActivitiesProps, PayrollCategoryTypes } from 'types/WorkLogTypes';

import { RootState } from '../../store';

import { CategoryMapBox, MYOBContainer, ToggleButton, ToggleButtonPanel } from './styles';

type MappedCategoriesTypes = Record<string, string>;

const SELECTION_OPTIONS = {
  PAYROLL: 'payroll',
  LEAVE: 'leave',
  SYNC: 'sync',
};

const MYOBConfigurePage = () => {
  const theme = useTheme();
  const [alignment, setAlignment] = React.useState(SELECTION_OPTIONS.PAYROLL);
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const [leaveCategories, setLeaveCategories] = React.useState<Array<PayrollCategoryTypes>>([]);
  const [mappedCategories, setMappedCategories] = React.useState<MappedCategoriesTypes>({});
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: MYOBPayrollCategories, isFetching: fetchingMYOB } = useGetMYOBPayrollCategoriesQuery({});
  const { data: busmanPayrollCategories, isFetching: fetchingBusman } = useGetBusmanPayrollCategoriesQuery({});
  const [updateCategories, { isLoading: updating }] = useUpdateCategoriesMutation();
  const [updateLeaveCategories] = useUpdateLeaveCategoriesMutation();

  const updateMappedCategories = (key: string, value: string) => {
    const updatedMappedCategories = { ...mappedCategories, [key]: value };
    setMappedCategories(updatedMappedCategories);
  };

  // Sort out leave
  React.useEffect(() => {
    if (busmanPayrollCategories) {
      const leaveArray: Array<PayrollCategoryTypes> = [];
      busmanPayrollCategories.forEach((item: PayrollCategoryTypes) => {
        if (item.category_type === 'Leave') {
          leaveArray.push(item);
        }
      });

      setLeaveCategories(leaveArray);
    }
  }, [busmanPayrollCategories]);

  const handleOnSave = async () => {
    if (alignment === SELECTION_OPTIONS.PAYROLL) {
      const newUpdatedData = Object.entries(mappedCategories).map(([categoryId, mappedCategoryId]) => ({
        category_id: categoryId,
        mapped_category_id: mappedCategoryId,
      }));

      const result = await updateCategories(newUpdatedData);

      if ('error' in result) {
        setMessage({ type: 'error', msg: 'Error updating Payroll Categories !' });
      }

      if ('data' in result) {
        setMessage({ type: 'success', msg: 'Payroll Category updated.' });
        setMappedCategories({});
      }
    } else {
      const leaveCategoryData = Object.entries(mappedCategories).map(([categoryId, mappedCategoryId]) => ({
        payroll_category_id: categoryId,
        mapped_activity_id: mappedCategoryId,
      }));

      const response = await updateLeaveCategories(leaveCategoryData);

      if ('error' in response) {
        setMessage({ type: 'error', msg: 'Error updating Leave Categories !' });
      }

      if ('data' in response) {
        setMessage({ type: 'success', msg: 'Leave Category updated.' });
        setMappedCategories({});
      }
    }
  };

  const handleChangeAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-MYOB-Configure" />
      </Helmet>
      <Loader open={fetchingBusman || fetchingMYOB || updating} />
      <PageContainer>
        <FilterPanelContainer>
          {!isMobileScreen && (
            <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
              MYOB Configure
            </Typography>
          )}
          <ToggleButtonPanel value={alignment} exclusive orientation="vertical" onChange={handleChangeAlignment}>
            <ToggleButton value={SELECTION_OPTIONS.PAYROLL}>
              <Typography sx={{ fontSize: '11px' }} variant="body2">
                Payroll Category Mapping
              </Typography>
            </ToggleButton>
            <ToggleButton value={SELECTION_OPTIONS.LEAVE}>
              <Typography sx={{ fontSize: '11px' }} variant="body2">
                Leave Activities Mapping
              </Typography>
            </ToggleButton>
            <ToggleButton value={SELECTION_OPTIONS.SYNC}>
              <Typography sx={{ fontSize: '11px' }} variant="body2">
                MYOB Sync
              </Typography>
            </ToggleButton>
          </ToggleButtonPanel>
        </FilterPanelContainer>
        {alignment === SELECTION_OPTIONS.PAYROLL && (
          <MYOBContainer>
            {busmanPayrollCategories?.map((category: PayrollCategoryTypes) => (
              <CategoryMapBox key={category.category_id}>
                <Typography variant="h6" sx={{ width: 'fit-content' }}>
                  {category.category_name}
                </Typography>
                <Select
                  fullWidth
                  sx={{
                    maxWidth: '280px',
                    height: '32px',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      maxWidth: '100%',
                      marginBottom: theme.spacing(1),
                    },
                  }}
                  value={mappedCategories[category.category_id] || category.mapped_category_id || ''}
                  onChange={(newSelection) => updateMappedCategories(category.category_id, newSelection.target.value)}>
                  <MenuItem key={'no value'} value="">
                    Select MYOB category
                  </MenuItem>
                  {MYOBPayrollCategories?.map((MYOBCategory: PayrollCategoryTypes) => (
                    <MenuItem key={MYOBCategory.category_id} value={MYOBCategory.category_id}>
                      {MYOBCategory.category_name}
                    </MenuItem>
                  ))}
                </Select>
              </CategoryMapBox>
            ))}
            <FilterButtons sx={{ marginLeft: 'auto', mt: 1 }}>
              <CustomButton
                disabled={!Object.keys(mappedCategories).length}
                variant="contained"
                onClick={handleOnSave}
                width="medium"
                text="save"
              />
            </FilterButtons>
          </MYOBContainer>
        )}
        {alignment === SELECTION_OPTIONS.LEAVE && (
          <MYOBContainer>
            {leaveCategories?.map((category: PayrollCategoryTypes) => (
              <CategoryMapBox key={category.category_id}>
                <Typography variant="h6" sx={{ width: 'fit-content' }}>
                  {category.category_name}
                </Typography>
                <Select
                  fullWidth
                  sx={{
                    maxWidth: '280px',
                    height: '32px',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      maxWidth: '100%',
                    },
                  }}
                  value={mappedCategories[category.category_id] || category.mapped_leavelog_activities || ''}
                  onChange={(newSelection) => updateMappedCategories(category.category_id, newSelection.target.value)}>
                  <MenuItem key={'no value'} value="">
                    Select Leave Activity category
                  </MenuItem>
                  {activities?.map((activity: ActivitiesProps) => (
                    <MenuItem key={activity.activity_id} value={activity.activity_id}>
                      {activity.activity_name !== null ? activity.activity_name : activity.display_id}
                    </MenuItem>
                  ))}
                </Select>
              </CategoryMapBox>
            ))}
            <FilterButtons sx={{ marginLeft: 'auto', mt: 1 }}>
              <CustomButton
                disabled={!Object.keys(mappedCategories).length}
                variant="contained"
                onClick={handleOnSave}
                width="medium"
                text="save"
              />
            </FilterButtons>
          </MYOBContainer>
        )}
        {alignment === SELECTION_OPTIONS.SYNC && <MYOBSync setMessage={setMessage} />}
      </PageContainer>
      <SnackBar message={message} onClose={() => setMessage(null)} />
    </>
  );
};

export default MYOBConfigurePage;
