import * as React from 'react';

import SyncIcon from '@mui/icons-material/Sync';

import CustomButton from 'components/Button';

interface SyncButtonProps {
  onClick: () => void;
}
function SyncButton({ onClick }: SyncButtonProps) {
  return <CustomButton variant="contained" onClick={onClick} width="medium" text="Sync" endIcon={<SyncIcon />} />;
}

export default SyncButton;
