import { Theme } from '@mui/material/styles';

const QuoteHeaderBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: '48px',
  width: '50%',
};

const QuoteHeaderBoxEnd = {
  display: 'flex',
  flexDirection: 'column',
  gap: '48px',
  width: '50%',
  alignItems: 'flex-end',
};

const DocHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

const BackingDataContainer = (theme: Theme) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
  marginLeft: '78px',
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '@media print': {
    marginLeft: '0',
    marginTop: '0',
    width: '210mm',
  },
});

const BackingDataDateRange = (theme: Theme) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  boxShadow: theme.shadows[2],
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  top: theme.spacing(1),
  position: 'sticky',
  height: '95vh',
  borderRadius: '2px',
  maxWidth: '312px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    maxWidth: '250px',
  },
  '@media print': {
    display: 'none',
    marginRight: '0',
  },
});

const QuoteDocBox = (theme: Theme) => ({
  flexGrow: 2,
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.spacing(2),
  background: theme.palette.background.paper,
  padding: theme.spacing(4),
  gap: theme.spacing(4),
  width: '210mm',
  minHeight: '297mm',
  height: 'fit-content',
  pageBreakAfter: 'always',
  ':last-child': {
    marginTop: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: '8px',
  },
  '@media print': {
    margin: '0',
    '.page-break': {
      pageBreakAfter: 'always',
    },
  },
});

const DocSummary = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
};

const logoContainer = {
  width: '150px',
  height: '50px',
  marginBottom: '20',
};

const DocDescription = () => ({
  display: 'flex',
  gap: '16px',
});

const tableCellsm = {
  flex: 1,
  borderBottom: 'none',
  alignItems: 'flex-start',
  verticalAlign: 'top',
  paddingBottom: '32px',
};

const tableCelllg = {
  flex: 2,
  borderBottom: 'none',
  maxWidth: '220px',
  verticalAlign: 'top',
};

export {
  BackingDataContainer,
  BackingDataDateRange,
  DocDescription,
  DocHeader,
  DocSummary,
  logoContainer,
  QuoteDocBox,
  QuoteHeaderBox,
  QuoteHeaderBoxEnd,
  tableCelllg,
  tableCellsm,
};
