import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLazyGetEmployeeDetailsQuery } from 'services/busmanApi';

import { RootState } from '../../store';

import { authActions } from './authSlice';

export default function Auth({
  children,
  setServerStatus,
}: {
  children: React.ReactNode;
  setServerStatus: (status: boolean | null) => void;
}) {
  const { user } = useSelector((state: RootState) => state.auth);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);

  const [fetchUser] = useLazyGetEmployeeDetailsQuery();
  const dispatch = useDispatch();

  const getUserFromStorage = () => {
    if (sessionStorage.getItem('user') || localStorage.getItem('user')) {
      return JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user') || '');
    }

    return null;
  };

  const fetchUserInfo = async () => {
    const localState = await getUserFromStorage();

    if (!localState) {
      setIsAuthenticating(false);
      return null;
    }

    const result = await fetchUser(localState?.userID);

    if ('data' in result) {
      setServerStatus(true);
      dispatch(authActions.setCredentials({ user: result?.data, access_token: localState?.token }));
    }

    if (result.error) {
      if ('status' in result.error && result.error.status === 'FETCH_ERROR') {
        setServerStatus(false);
        console.error('Service temporarily unavailable. Please try again later.');
      }
      console.error('Error fetching/authenticating user');
    }

    setIsAuthenticating(false);
  };

  React.useEffect(() => {
    if (!user) {
      fetchUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isAuthenticating) {
    return null;
  }

  return <>{children}</>;
}
