import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NoWorkLogsContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[2],
  display: 'flex',
  minHeight: '48px',
  maxWidth: '650px',
  borderRadius: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
}));
