import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, IconButton, TextField, Typography, useTheme } from '@mui/material';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import { FilterButtons } from 'components/GlobalStyles/styles';
import { MessageType } from 'components/SnackBar';
import { UserType } from 'types/WorkLogTypes';
import { generateDateRange } from 'utils/date';

import {
  useGetUserListQuery,
  useLazyAdjustTimeSheetsQuery,
  useLazySyncTimeSheetsQuery,
  useSyncWorklogsToMYOBMutation,
} from '../../../../services/busmanApi';

import { Header, ModalPageContainer } from './styles';

interface SyncConfirmProps {
  onClose: () => void;
  open?: boolean;
  setMessage: (message: MessageType | null) => void;
  setLogMessage: (type: string, message: string) => void;
  setWorklogsSyncing: (value: boolean) => void;
}

const defaultOption = { user_id: '', user_role: '', username: '', full_name: 'All' };

const SyncWorklogsModal = ({
  onClose,
  open = false,
  setMessage,
  setLogMessage,
  setWorklogsSyncing,
}: SyncConfirmProps) => {
  const theme = useTheme();
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(1, 'week'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const [selectedEmployee, setSelectedEmployee] = React.useState<UserType | null>(defaultOption);
  const [syncWorklogsToMYOB] = useSyncWorklogsToMYOBMutation();
  const [syncTimeSheets] = useLazySyncTimeSheetsQuery();
  const [adjustTimeSheets] = useLazyAdjustTimeSheetsQuery();
  dayjs.extend(isSameOrAfter);

  const { data: userList = [] } = useGetUserListQuery({});

  const userOptions = [defaultOption, ...userList];

  const syncAllEmployeeTimesheets = async (date: string) => {
    const params = {
      startDate: date,
      endDate: date,
    };

    const result = await syncTimeSheets(params);
    if ('error' in result) {
      setLogMessage('error', 'Error syncing Timesheets');
      setMessage({ type: 'error', msg: 'Error syncing Timesheets' });
    }
    if ('data' in result) {
      setMessage({ type: 'success', msg: 'Timesheets synced successfully' });
    }
  };

  const syncLogsAndTimesheets = async (date: string, handleSync: any, msg: string) => {
    const users = selectedEmployee?.full_name !== 'All' ? [selectedEmployee] : [...userList];

    for (const user of users) {
      const params = {
        user_id: user?.user_id,
        start_date: date,
        end_date: date,
      };

      const result = await handleSync(params);
      if ('error' in result) {
        setLogMessage('error', `Error ${msg} of ${user?.full_name} ${date}`);
        setMessage({ type: 'error', msg: `Error ${msg} of ${user?.full_name}` });
      }
      if ('data' in result) {
        setMessage({ type: 'success', msg: `${msg} for ${user?.full_name} successfull` });
      }
    }
  };

  const handleSyncing = async () => {
    if (!selectedEmployee) {
      setErrorMsg('Please select an employee');
      return;
    }

    if (!toDate?.startOf('day').isSameOrAfter(fromDate?.startOf('day'))) {
      setErrorMsg('FROM Date cannot be after TO Date');
      return;
    }

    onClose();
    setWorklogsSyncing(true);
    setLogMessage('success', 'Syncing Worklogs...');
    const dateRange = generateDateRange(fromDate, toDate);

    try {
      for (const date of dateRange) {
        await syncLogsAndTimesheets(date, syncWorklogsToMYOB, 'syncing worklogs');
        await syncAllEmployeeTimesheets(date);
        await syncLogsAndTimesheets(date, adjustTimeSheets, 'adjusting timesheets');
      }
      setMessage({ type: 'success', msg: 'Worklog Sync Completed' });
      setLogMessage('success', 'Syncing Worklogs Complete');
    } catch (error) {
      setLogMessage('error', 'Error Syncing Worklogs');
      setMessage({ type: 'error', msg: 'Sync did not complete' });
    }
    setWorklogsSyncing(false);
  };

  const handleSelectEmployee = (value: UserType | null) => {
    setSelectedEmployee(value);
    if (errorMsg) setErrorMsg('');
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    setFromDate(value);
    const diff = value && Math.abs(value?.diff(dayjs(toDate), 'day'));
    if (diff && diff > 7) {
      let newToDate = dayjs(value).add(1, 'week');
      newToDate = newToDate.isSameOrBefore(dayjs()) ? newToDate : dayjs(); // to date should exceed current date
      setToDate(newToDate);
    }
  };

  const handleToDateChange = (value: Dayjs | null) => {
    setToDate(value);
    const diff = value && Math.abs(value?.diff(dayjs(fromDate), 'day'));
    if (diff && diff > 7) setFromDate(dayjs(value).subtract(1, 'week'));
  };

  return (
    <ModalPageContainer open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.background.paper,
          // height: '300px',
          maxWidth: '608px',
          width: '100%',
        }}>
        <Header>
          <Typography variant="h4">Sync Worklogs from MYOB to Busmin</Typography>
          <IconButton onClick={onClose} edge="end">
            <ClearIcon />
          </IconButton>
        </Header>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(2) }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 2,
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2">FROM</Typography>
              <DatePicker allowMaxDate onChange={handleFromDateChange} value={fromDate} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2">TO</Typography>
              <DatePicker onChange={handleToDateChange} value={toDate} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: theme.spacing(2),
              width: '280px',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              Employee
            </Typography>
            <Autocomplete
              size="small"
              value={selectedEmployee}
              options={userOptions}
              getOptionLabel={(option) => `${option.full_name}`}
              isOptionEqualToValue={(option, value) => option.full_name === value.full_name}
              onChange={(event, value) => handleSelectEmployee(value)}
              ListboxProps={{
                style: {
                  fontSize: '12px',
                },
              }}
              popupIcon={<ExpandMoreIcon fontSize="small" />}
              renderInput={(params) => <TextField {...params} />}
            />
            {errorMsg && (
              <Typography
                variant="subtitle2_italic"
                sx={{ color: theme.palette.busmanColors.busmanRed, margin: theme.spacing(1) }}>
                {errorMsg}
              </Typography>
            )}
          </Box>
          <FilterButtons sx={{ marginLeft: 'auto' }}>
            <CustomButton variant="contained" width="medium" text="Sync Worklogs" onClick={handleSyncing} />
          </FilterButtons>
        </Box>
      </Box>
    </ModalPageContainer>
  );
};

export default SyncWorklogsModal;
