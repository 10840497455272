import { Box, styled } from '@mui/material';

export const PendingEmployeesBox = styled(Box)(({ theme }) => ({
  width: '496px',
  maxHeight: '95vh',
  display: 'flex',
  overflowY: 'scroll',
  borderRadius: '2px',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
}));
