import { Box, Button, styled } from '@mui/material';

export const ProductivityButton = styled(Button)(() => ({
  width: '16px',
  border: 'none',
  height: '16px',
  minWidth: '16px',
  padding: 0,
  mr: 1,
}));

export const IndexDetail = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px',
  gap: '16px',
}));

export const DataTitle = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
}));
