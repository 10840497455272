// StyledComponents.js
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, styled } from '@mui/material';

export const CardContainer = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: '2px',
  boxShadow: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  height: 'auto',
  width: '99%',
  overflow: 'visible',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  position: 'relative',
}));

export const CloseButton = styled(CloseIcon)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '50%',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontSize: '20px',
  padding: '4px',
  position: 'absolute',
  top: '0px',
}));

export const DateTimeBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  width: '100%',
}));

export const ActivityContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '32px',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  width: '100%',
}));
