import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserTagContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
}));

export const EditBtnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50%',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
}));
