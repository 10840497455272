import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDlY3gSlxdLzMFpbnOfQKVfezbdWLg8JgQ',
  authDomain: 'busman-pro.firebaseapp.com',
  projectId: 'busman-pro',
  storageBucket: 'busman-pro.appspot.com',
  messagingSenderId: '232941385832',
  appId: '1:232941385832:web:e2011b9ad0466d4e0ce3dd',
  measurementId: 'G-MCYW2VYRVJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const supported = await isSupported();
const messaging = supported ? getMessaging(app) : null;

export const requestForToken = async () => {
  return getToken(messaging, {
    vapidKey: 'BKN8Ng0rsxTL3HrSCxQkSIs9m_NeiJ01icEBIHt0agUfK9o7SqsjzVTi7to_C6I5uye15EgZ9TkdZquERNYQs9Y',
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default messaging;
