import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';

import { MessageType } from 'components/SnackBar';
import { SuccessBanner } from 'pages/ViewQuote/styles';

import { WarningBanner } from '../styles';

interface MessageBannerProps {
  message: MessageType | null;
  isTabletScreen: boolean;
}

const MessageBanner = ({ message, isTabletScreen }: MessageBannerProps) => {
  return (
    <>
      {message && message.msg !== '' && message.type === 'success' && (
        <SuccessBanner>
          {!isTabletScreen && <CheckCircleOutlineIcon />}
          <Typography
            variant="body1"
            sx={{ color: 'inherit', maxHeight: '48px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {message.msg}
          </Typography>
        </SuccessBanner>
      )}
      {message && message.msg !== '' && message.type === 'error' && (
        <WarningBanner>
          {!isTabletScreen && <WarningAmberIcon />}
          <Typography
            variant="body1"
            sx={{ color: 'inherit', maxHeight: '48px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {message.msg}
          </Typography>
        </WarningBanner>
      )}
    </>
  );
};

export default MessageBanner;
