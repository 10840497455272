import { Box, List } from '@mui/material';
import { styled } from '@mui/material';

export const ActivitiesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  outline: 'none',
  width: '50%',
  maxWidth: 500,
  height: 'fit-content',
  overflow: 'hidden',
  borderRadius: '2px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '80%',
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderBottom: '1px solid #e0e0e0',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const modalStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  overflowY: 'auto',
};

export const ActivitiesList = styled(List)(({ theme }) => ({
  overflowY: 'auto',
  border: `1px solid ${theme.palette.background.default}`,
  padding: 0,
  mb: '16px',
  height: '260px',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
  },
}));
