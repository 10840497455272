/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/indent */
import type { PointerEvent } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Dayjs } from 'dayjs';
import { RootState } from 'store';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, InputAdornment, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { LineHeader, LineItem } from 'types/QuoteTypes';
import { getHistoricalRate } from 'utils/activities';

import { CloseButton, DuplicateIcon } from '../styles';

import NewLineItem from './LineItem';
import TextFieldBox from './TextFieldBox';

const tableCell = {
  borderBottom: 'none',
  padding: '8px',
  overFlow: 'hidden',
};

function isInteractiveElement(element: Element | null) {
  const interactiveElements = ['button'];
  if (element?.tagName && interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}
class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as any,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        if (event.button !== 0 || isInteractiveElement(event.target as Element)) {
          return false;
        }

        return true;
      },
    },
  ];
}

interface NewLineHeaderTypes {
  setFormValues: (key: string, value: string | LineHeader | LineItem | Dayjs | null, index: number) => void;
  handleDeleteLine: (key: string, lineHeaderIdx: number, lineItemIdx: number) => void;
  handleDuplicateLineHeader: (idx: number) => void;
  handleDeleteLineHeader: (idx: number) => void;
  handleSetLineItemValues: (key: string, value: any, lineHeaderIdx: number, lineItemIdx: number) => void;
  lineHeaderIdx: number;
  lineHeader: LineHeader;
  quotedAtDate: string;
  handleSetLineHeaderOrder: (lineItems: LineItem[], lineHeaderIdx: number) => void;
}

const NewLineHeader = ({
  setFormValues,
  lineHeaderIdx,
  lineHeader,
  handleDeleteLine,
  handleDuplicateLineHeader,
  handleSetLineItemValues,
  handleDeleteLineHeader,
  quotedAtDate,
  handleSetLineHeaderOrder,
}: NewLineHeaderTypes) => {
  const theme = useTheme();
  const [linesExpanded, setlinesExpanded] = useState(true);
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const [activeId, setActiveId] = useState(null);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: lineHeader.id,
    transition: {
      duration: 200,
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  });

  const sensors = useSensors(useSensor(SmartPointerSensor));
  const descriptionRef = React.useRef(null);

  const getLineItemTotal = (lineItem: LineItem) => {
    if (lineItem.lineType === 'Inventory') return lineItem.units * Number(lineItem.customCost) - lineItem.discount;

    //Get historical rate for activity based on quote date
    const lineRate =
      lineItem.activityDetail && getHistoricalRate(activities, lineItem.activityDetail.activityId, quotedAtDate);
    return Number(lineItem.units) * Number(lineRate) - lineItem.discount;
  };

  const calculateTotalDiscount = (lineHeader: LineHeader) => {
    let totalDiscount = 0;

    lineHeader.lineItems.forEach((line) => {
      totalDiscount += Number(line.discount);
    });

    return totalDiscount.toFixed(2);
  };

  const getLineHeaderTotal = () => {
    let total = 0;
    lineHeader.lineItems.forEach((line) => {
      total += getLineItemTotal(line);
    });

    return total.toFixed(2);
  };

  const createNewLineItem = () => {
    setlinesExpanded(true);
    setFormValues(
      'lineHeaders',
      {
        id: lineHeader.lineItems.length + 1,
        units: 0,
        description: '',
        customCost: null,
        lineType: 'Activity',
        activityDetail: null,
        discount: 0,
        total: 0,
      },
      lineHeaderIdx,
    );
  };

  const handleSetHeaderDescription = (key: string, value: string) => {
    setFormValues('lineHeaders', value, lineHeaderIdx);
  };

  const handleDuplicateLine = (idx: number) => {
    const lineToDuplicate = lineHeader.lineItems[idx];
    setFormValues('lineHeaders', lineToDuplicate, lineHeaderIdx);
  };

  const ButtonGroup = React.memo(() => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(1) }}>
      <CustomButton
        onClick={() => createNewLineItem()}
        variant="contained"
        width="medium"
        text="Add Line Item"
        toolTip="Add Line"
      />
    </Box>
  ));
  ButtonGroup.displayName = 'ButtonGroup';

  const handleDragStart = (event: any) => {
    const { active } = event;

    setActiveId(active.id);
  };

  const activeLineItem = lineHeader.lineItems.find((item) => item.id === activeId);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = lineHeader.lineItems.findIndex((item) => item.id === active.id);
      const newIndex = lineHeader.lineItems.findIndex((item) => item.id === over.id);

      const newLineItems = arrayMove(lineHeader.lineItems, oldIndex, newIndex);

      handleSetLineHeaderOrder(newLineItems, lineHeaderIdx);
    }
  };

  const draggableStyle = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    width: '100%',
  };

  return (
    <TableBody
      style={draggableStyle}
      ref={setNodeRef}
      key={lineHeaderIdx}
      sx={{
        height: 'auto',
        border: `1px solid ${theme.palette.background.default}`,
        mb: '20px',
        position: 'relative',
      }}>
      <TableRow>
        <TableCell sx={tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ '&: focus': { backgroundColor: theme.palette.background.default } }}
            onClick={() => setlinesExpanded(!linesExpanded)}>
            {linesExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={2} sx={tableCell}>
          <Box>
            <TextField
              required
              inputProps={{ min: 0 }}
              multiline
              inputRef={descriptionRef}
              sx={{
                width: '100%',
                height: '100%',
                direction: 'ltr',
                'input::-webkit-inner-spin-button': {
                  ' -webkit-appearance': 'auto',
                },
              }}
              size="small"
              variant="outlined"
              placeholder="DESCRIPTION"
              defaultValue={lineHeader.description}
              onBlur={(e) => handleSetHeaderDescription('description', e.target.value)}
            />
          </Box>
        </TableCell>
        <TableCell sx={tableCell}>
          <Box
            sx={{
              textAlign: 'left',
              width: '100%',
              backgroundColor: '#F2F2F2',
              height: '32px',
              padding: '8px 16px 8px 16px',
            }}>
            <Typography variant="body1">${calculateTotalDiscount(lineHeader)}</Typography>
          </Box>
        </TableCell>
        <TableCell sx={tableCell}>
          <Box
            sx={{
              textAlign: 'left',
              width: '100%',
              backgroundColor: '#F2F2F2',
              height: '32px',
              padding: '8px 16px 8px 16px',
            }}>
            <Typography variant="body1">${getLineHeaderTotal()}</Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ ...tableCell, position: 'relative' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CustomButton
              variant="contained_transparent"
              width="small"
              toolTip="Duplicate Line Header"
              text={<DuplicateIcon sx={{ fontSize: '22px' }} />}
              onClick={(e) => handleDuplicateLineHeader(lineHeaderIdx)}
            />
            <CustomButton
              variant="contained_transparent"
              width="small"
              toolTip="Remove Line Header"
              text={<CloseButton sx={{ fontSize: '30px' }} />}
              onClick={(e) => handleDeleteLineHeader(lineHeaderIdx)}
            />
            <Box {...attributes} {...listeners}>
              <CustomButton
                variant="contained_transparent"
                width="small"
                text={
                  <DragHandleIcon
                    sx={{
                      fontSize: '20px',
                      color: theme.palette.primary.light,
                      cursor: 'grab',
                      padding: '0 !important',
                    }}
                  />
                }
              />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <DndContext
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}>
        <SortableContext items={lineHeader.lineItems} strategy={rectSortingStrategy}>
          {linesExpanded &&
            lineHeader.lineItems.map((row, idx) => {
              return (
                <NewLineItem
                  key={`lineItem ${row.id}`}
                  row={row}
                  idx={idx}
                  lineHeaderIdx={lineHeaderIdx}
                  handleSetLineItemValues={handleSetLineItemValues}
                  handleDuplicateLine={handleDuplicateLine}
                  handleDeleteLine={handleDeleteLine}
                  quotedAtDate={quotedAtDate}
                />
              );
            })}
        </SortableContext>
        <DragOverlay
          dropAnimation={{
            duration: 200,
            easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
          }}
          style={{
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}>
          {activeId && activeLineItem ? (
            <NewLineItem
              key={activeId}
              row={activeLineItem}
              idx={1}
              lineHeaderIdx={lineHeaderIdx}
              handleSetLineItemValues={handleSetLineItemValues}
              handleDuplicateLine={handleDuplicateLine}
              handleDeleteLine={handleDeleteLine}
              quotedAtDate={quotedAtDate}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <TableRow>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell colSpan={2} sx={[tableCell, { gap: '16px', textAlign: 'right' }]}>
          <ButtonGroup />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default NewLineHeader;
