import { Grid, styled } from '@mui/material';

export const ChangePasswordContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '78px',
  marginTop: theme.spacing(1),
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: '0',
    padding: '8px',
    justifyContent: 'center',
    height: '90dvh',
  },
}));

export const ChangePasswordForm = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  height: 'fit-content',
  width: '500px',
  padding: theme.spacing(2),
  borderRadius: '4px',
  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));
