import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import dayjs, { Dayjs } from 'dayjs';
import { eeaLogo } from 'images';

import { ArrowBackIos } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DataNotFound from 'components/DataNotFound';
import BasicDatePicker from 'components/DatePicker/DatePicker';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog';
import { FilterButtons, FilterPanelContainer, PageContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import { worklogActions } from 'features/worklog/worklogSlice';
import {
  useDeleteQuoteMutation,
  useGetClientByIdQuery,
  useGetQuotesByIdQuery,
  useUpdateQuotesMutation,
} from 'services/busmanApi';
import { FetchError, LineHeader, LineItem, QuoteDetailProps } from 'types/QuoteTypes';
import { getHistoricalRate } from 'utils/activities';
import { COMPANY_DETAILS, TERMS_AND_CONDITIONS } from 'utils/constants';
import { convertLineHeadersToCamelCase, convertLineHeadersToSnakeCase, instanceOfLine } from 'utils/quotes';

import { RootState } from '../../store';

import NewLineHeader from './Components/LineHeader';
import MessageBanner from './Components/MessageBanner';
import SaveAndExitModal from './Components/SaveAndExit';
import TextFieldBox from './Components/TextFieldBox';
import {
  ActivityFormContainer,
  BusinessDetails,
  ContactDetails,
  Footer,
  FormContent,
  FormContentReverse,
  HeaderContent,
  LogoContainer,
  NewLineBtnGroup,
  QuoteDocContainer,
  QuoteDocSummaryContainer,
  QuoteHeader,
  QuoteTotalsContainer,
  SummaryContainer,
  ToggleButton,
  ToggleButtonPanel,
  TypographyContainer,
  ViewQuoteContainer,
  WarningBanner,
} from './styles';

const DEFAULT_VALUES = {
  id: '',
  quoteNumber: '',
  quotedDate: dayjs().format('DD/MM/YYYY'),
  taxCode: '',
  client: null,
  potentialClient: null,
  quotationPreparedBy: {
    userId: '',
    fullName: '',
    username: '',
    userRole: '',
  },
  quotesMetaData: null,
  status: 'Pending',
  createdAt: '',
  expirationDate: dayjs().add(1, 'month').format('DD/MM/YYYY'),
  updatedAt: '',
  lineHeaders: [{ id: 1, jobQuoteId: '', description: '', lineItems: [] }],
};

const EditQuote = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { quoteId } = useParams();

  const { state: snackBarMessage } = useLocation();
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<QuoteDetailProps>(DEFAULT_VALUES);
  const [uneditedQuoteData, setUneditedQuoteData] = useState<QuoteDetailProps>(DEFAULT_VALUES);
  const [toggleCustomClient, setToggleCustomClient] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [message, setMessage] = React.useState<MessageType | null>(snackBarMessage);
  const [activeDragId, setActiveDragId] = useState(null);

  const { clients } = useSelector((state: RootState) => state.worklogs);
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const { showSaveandExit, navigateURL, unsavedQuote } = useSelector((state: RootState) => state.worklogs);

  const { data: quoteData, isFetching } = useGetQuotesByIdQuery(quoteId);
  const { data: clientData, isFetching: clientFetching } = useGetClientByIdQuery(selectedClientId, {
    skip: !selectedClientId,
  });
  const [updateQuote, { isLoading }] = useUpdateQuotesMutation();
  const [deleteQuote] = useDeleteQuoteMutation();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md')) && !isMobileScreen;
  const types = ['Pending', 'Submitted', 'Accepted'];

  const sensors = useSensors(useSensor(PointerSensor));

  const getClientDetail = () => {
    if (clientData) return clientData;

    return quoteData?.client_data || null;
  };

  const clientObj = getClientDetail();
  const isGST = clientObj?.tax_code?.code === 'GST' || formValues?.quotesMetaData?.taxCode === 'GST';

  React.useEffect(() => {
    if (!quoteData) return;

    // Redirect to quotes page if quote is already accepted
    if (quoteData.status === 'Accepted') navigate('/Quotes');

    const initialQuoteData = {
      ...formValues,
      id: quoteData.id,
      status: quoteData.status,
      quoteNumber: quoteData.quote_number,
      quotedDate: quoteData.quoted_date,
      taxCode: clientObj?.tax_code.tax_id || null,
      client: quoteData?.client_data?.client_id || null,
      potentialClient: quoteData.potential_client && {
        clientName: quoteData.potential_client?.client_name || undefined,
        clientAddress: quoteData.potential_client?.client_address || undefined,
      },
      quotationPreparedBy: {
        userId: quoteData.quotation_prepared_by.user_id,
        fullName: quoteData.quotation_prepared_by.full_name,
        username: quoteData.quotation_prepared_by.username,
        userRole: quoteData.quotation_prepared_by.user_role,
      },
      createdAt: quoteData.created_at,
      quotesMetaData: {
        attn: quoteData.quotes_meta_data?.attn || '',
        scope: quoteData.quotes_meta_data?.scope || '',
        required: quoteData.quotes_meta_data?.required || '',
        deliverables: quoteData.quotes_meta_data?.deliverables || '',
        resources: quoteData.quotes_meta_data?.resources || '',
        schedule: quoteData.quotes_meta_data?.schedule || '',
        personnel: quoteData.quotes_meta_data?.personnel || '',
        taxCode: quoteData.quotes_meta_data?.tax_code || null,
        termsAndConditions: quoteData.quotes_meta_data?.terms_and_conditions || TERMS_AND_CONDITIONS,
      },
      expirationDate: quoteData.expiration_date || dayjs().add(1, 'month').format('YYYY-MM-DD'),
      updatedAt: quoteData.updated_at,
      lineHeaders:
        quoteData.line_headers.length > 0
          ? [...convertLineHeadersToSnakeCase(quoteData.line_headers)]
          : [...formValues.lineHeaders],
    };

    setFormValues(initialQuoteData);
    setUneditedQuoteData(initialQuoteData);

    if (quoteData?.potential_client) {
      setToggleCustomClient(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData]);

  React.useEffect(() => {
    if (uneditedQuoteData !== formValues && !unsavedQuote) {
      dispatch(worklogActions.setUnSavedQuotes({ unsavedQuote: true }));
    }
  }, [formValues]);

  React.useEffect(() => {
    if (!clientData) return;
    let newAttn = formValues.quotesMetaData?.attn;

    if (clientData && clientData.address) {
      newAttn = clientData.address.contact_name;
    }

    setFormValues((prevState) => {
      return {
        ...prevState,
        quotesMetaData: { ...prevState.quotesMetaData, attn: newAttn, taxCode: clientData?.tax_code.tax_id || '' },
      };
    });
  }, [clientData]);

  const calculateLineTotal = (lineItem: LineItem) => {
    if (lineItem.lineType === 'Inventory') return lineItem.units * Number(lineItem.customCost) - lineItem.discount;

    if (lineItem.lineType === 'Activity') {
      //Get historical rate for activity based on quote date
      const lineRate = getHistoricalRate(activities, lineItem.activityDetail?.activityId || '', formValues.quotedDate);
      return lineItem.units * Number(lineRate) - lineItem.discount;
    }
    return 0;
  };

  const calculateTotal = (lineHeaders: LineHeader[]) => {
    let total = 0;

    lineHeaders.forEach((lineHeader) => {
      lineHeader.lineItems.forEach((line) => {
        total += calculateLineTotal(line);
      });
    });

    return total;
  };

  const total = React.useMemo(
    () => calculateTotal(formValues.lineHeaders),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues.lineHeaders, activities, quoteData],
  );
  const isFetchingData = isFetching || isLoading;

  const handleSetFormValues = (
    key: string,
    value: string | LineHeader | LineItem | Dayjs | null,
    lineHeaderidx?: number,
  ) => {
    //Check for line_header description
    if (key === 'lineHeaders' && !instanceOfLine(value) && typeof value === 'string') {
      const updatedLineHeaders = formValues.lineHeaders.map((header, idx) => {
        if (lineHeaderidx === idx) {
          const newLineHeader = { ...header, description: value };
          return newLineHeader;
        }
        return header;
      });
      return setFormValues({ ...formValues, [key]: updatedLineHeaders });
    }

    //Create new empty line/duplicate line
    if (instanceOfLine(value) && lineHeaderidx !== undefined) {
      const preLineHeaders = formValues.lineHeaders.map((header, idx) => {
        if (idx === lineHeaderidx) {
          return { ...header, lineItems: [...header.lineItems, value] };
        }
        return header;
      });
      return setFormValues({ ...formValues, [key]: preLineHeaders });
    }

    //Everything else
    setFormValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const handleSetLineHeaderOrder = (newLineItems: LineItem[], lineHeaderIdx: number) => {
    const updatedLineHeaders = formValues.lineHeaders.map((header, idx) => {
      if (idx === lineHeaderIdx) {
        return { ...header, lineItems: newLineItems };
      }
      return header;
    });

    setFormValues({ ...formValues, lineHeaders: updatedLineHeaders });
  };

  const handleSetLineItemValues = (key: string, value: any, lineHeaderIdx: number, lineItemIdx: number) => {
    const updatedLineHeaders = formValues.lineHeaders.map((header, idx) => {
      if (idx === lineHeaderIdx) {
        const updatedLineItems = header.lineItems.map((line, idx) => {
          if (idx === lineItemIdx) {
            return { ...line, [key]: value };
          }
          return line;
        });
        return { ...header, lineItems: updatedLineItems };
      }
      return header;
    });

    return setFormValues({ ...formValues, lineHeaders: updatedLineHeaders });
  };

  const handleSetCustomClient = (key: string, value: string) => {
    setFormValues((prevState) => {
      return { ...prevState, potentialClient: { ...prevState.potentialClient, [key]: value } };
    });
  };

  const handleSetMetaData = (key: string, value: string) => {
    setFormValues((prevState) => {
      return { ...prevState, quotesMetaData: { ...prevState.quotesMetaData, [key]: value } };
    });
  };

  const handleDeleteLine = (key: string, lineheaderIdx: number, lineItemIdx: number) => {
    const updatedLineHeaders = formValues.lineHeaders.map((header, idx) => {
      if (idx === lineheaderIdx) {
        header.lineItems.splice(lineItemIdx, 1);
        return header;
      }
      return header;
    });
    return setFormValues({ ...formValues, [key]: updatedLineHeaders });
  };

  const createNewLineHeader = () => {
    setFormValues((prevState) => ({
      ...prevState,
      lineHeaders: [
        ...prevState.lineHeaders,
        { id: formValues.lineHeaders.length + 1, jobQuoteId: formValues.id, description: '', lineItems: [] },
      ],
    }));
  };

  const handleDeleteLineHeader = (lineHeaderIdx: number) => {
    const updatedLineHeaders = formValues.lineHeaders.filter((lineHeader, idx) => idx !== lineHeaderIdx);

    setFormValues((prevState) => {
      return { ...prevState, lineHeaders: updatedLineHeaders };
    });
  };

  const handleDeleteQuote = async () => {
    setDeleteModalOpen(false);
    const result = await deleteQuote(formValues.id);

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error deleting quote' });
    }

    if ('data' in result) {
      navigate('/quotes', {
        state: { type: 'success', msg: 'Quote deleted succesfully' },
      });
    }
  };

  const handleDuplicateLineHeader = (lineHeaderIdx: number) => {
    const lineHeaderCopy = formValues.lineHeaders.find((lineHeader, idx) => idx === lineHeaderIdx);
    const deepCopy = JSON.parse(JSON.stringify(lineHeaderCopy));
    if (lineHeaderCopy) {
      const duplicatedLineHeader = {
        ...deepCopy,
        id: formValues.lineHeaders.length + 1,
        lineItems: deepCopy?.lineItems.map((line: LineItem) => ({ ...line })),
      };

      setFormValues((prevState) => ({
        ...prevState,
        lineHeaders: [...prevState.lineHeaders, duplicatedLineHeader],
      }));
    }
  };

  const handleQuoteDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    setFormValues((prevState) => {
      return { ...prevState, quotedDate: newValue.format('YYYY-MM-DD') };
    });
  };

  const handleExpirationDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    setFormValues((prevState) => {
      return { ...prevState, expirationDate: newValue.format('YYYY-MM-DD') };
    });
  };

  const handleClientChange = (value: string | null) => {
    setFormValues({ ...formValues, client: value });
    setSelectedClientId(value);
  };

  const handleGstToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (toggleCustomClient) {
      return setFormValues((prevState) => {
        return { ...prevState, quotesMetaData: { ...prevState.quotesMetaData, taxCode: newAlignment } };
      });
    }
    setFormValues((prevState) => {
      return { ...prevState, taxCode: newAlignment };
    });
  };

  const handleToggleCustomClient = () => {
    if (!toggleCustomClient) {
      setFormValues((prevState) => {
        return {
          ...prevState,
          quotesMetaData: { ...formValues.quotesMetaData, taxCode: formValues.quotesMetaData?.taxCode || 'GST' },
        };
      });
    }
    setToggleCustomClient(!toggleCustomClient);
  };

  const handleSubmitQuote = async () => {
    if (!formValues.client && !formValues.potentialClient) {
      setMessage({ type: 'error', msg: 'Please select a client' });
      return;
    }

    if (!formValues.lineHeaders.length) {
      setMessage({ type: 'error', msg: 'Please add at least one line header' });
      return;
    }

    const isLineItemEmpty = (lineItem: LineItem) => {
      return (
        !lineItem.units ||
        (lineItem.lineType === 'Activity' && !lineItem.activityDetail) ||
        (lineItem.lineType === 'Inventory' && !lineItem.customCost)
      );
    };

    const isLineHeaderEmpty = (lineHeader: LineHeader) => {
      return !lineHeader.description || lineHeader.lineItems.some(isLineItemEmpty);
    };

    const isFormValuesEmpty = () => {
      return (!toggleCustomClient && !formValues.client) || formValues.lineHeaders.some(isLineHeaderEmpty);
    };

    if (isFormValuesEmpty()) {
      setMessage({ type: 'error', msg: 'Please fill in all line item fields' });
      return;
    }

    if (
      (toggleCustomClient && !formValues.potentialClient?.clientName) ||
      (toggleCustomClient && !formValues.potentialClient?.clientAddress)
    ) {
      setMessage({ type: 'error', msg: 'Please fill in all custom client fields' });
      return;
    }

    const payload = {
      id: formValues.id,
      quote_number: formValues.quoteNumber,
      quoted_date: formValues.quotedDate,
      tax_code: toggleCustomClient ? null : formValues.taxCode,
      client: toggleCustomClient ? null : formValues.client,
      potential_client: toggleCustomClient
        ? {
            client_name: formValues.potentialClient?.clientName,
            client_address: formValues.potentialClient?.clientAddress,
          }
        : null,
      quotation_prepared_by: {
        user_id: formValues.quotationPreparedBy.userId,
        full_name: formValues.quotationPreparedBy.fullName,
        username: formValues.quotationPreparedBy.username,
        user_role: formValues.quotationPreparedBy.userRole,
      },
      quotes_meta_data: formValues.quotesMetaData
        ? {
            attn: formValues.quotesMetaData.attn,
            scope: formValues.quotesMetaData.scope,
            required: formValues.quotesMetaData.required,
            deliverables: formValues.quotesMetaData.deliverables,
            personnel: formValues.quotesMetaData.personnel,
            resources: formValues.quotesMetaData.resources,
            schedule: formValues.quotesMetaData.schedule,
            tax_code: toggleCustomClient ? formValues.quotesMetaData.taxCode : null,
            terms_and_conditions:
              formValues.quotesMetaData.termsAndConditions !== ''
                ? formValues.quotesMetaData.termsAndConditions
                : TERMS_AND_CONDITIONS,
          }
        : null,
      status: formValues.status,
      expiration_date: formValues.expirationDate,
      line_headers: convertLineHeadersToCamelCase(formValues.lineHeaders),
    };

    const result = await updateQuote(payload);

    if ('error' in result) {
      const fetchError = result as FetchError;
      setMessage({ type: 'error', msg: fetchError.error.data.errors });
    }

    if ('data' in result) {
      navigate(`/viewquote/${quoteData?.id}`, {
        state: { type: 'success', msg: 'Quote Saved succesfully' },
      });
    }

    dispatch(worklogActions.resetUnsavedQuotes());
  };

  const handleSetStatus = (status: string) => {
    setFormValues((prevState) => {
      return { ...prevState, status: status };
    });
  };

  const handleCloseSnackbar = () => {
    setMessage({ type: '', msg: '' });
  };

  const ButtonGroup = () => {
    return (
      <>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(1), paddingRight: theme.spacing(1) }}>
          <CustomButton
            variant="contained_grey"
            text="New Line Header"
            onClick={() => createNewLineHeader()}
            width="medium"
            toolTip="Add New Line Header"
          />
        </Box>
      </>
    );
  };

  const RenderHeader = () => {
    return (
      <>
        <QuoteHeader>
          <HeaderContent>
            <LogoContainer>
              <img style={{ objectFit: 'contain', width: '100%' }} src={eeaLogo} alt="Element Logo" />
            </LogoContainer>
            <BusinessDetails>
              <Typography variant="h4">Quote</Typography>
              <Typography variant="body1">{COMPANY_DETAILS.company_name}</Typography>
            </BusinessDetails>
          </HeaderContent>
          <ContactDetails>
            <BusinessDetails>
              <Typography variant="body1">{COMPANY_DETAILS.company_abn}</Typography>
              <Typography variant="body1">{COMPANY_DETAILS.company_phone}</Typography>
              <Typography variant="body1">{COMPANY_DETAILS.company_email}</Typography>
            </BusinessDetails>
            <BusinessDetails>
              <Typography variant="body2">ABN</Typography>
              <Typography variant="body2">PHONE</Typography>
              <Typography variant="body2">EMAIL</Typography>
            </BusinessDetails>
          </ContactDetails>
        </QuoteHeader>
      </>
    );
  };

  const RenderClientForm = () => {
    const GstSwitch = () => {
      return (
        <Box>
          <Typography textAlign={'left'} variant="body2">
            TAX CODE
          </Typography>
          <ToggleButtonPanel
            value={toggleCustomClient ? formValues.quotesMetaData?.taxCode : clientObj?.tax_code.code}
            exclusive
            onChange={handleGstToggle}>
            <ToggleButton
              onMouseUp={(e) => e.currentTarget.blur()}
              sx={{
                width: !toggleCustomClient ? '100%' : '50%',
                cursor: !toggleCustomClient ? 'default' : 'pointer',
              }}
              value="GST">
              <Typography
                sx={{
                  fontSize: '11px',
                }}
                variant="body2">
                {!toggleCustomClient ? clientObj?.tax_code.code : 'GST'}
              </Typography>
            </ToggleButton>
            {toggleCustomClient && (
              <ToggleButton value="FRE" onMouseUp={(e) => e.currentTarget.blur()}>
                <Typography
                  sx={{
                    fontSize: '11px',
                  }}
                  variant="body2">
                  FRE
                </Typography>
              </ToggleButton>
            )}
          </ToggleButtonPanel>
        </Box>
      );
    };
    return (
      <>
        <FormControl>
          <FormContent>
            <TextFieldBox
              label="Quote Number"
              placeholderText={false}
              purpose="disabled"
              onChangeFn={handleSetFormValues}
              formKey="quoteNumber"
              value={formValues.quoteNumber}
            />
            <Box>
              <Typography textAlign={'left'} variant="body2">
                QUOTE DATE
              </Typography>
              <BasicDatePicker allowMaxDate onChange={handleQuoteDateChange} value={dayjs(formValues.quotedDate)} />
            </Box>
            <Box>
              <Typography textAlign={'left'} variant="body2">
                EXPIRATION
              </Typography>
              <BasicDatePicker
                allowMaxDate
                onChange={handleExpirationDateChange}
                value={dayjs(formValues.expirationDate)}
              />
            </Box>
            {GstSwitch()}
          </FormContent>
          <FormContentReverse>
            <CustomButton
              variant="contained_grey"
              text={toggleCustomClient ? 'Select Existing Client' : 'Select Custom Client'}
              onClick={() => handleToggleCustomClient()}
              width="100%"
              customSx={{ marginTop: theme.spacing(2), alignSelf: 'center' }}
            />
            {!toggleCustomClient && (
              <Box>
                <Typography textAlign={'left'} variant="body2">
                  CLIENT
                </Typography>
                <Autocomplete
                  size="small"
                  disableClearable
                  value={clientObj || undefined}
                  disabled={clientFetching}
                  options={clients.map(({ client_name, client_id }) => {
                    return { client_name, client_id };
                  })}
                  getOptionLabel={(option) => option.client_name || ''}
                  isOptionEqualToValue={(option, value) => option.client_id === value?.client_id}
                  onChange={(event, value) => handleClientChange(value?.client_id || null)}
                  ListboxProps={{
                    style: {
                      fontSize: '10px',
                      textAlign: 'left',
                      padding: '0',
                    },
                  }}
                  renderInput={(params) => <TextField {...params} required sx={{ direction: 'ltr' }} />}
                />
              </Box>
            )}
            {toggleCustomClient && (
              <TextFieldBox
                required
                label="Client Name"
                placeholderText={false}
                purpose=""
                onChangeFn={handleSetCustomClient}
                formKey="clientName"
                value={formValues.potentialClient?.clientName || ''}
              />
            )}
            <TextFieldBox
              label="attn"
              placeholderText={false}
              purpose=""
              onChangeFn={handleSetMetaData}
              formKey="attn"
              value={formValues.quotesMetaData?.attn || ''}
            />
            {toggleCustomClient && (
              <TextFieldBox
                required
                label="Address"
                placeholderText={false}
                purpose=""
                onChangeFn={handleSetCustomClient}
                formKey="clientAddress"
                value={formValues.potentialClient?.clientAddress || ''}
              />
            )}
          </FormContentReverse>
        </FormControl>
      </>
    );
  };

  const RenderActivityForm = () => {
    const tableFields = ['UNITS', 'DESCRIPTION', 'RATE/COST', 'DISCOUNT', 'LINE TOTAL'];

    const handleDragEnd = (event: any) => {
      const { active, over } = event;
      if (!active || !over) return;
      if (active.id === over.id) return;

      const oldIndex = formValues.lineHeaders.findIndex((header) => header.id === active.id);
      const newIndex = formValues.lineHeaders.findIndex((header) => header.id === over.id);

      const updatedLineHeaders = arrayMove(formValues.lineHeaders, oldIndex, newIndex);

      setFormValues({ ...formValues, lineHeaders: updatedLineHeaders });
    };

    const handleDragStart = (event: any) => {
      const { active } = event;

      setActiveDragId(active.id);
    };

    const activeDragLineHeader = formValues.lineHeaders.find((header) => header.jobQuoteId === activeDragId);

    return (
      <>
        <ActivityFormContainer>
          <TableContainer>
            <Table sx={{ overflow: 'hidden' }}>
              <colgroup>
                <col width="10%" />
                <col width="30%" />
                <col width="25%" />
                <col width="12.5%" />
                <col width="12.5%" />
                <col width="5%" />
              </colgroup>
              <TableHead sx={{ lineHeight: '20px' }}>
                <TableRow>
                  {tableFields.map((field) => {
                    return (
                      <TableCell
                        sx={{ borderBottom: 'none', paddingBottom: theme.spacing(1), padding: '8px' }}
                        key={field}>
                        {field}
                      </TableCell>
                    );
                  })}
                  <TableCell sx={{ borderBottom: 'none', paddingBottom: theme.spacing(1), padding: '8px' }}></TableCell>
                </TableRow>
              </TableHead>
              <DndContext
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}>
                <SortableContext items={formValues.lineHeaders} strategy={rectSortingStrategy}>
                  {formValues.lineHeaders.map((lineHeader, idx) => {
                    return (
                      <NewLineHeader
                        key={`lineheader ${lineHeader.id}`}
                        setFormValues={handleSetFormValues}
                        lineHeaderIdx={idx}
                        lineHeader={lineHeader}
                        handleDeleteLine={handleDeleteLine}
                        handleSetLineItemValues={handleSetLineItemValues}
                        handleDuplicateLineHeader={handleDuplicateLineHeader}
                        handleDeleteLineHeader={handleDeleteLineHeader}
                        quotedAtDate={formValues.quotedDate}
                        handleSetLineHeaderOrder={handleSetLineHeaderOrder}
                      />
                    );
                  })}
                </SortableContext>
                <DragOverlay
                  dropAnimation={{
                    duration: 200,
                    easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                  }}
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}>
                  {activeDragLineHeader && (
                    <NewLineHeader
                      key={`lineHeader ${activeDragLineHeader.id}`}
                      setFormValues={() => {}}
                      lineHeaderIdx={activeDragLineHeader.id}
                      lineHeader={activeDragLineHeader}
                      handleDeleteLine={() => {}}
                      handleSetLineItemValues={() => {}}
                      handleDuplicateLineHeader={() => {}}
                      handleDeleteLineHeader={() => {}}
                      quotedAtDate={formValues.quotedDate}
                      handleSetLineHeaderOrder={() => {}}
                    />
                  )}
                </DragOverlay>
              </DndContext>
            </Table>
          </TableContainer>
          <NewLineBtnGroup>{ButtonGroup()}</NewLineBtnGroup>
        </ActivityFormContainer>
      </>
    );
  };

  const RenderFooter = (docType?: string) => {
    const getGstValue = () => {
      if (clientObj && !toggleCustomClient) {
        return Number(clientObj.tax_code.rate) / 100;
      }
      if (toggleCustomClient && formValues.quotesMetaData?.taxCode === 'GST') {
        return 0.1;
      }
      return 1;
    };
    return (
      <>
        {docType === 'detailed' && (
          <QuoteTotalsContainer>
            <TypographyContainer>
              <Typography variant="body2">SUBTOTAL</Typography>
              <Typography variant="body1">${total.toFixed(2)}</Typography>
            </TypographyContainer>
            {isGST && (
              <TypographyContainer>
                <Typography variant="body2">GST</Typography>
                <Typography variant="body1">
                  ${clientObj ? (total * getGstValue()).toFixed(2) : (total * 0.1).toFixed(2)}
                </Typography>
              </TypographyContainer>
            )}
            <TypographyContainer>
              <Typography variant="body2">TOTAL</Typography>
              <Typography variant="body1">
                ${isGST ? (total * (1 + getGstValue())).toFixed(2) : total.toFixed(2)}
              </Typography>
            </TypographyContainer>
          </QuoteTotalsContainer>
        )}
        <Footer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: theme.spacing(2) }}>
            <TextFieldBox
              label="quotation_prepared_by"
              placeholderText={false}
              purpose=""
              onChangeFn={handleSetFormValues}
              formKey="quotationPreparedBy"
              value={formValues.quotationPreparedBy.fullName}
            />
          </Box>
          <Box>
            <TextField
              required
              inputProps={{ min: 0 }}
              multiline
              sx={{
                width: '100%',
                height: '100%',
              }}
              size="small"
              variant="outlined"
              placeholder="Terms and Conditions"
              defaultValue={formValues.quotesMetaData?.termsAndConditions || ''}
              onBlur={(e) => handleSetMetaData('termsAndConditions', e.target.value)}
            />
          </Box>
        </Footer>
      </>
    );
  };

  const RenderSummary = () => {
    const textField = (label: string, formKey: string, value: string) => {
      return (
        <Box>
          <Typography textAlign={'left'} variant="body2">
            {label}
          </Typography>
          <TextField
            required
            inputProps={{ min: 0 }}
            multiline
            sx={{
              width: '100%',
              height: '100%',
            }}
            size="small"
            variant="outlined"
            defaultValue={value || ''}
            onBlur={(e) => handleSetMetaData(formKey, e.target.value)}
          />
        </Box>
      );
    };

    return (
      <>
        <SummaryContainer>
          {textField('SCOPE', 'scope', formValues.quotesMetaData?.scope || '')}
          {textField('REQUIRED', 'required', formValues.quotesMetaData?.required || '')}
          {textField('DELIVERABLES', 'deliverables', formValues.quotesMetaData?.deliverables || '')}
          {textField('PERSONNEL', 'personnel', formValues.quotesMetaData?.personnel || '')}
          {textField('RESOURCES', 'resources', formValues.quotesMetaData?.resources || '')}
          {textField('SCHEDULE', 'schedule', formValues.quotesMetaData?.schedule || '')}
        </SummaryContainer>
      </>
    );
  };

  const exitWithoutSaving = () => {
    if (navigateURL) {
      navigate(navigateURL);
    } else {
      navigate(-1);
    }
    dispatch(worklogActions.resetUnsavedQuotes());
  };

  const handleModalSave = () => {
    dispatch(worklogActions.setUnSavedQuotes({ showSaveandExit: false }));
    handleSubmitQuote();
  };

  return (
    <>
      <SnackBar message={message} onClose={handleCloseSnackbar} />
      {!quoteData && !isFetching && <DataNotFound errorMessage="Quote Not Found" url="quotes" />}
      <Loader open={isFetchingData} />
      {quoteData && !isFetching && (
        <PageContainer>
          <FilterPanelContainer>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 4, gap: theme.spacing(1) }}>
              <ArrowBackIos
                sx={{
                  color: theme.palette.text.disabled,
                  fontSize: '0.8rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (unsavedQuote) dispatch(worklogActions.setUnSavedQuotes({ showSaveandExit: true }));
                  else navigate(-1);
                }}
              />
              <Typography variant="h4">Edit {formValues.quoteNumber}</Typography>
            </Box>
            <Typography variant="body2" gutterBottom>
              STATUS
            </Typography>
            <Select
              defaultValue={formValues.status}
              onChange={(newSelection) => {
                handleSetStatus(newSelection.target.value);
              }}
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              sx={{
                height: '32px',
                width: '100%',
              }}
              value={formValues.status}>
              {types.map((name) => {
                return (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
            <FilterButtons ml={'auto'}>
              {quoteData?.status === 'Pending' && (
                <CustomButton
                  variant="contained_red"
                  text="Delete Quote"
                  onClick={() => setDeleteModalOpen(true)}
                  width="medium"
                />
              )}
              <CustomButton variant="contained" text="Save Quote" onClick={() => handleSubmitQuote()} width="medium" />
            </FilterButtons>
            <MessageBanner message={message} isTabletScreen={isTabletScreen} />
          </FilterPanelContainer>
          <ViewQuoteContainer>
            <QuoteDocContainer>
              {RenderHeader()}
              {RenderClientForm()}
              {RenderActivityForm()}
              {RenderFooter('detailed')}
            </QuoteDocContainer>
            <QuoteDocSummaryContainer>
              {RenderHeader()}
              {RenderSummary()}
              {RenderFooter('summary')}
            </QuoteDocSummaryContainer>
          </ViewQuoteContainer>
        </PageContainer>
      )}
      {showSaveandExit && (
        <SaveAndExitModal
          title={'Unsaved Changes!'}
          description={'Are you sure you wish to exit without saving changes?'}
          onClose={() =>
            dispatch(
              worklogActions.setUnSavedQuotes({
                unsavedQuote: unsavedQuote,
                showSaveandExit: false,
                navigateURL: '',
              }),
            )
          }
          onExit={() => exitWithoutSaving()}
          onSave={() => handleModalSave()}
        />
      )}
      {deleteModalOpen && (
        <DeleteConfirmationDialog
          title={'Confirm deletion'}
          description={`Are you sure you want to delete ${formValues.quoteNumber}?`}
          secondaryText="This action cannot be undone"
          onClose={() => setDeleteModalOpen(false)}
          onDelete={() => handleDeleteQuote()}
          buttonText={'Delete'}
        />
      )}
    </>
  );
};

export default EditQuote;
