import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

import { getThemeFromStorage } from '../utils';

import { ThemeState, ThemeType } from './types';

export const initialState: ThemeState = {
  selected: getThemeFromStorage() || 'system',
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme(state, action: PayloadAction<ThemeType>) {
      state.selected = action.payload;
    },
  },
});

export const { actions: themeActions, reducer } = slice;
