import * as React from 'react';
import dayjs from 'dayjs';

import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, Box, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import UserTagging from 'features/UserTagging';
import { CommentDataType } from 'services/busmanApi/types';
import { CommentType } from 'types/WorkLogTypes';

import CommentCard from './CommentCard';
import { CustomAccordionSummary, NewCommentBox } from './styles';

type GetCommentsQueryType = (...args: any) => {
  data?: CommentDataType | undefined;
  error?: any;
  isLoading: boolean;
  isError: boolean;
};

interface CommentProps {
  addNewComment: (newComment: string, tagged_users: (string | null)[]) => Promise<string> | void;
  deleteComment: (id: string) => Promise<string> | void;
  updateComment: (updatedComment: string, commentId: string) => Promise<string> | void;
  fetchComments: GetCommentsQueryType;
  updateCommentCount?: (count: number) => void;
  color?: string | null;
  showHeader?: boolean;
  id?: string;
  empID?: string;
}

const Comments = ({
  addNewComment,
  deleteComment,
  updateComment,
  color,
  showHeader = true,
  id = '',
  fetchComments,
  empID,
  updateCommentCount,
}: CommentProps) => {
  const [error, setError] = React.useState<string>('');
  const [newComment, setNewComment] = React.useState<string>('');
  const [taggedUsers, setTaggedUsers] = React.useState<(string | null)[]>([]);
  const [page, setPage] = React.useState<number>(1);

  const { data: commentsData, isLoading } = fetchComments({ id: id, page: page }, { refetchOnMountOrArgChange: true });

  const sortedComments = commentsData?.comments.slice().sort((a, b) => {
    const dateA = dayjs(a.created_at);
    const dateB = dayjs(b.created_at);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSubmitComment = async () => {
    if (error) setError('');

    if (newComment === '') return;

    const result = await addNewComment(newComment, taggedUsers);

    if (result === 'error') setError('Error adding comment !');

    if (result === 'success') setNewComment('');
  };

  React.useEffect(() => {
    if (updateCommentCount) {
      updateCommentCount(commentsData?.count || 0);
    }
  }, [commentsData?.count, updateCommentCount]);

  const renderCommentCard = () => {
    return (
      <>
        {sortedComments?.map((comment: CommentType) => (
          <CommentCard
            color={color}
            key={comment.comment_id}
            comment={comment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            error={error}
            showError={setError}
          />
        ))}
        {commentsData?.total_pages && commentsData?.total_pages > 1 && (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 1 }}>
            <Pagination count={commentsData?.total_pages} size="small" page={page} onChange={handleChange} />
          </Box>
        )}
      </>
    );
  };

  if (isLoading) return null;

  return (
    <Box>
      <Accordion disableGutters elevation={0} square expanded sx={{ backgroundColor: color, borderBottom: 'none' }}>
        {showHeader && (
          <CustomAccordionSummary aria-controls="comment-content" id="comment-header">
            <Typography variant="h6">{commentsData?.count} Comments</Typography>
          </CustomAccordionSummary>
        )}
        <AccordionDetails sx={{ padding: 0 }}>
          {commentsData?.count ? renderCommentCard() : null}
          <NewCommentBox>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <AccountCircleIcon
                sx={{ color: !color || color === '#FFFFFF' || color === 'c8e6c9' ? '#9E9E9E' : '#FFFFFF' }}
              />
              <UserTagging
                setNewComment={setNewComment}
                setTaggedUsers={setTaggedUsers}
                commentValue={newComment}
                handleCommentUpdate={handleSubmitComment}
                existingComment={false}
                empID={empID}
              />
            </Box>
          </NewCommentBox>
        </AccordionDetails>
        {error && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="subtitle2_italic" gutterBottom sx={{ color: 'red' }}>
              {error}
            </Typography>
          </Box>
        )}
      </Accordion>
    </Box>
  );
};

export default Comments;
