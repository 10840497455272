import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import WorklogIcon from '@mui/icons-material/AccessTimeFilled';
import WorklogManagerIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClientsIcon from '@mui/icons-material/Contacts';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import LockIcon from '@mui/icons-material/Lock';
import QuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/SpaceDashboard';
import TeamIcon from '@mui/icons-material/SupervisorAccount';
import JobIcon from '@mui/icons-material/Work';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import BusinessInsightsIcon from 'components/BusinessInsightsIcon';
import { authActions } from 'features/Auth/authSlice';
import { worklogActions } from 'features/worklog/worklogSlice';

import { RootState } from '../../../../store';
import MissingLogIcon from '../../../MissingLogIcon';

import { DrawerHeader } from './styles';

interface SideDrawerProps {
  openDrawer: boolean;
  close: any;
  title: string;
}

export default function SideDrawer({ openDrawer, close, title }: SideDrawerProps) {
  const theme = useTheme();
  const { unsavedQuote } = useSelector((state: RootState) => state.worklogs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  const getDrawerContent = (menu: string) => {
    switch (menu) {
      case 'ME':
        return [
          { name: 'Dashboard', Icon: DashboardIcon, permission: 'All' },
          { name: 'Worklogs', Icon: WorklogIcon, permission: 'All' },
        ];

      case 'TEAMS':
        return [
          { name: 'Teams', Icon: TeamIcon, permission: ['A', 'M'] },
          { name: 'Worklog Manager', Icon: WorklogManagerIcon, permission: ['A', 'M'] },
          { name: 'Missing Logs', Icon: MissingLogIcon, permission: ['A', 'M'] },
        ];

      case 'CLIENT':
        return [
          { name: 'Clients', Icon: ClientsIcon, permission: ['A', 'M'] },
          { name: 'Jobs', Icon: JobIcon, permission: ['A', 'M'] },
          { name: 'Quotes', Icon: QuoteIcon, permission: ['A', 'M'] },
          { name: 'Business Insights', Icon: BusinessInsightsIcon, permission: ['A', 'M'] },
        ];

      case 'SETTING':
        return [{ name: 'MYOB Configure', Icon: SettingsIcon, permission: ['A'] }];

      case 'ACCOUNT':
        return [
          { name: 'Change Password', Icon: LockIcon, permission: 'All' },
          { name: 'Logout', Icon: ExitToAppRoundedIcon, permission: 'All' },
        ];

      default:
        return null;
    }
  };

  const getUrl = (id: string) => {
    let url;
    switch (id) {
      case 'id-Dashboard':
        url = '/';
        break;
      case 'id-Worklogs':
        url = '/worklogs';
        break;
      case 'id-notifications':
        url = '/notifications';
        break;
      case 'id-Worklog Manager':
        url = '/worklogmanager';
        break;
      case 'id-Teams':
        url = '/teams';
        break;
      case 'id-Missing Logs':
        url = '/missinglogs';
        break;
      case 'id-Clients':
        url = '/clients';
        break;
      case 'id-Jobs':
        url = '/jobs';
        break;
      case 'id-Quotes':
        url = '/quotes';
        break;
      case 'id-Cost Tracker':
        url = '/jobs/costs';
        break;
      case 'id-MYOB Configure':
        url = '/myobconfigure';
        break;
      case 'id-Change Password':
        url = '/changepassword';
        break;
      case 'id-Business Insights':
        url = '/businessinsights';
        break;
      case 'id-Logout':
        dispatch(authActions.resetCredentials());
        break;
      default:
        url = null;
        break;
    }

    return url;
  };

  const handleSubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    const url = getUrl(event.currentTarget.id);

    if (unsavedQuote && url)
      return dispatch(worklogActions.setUnSavedQuotes({ showSaveandExit: true, navigateURL: url }));

    if (url) navigate(url);
  };

  return (
    <Drawer
      variant="temporary"
      // hideBackdrop
      disableScrollLock
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.primary.main,
          height: '95vh',
          width: 'auto',
          top: theme.spacing(1),
          left: theme.spacing(9),
        },
      }}
      anchor="left"
      open={openDrawer}
      onClose={close}>
      <DrawerHeader>
        <Typography variant="body2" sx={{ pl: theme.spacing(1) }}>
          {title}
        </Typography>
      </DrawerHeader>
      <Box sx={{ width: '200px' }} role="presentation" onClick={close} onKeyDown={close}>
        <List>
          {getDrawerContent(title)?.map(({ name, Icon, permission }) => {
            if (permission === 'All' || permission.includes(user.user_role)) {
              return (
                <ListItem key={name} disablePadding>
                  <ListItemButton
                    id={`id-${name}`}
                    onClick={handleSubMenuClick}
                    sx={{
                      color: theme.palette.primary.light,
                      ':hover': {
                        color: theme.palette.primary.contrastText,
                      },
                      minHeight: 48,
                      justifyContent: 'center',
                      px: 2.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: '24px',
                        marginRight: '12px',
                        color: theme.palette.primary.light,
                      }}>
                      {Icon && <Icon sx={{ padding: 'none' }} />}
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{
                        '& .MuiListItemText-primary': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }

            return null;
          })}
        </List>
      </Box>
    </Drawer>
  );
}
