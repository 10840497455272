import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import { useTheme } from '@mui/material/styles';

import { ProductivityProps } from 'services/busmanApi/types';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ProductivityChartProps {
  chartStats: ProductivityProps;
}

const ProductivityChart = ({ chartStats }: ProductivityChartProps) => {
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [
          chartStats.billableHours,
          chartStats.productiveHours,
          chartStats.leaveHours,
          chartStats.unProductiveHours,
        ],
        backgroundColor: [
          theme.palette.busmanColors.busmanPrimary,
          theme.palette.busmanColors.busmanGreen,
          theme.palette.busmanColors.busmanRed,
          theme.palette.busmanColors.darkGrey,
        ],
        hoverOffset: 2,
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      options={{
        devicePixelRatio: 4,
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: true,
        plugins: {
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      }}
    />
  );
};

export default ProductivityChart;
