import React, { useState } from 'react';

import { Link, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/material';

import CustomButton from 'components/Button';
import SuccessModal from 'components/SuccessModal';
import { useRequestResetPasswordMutation } from 'services/busmanApi';

import { ForgotPasswordBox, ForgotPasswordContainer, Row } from './styles';

const ForgotPassword = () => {
  const theme = useTheme();
  const [message, setMessage] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const [requestReset] = useRequestResetPasswordMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestReset({ email: email }).then((response) => {
      if ('data' in response) {
        setSuccess(true);
        setMessage(`${response.data.data}`);
      }
      if ('error' in response) {
        setMessage('*Please enter a valid email');
      }
    });
  };

  return (
    <>
      {success && (
        <SuccessModal purpose="Back to Login" message="Password reset link has been sent to your Email" url="/login" />
      )}
      <ForgotPasswordContainer>
        <ForgotPasswordBox>
          <Row>
            <Typography component="h4" variant="h4">
              Send Verification Email
            </Typography>
          </Row>
          <Box
            component="form"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
              padding: theme.spacing(2),
            }}
            onSubmit={handleSubmit}>
            <Box>
              <TextField
                size="small"
                required
                fullWidth
                id="email"
                label="Email Address"
                placeholder="example@email.com"
                name="email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
              {message && (
                <Typography variant="subtitle1" sx={{ mt: 1, color: success ? 'green' : 'red' }}>
                  {message}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1), marginLeft: 'auto' }}>
              <CustomButton type="submit" variant="contained_purple" text="Send Password Reset Link" />
              <Link sx={{ textAlign: 'right' }} href="/login" variant="body2">
                Back to login
              </Link>
            </Box>
          </Box>
        </ForgotPasswordBox>
      </ForgotPasswordContainer>
    </>
  );
};

export default ForgotPassword;
