import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { FilterButtons, PageContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SearchActivitiesModal from 'components/SearchActivities';
import SnackBar, { MessageType } from 'components/SnackBar';
import { employeeActions } from 'features/Employee/EmployeeSlice';
import { worklogActions } from 'features/worklog/worklogSlice';
import {
  useGetActivitiesQuery,
  useLazyGetEmployeeDetailsQuery,
  useUpdateEmployeeDetailMutation,
} from 'services/busmanApi';
import { ActivitiesProps } from 'types/WorkLogTypes';
import { sortLists } from 'utils/sort';

import { RootState } from '../../store';

import { CustomCheckbox, CustomSwitch, Header } from './styles';

type AllowableActivitiesType = {
  activityId: string;
  activityName: string;
};

type UserDetailType = {
  user_role?: string;
  is_active?: boolean;
  activities?: Array<string>;
};

const UserDetail = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [detailToUpdate, setDetailToUpdate] = React.useState<UserDetailType | null>(null);
  const [message, setMessage] = React.useState({ type: '', msg: '' });
  const [allowableActivities, setAllowableActivities] = React.useState<AllowableActivitiesType[]>([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { empID } = useParams();
  const [updateEmployeeDetail, { isLoading: updating }] = useUpdateEmployeeDetailMutation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedEmployee } = useSelector((state: RootState) => state.employee);
  const { activities } = useSelector((state: RootState) => state.worklogs);

  const activitiesResult = useGetActivitiesQuery({});
  const [fetchEmployeeDetail, { isFetching }] = useLazyGetEmployeeDetailsQuery();

  let filteredActivities: ActivitiesProps[] = activities;

  if (allowableActivities.length) {
    filteredActivities = activities.filter((activity) => {
      return !allowableActivities.some(
        (allowedActivity: AllowableActivitiesType) => allowedActivity.activityId === activity.activity_id,
      );
    });
  }

  const loading = isFetching || !selectedEmployee || activitiesResult.isLoading || updating;

  const activityIsActive = (id: string) => {
    const selectedActivity = activities.find((activity: ActivitiesProps) => activity.activity_id === id);

    return selectedActivity?.is_active;
  };

  const fetchSelectedEmployee = async () => {
    const { data } = await fetchEmployeeDetail(empID);
    dispatch(employeeActions.setSelectedEmployee(data));
  };

  const handleCloseSnackbar = () => {
    setMessage({ type: '', msg: '' });
  };

  const showMessage = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const updateUserInfo = (key: string, value: string | boolean | Array<string>) => {
    const newDetailToUpdate = { ...detailToUpdate, [key]: value };
    setDetailToUpdate(newDetailToUpdate);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newActivities = allowableActivities?.map(({ activityId }) => activityId);

    const newUserDetail = {
      employee_id: empID,
      user_role: detailToUpdate?.user_role || selectedEmployee?.user_role,
      is_active:
        typeof detailToUpdate?.is_active === 'boolean' ? detailToUpdate?.is_active : selectedEmployee?.is_active,
      activities: newActivities,
    };

    const result = await updateEmployeeDetail(newUserDetail);
    if ('error' in result) {
      showMessage({ type: 'error', msg: 'Error updating employee detail!' });
    }

    if ('data' in result) {
      showMessage({ type: 'success', msg: 'User Detail updated.' });
      setDetailToUpdate(null);
      dispatch(employeeActions.setSelectedEmployee(result.data?.data));
    }
  };

  const removeActivity = (id: string) => {
    const newActivities = allowableActivities.filter((activity: AllowableActivitiesType) => activity.activityId !== id);
    setAllowableActivities(newActivities);
    updateUserInfo(
      'activities',
      newActivities?.map(({ activityId }) => activityId),
    );
  };

  const onSelectActivity = (userActivities: ActivitiesProps[]) => {
    const selectedActivities = userActivities.map((activity) => {
      return {
        activityId: activity.activity_id,
        activityName: activity.activity_name,
      };
    });
    setAllowableActivities((prevActivities) => [...prevActivities, ...selectedActivities]);

    updateUserInfo(
      'activities',
      allowableActivities?.map(({ activityId }) => activityId),
    );
  };

  React.useEffect(() => {
    if (!activitiesResult.isLoading && !activitiesResult.error && activitiesResult.data) {
      dispatch(worklogActions.setActivities(activitiesResult.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesResult]);

  React.useEffect(() => {
    if (!selectedEmployee) {
      fetchSelectedEmployee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  React.useEffect(() => {
    if (selectedEmployee?.activities?.length) {
      const allowedActivities = selectedEmployee?.activities.map((jobActivity: ActivitiesProps) => {
        return { activityId: jobActivity.activity_id, activityName: jobActivity.activity_name };
      });

      if (allowedActivities.length) setAllowableActivities(allowedActivities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-User Detail" />
      </Helmet>
      <Loader open={loading} />
      <PageContainer>
        {selectedEmployee && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
              backgroundColor: theme.palette.background.paper,
              height: 'fit-content',
              maxHeight: '90vh',
              overflowY: 'auto',
              width: '600px',
              marginRight: '10px',
              [theme.breakpoints.down('md')]: {
                position: 'fixed',
                maxWidth: '100vw',
              },
              [theme.breakpoints.down('sm')]: {
                width: '92dvw',
                maxHeight: '95dvh',
              },
            }}>
            <Header>
              <Typography variant="h4"> User Details </Typography>
            </Header>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '16px',
                },
              }}>
              <Box sx={{ minWidth: '260px' }}>
                <Typography variant="body1" sx={{ color: '#9E9E9E' }}>
                  USER NAME
                </Typography>
                <TextField fullWidth name="user-name" size="small" value={selectedEmployee?.full_name} />
              </Box>
              <Box sx={{ minWidth: '260px' }}>
                <Typography variant="body1" sx={{ color: '#9E9E9E' }}>
                  EMAIL
                </Typography>
                <TextField fullWidth name="user_email" size="small" value={selectedEmployee?.email} />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 2,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: '16px',
                },
              }}>
              <Box sx={{ minWidth: '260px' }}>
                <Typography variant="body1" sx={{ color: '#9E9E9E' }}>
                  ROLE
                </Typography>
                <Select
                  fullWidth
                  sx={{ height: '32px' }}
                  onChange={(event) => updateUserInfo('user_role', event.target.value as string)}
                  value={detailToUpdate?.user_role || selectedEmployee?.user_role}>
                  {user?.user_role === 'A' && (
                    <MenuItem key="A" value="A">
                      Admin
                    </MenuItem>
                  )}
                  <MenuItem key="E" value="E">
                    Employee
                  </MenuItem>
                  <MenuItem key="M" value="M">
                    Manager
                  </MenuItem>
                </Select>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '260px', marginTop: 2 }}>
                <CustomSwitch
                  checked={
                    typeof detailToUpdate?.is_active === 'boolean'
                      ? detailToUpdate?.is_active
                      : selectedEmployee.is_active
                  }
                  onChange={(event) => updateUserInfo('is_active', event.target.checked)}
                />
                <Typography variant="subtitle1" sx={{ marginLeft: theme.spacing(2) }}>
                  Active User
                </Typography>
              </Box>
            </Box>
            <Box>
              <Header>
                <Typography variant="h6">Allowable Activities</Typography>
              </Header>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: theme.spacing(2),
                    paddingBottom: theme.spacing(1),
                  }}>
                  <Typography variant="body1" sx={{ color: '#9E9E9E' }}>
                    ACTIVITY ID
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#9E9E9E' }}>
                    ALLOW
                  </Typography>
                </Box>
                <List
                  sx={{
                    width: '100%',
                    height: '30vh',
                    border: allowableActivities.length ? '' : `1px solid ${theme.palette.background.default}`,
                    maxHeight: '52vh',
                    overflowY: 'auto',
                    mb: theme.spacing(2),
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      maxHeight: 'fit-content',
                    },
                  }}>
                  {allowableActivities &&
                    sortLists(allowableActivities, 'activityName').map((activity: AllowableActivitiesType) => (
                      <ListItem
                        key={activity.activityId}
                        sx={{
                          fontSize: '12px',
                          fontWeight: '500',
                          height: '36px',
                          border: `1px solid ${theme.palette.background.default}`,
                          mb: 0.5,
                          ':hover': { bgcolor: theme.palette.background.default },
                        }}
                        secondaryAction={
                          <>
                            <Tooltip title="active">
                              <CustomCheckbox
                                size="small"
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    color: activityIsActive(activity.activityId)
                                      ? theme.palette.busmanColors.busmanPrimary
                                      : theme.palette.primary.light,
                                    backgroundColor: activityIsActive(activity.activityId)
                                      ? theme.palette.busmanColors.busmanPrimary
                                      : theme.palette.primary.light,
                                  },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="remove activity">
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => removeActivity(activity.activityId)}>
                                <ClearIcon sx={{ fontSize: 'small' }} />
                              </IconButton>
                            </Tooltip>
                          </>
                        }>
                        <ListItemText primary={activity.activityName} />
                      </ListItem>
                    ))}
                </List>
                <CustomButton
                  variant="contained_grey"
                  onClick={() => setOpen(true)}
                  width="medium"
                  text="Assign Activity"
                />
              </Box>
              <FilterButtons
                sx={{
                  paddingBottom: theme.spacing(2),
                  justifyContent: 'flex-end',
                  mt: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    paddingRight: theme.spacing(1),
                  },
                }}>
                <CustomButton disabled={!detailToUpdate} variant="contained" width="medium" text="Save" type="submit" />
                <SearchActivitiesModal
                  activities={filteredActivities}
                  open={open}
                  onClose={() => setOpen(false)}
                  onSelectMultiple={onSelectActivity}
                />
              </FilterButtons>
            </Box>
          </Box>
        )}
      </PageContainer>

      <SnackBar message={message} onClose={handleCloseSnackbar} />
    </>
  );
};

export default UserDetail;
