import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  height: '56px',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  width: '100%',
}));
