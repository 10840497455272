import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

interface TimePickerProps {
  onChange: (arg: Dayjs | null) => void;
  value: Dayjs | null;
}

export default function BasicTimePicker({ onChange, value }: TimePickerProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [adornmentFocus, setAdornmentFocus] = React.useState<boolean>(false);

  const roundToNearest15Minutes = (time: Dayjs | null) => {
    if (time === null) return null;
    const minutes = time.minute();
    const remainder = minutes % 15;
    if (remainder >= 8) {
      time = time.add(15 - remainder, 'minute');
    } else {
      time = time.subtract(remainder, 'minute');
    }
    return time;
  };

  const handleChange = (newValue: Dayjs | null) => {
    const roundedTime = roundToNearest15Minutes(newValue);
    onChange(roundedTime);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          open={open}
          value={value}
          inputFormat="hh:mm a"
          onError={(e) => {
            if (e === null) setError(false);
            else setError(true);
          }}
          onAccept={() => setError(false)}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          onClose={() => setOpen(false)}
          minutesStep={15}
          PopperProps={{
            placement: 'bottom-start',
          }}
          InputProps={{
            sx: {
              minWidth: 'none',
              pl: 0,
              '.MuiOutlinedInput-input': { p: 1 },
            },
          }}
          InputAdornmentProps={{
            position: 'start',
            onClick: () => setOpen(true),
            onFocus: () => setAdornmentFocus(true),
            onBlur: () => setAdornmentFocus(false),
            sx: {
              '.MuiIconButton-root': { margin: 0 },
              backgroundColor: theme.palette.background.default,
              height: '100%',
              width: '32px',
              padding: 0,
              maxHeight: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `1px ${theme.palette.background.default} solid`,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onTouchStart={() => setOpen(true)}
              fullWidth
              onClick={() => {
                if (error) {
                  setError(false);
                  onChange(dayjs().startOf('hour'));
                }
                setOpen(true);
              }}
              sx={{
                svg: {
                  backgroundColor: adornmentFocus ? theme.palette.primary.contrastText : '',
                  '&:hover': {},
                  border: adornmentFocus ? `1px solid ${theme.palette.primary.light}` : '',
                },
                '.MuiOutlinedInput-input': { pt: 1, pb: 1, color: error ? 'red' : 'black' },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

// import * as React from 'react';
// import { useEffect } from 'react';
// import { Dayjs } from 'dayjs';

// import Box from '@mui/material/Box';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// interface TimePickerProps {
//   onChange: (arg: Dayjs | null) => void;
//   value: Dayjs | null;
// }

// export default function BasicTimePicker({ onChange, value }: TimePickerProps) {
//   const [open, setOpen] = React.useState<boolean>(false);

//   useEffect(() => {
//     const handleWindowBlur = () => {
//       setOpen(false);
//     };

//     window.addEventListener('blur', handleWindowBlur);

//     return () => {
//       window.removeEventListener('blur', handleWindowBlur);
//     };
//   }, []);

//   return (
//     <Box sx={{ minWidth: '300px', position: 'relative' }}>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <TimePicker
//           open={open}
//           value={value}
//           onChange={(newValue) => {
//             onChange(newValue);
//           }}
//           onClose={() => setOpen(false)}
//           minutesStep={15}
//           slotProps={{
//             textField: {
//               fullWidth: true,
//               onClick: () => setOpen(true),
//               sx: {
//                 '.MuiOutlinedInput-input': { p: 1 },
//                 '.MuiOutlinedInput-notchedOutline': {
//                   border: 'none',
//                 },
//               },
//             },
//             inputAdornment: { position: 'start' },
//           }}
//         />
//       </LocalizationProvider>
//     </Box>
//   );
// }
