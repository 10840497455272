import { styled } from '@mui/material';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  alignItems: 'center',
  minHeight: '40px',
  marginTop: '10px',
  marginBottom: theme.spacing(5),
}));
