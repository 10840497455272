import { busmanApiSlice } from './busmanApiSlice';

export const businessInsights = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Business Insights
    getBusinessInsights: builder.query({
      query: ({ startDate, endDate, levelOne, levelTwo, levelThree }) =>
        `/insights/?&start_date=${startDate}&end_date=${endDate}&level_one=${levelOne}&level_two=${levelTwo}&level_three=${levelThree}`,
      providesTags: ['Insights'],
    }),
  }),
});

export const { useLazyGetBusinessInsightsQuery } = businessInsights;
