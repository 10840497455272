import React from 'react';

import { Avatar, Box } from '@mui/material';

import { BusinessInsight } from '../../images/index';

const BusinessInsightsIcon = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Avatar alt="MissinLog Icon" src={BusinessInsight} sx={{ width: 24, height: 24, padding: '2px' }} />
    </Box>
  );
};

export default BusinessInsightsIcon;
