import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AddWorklogCard from 'components/AddWorklogCard';
import WorkLogCard from 'components/WorklogCard';
import { ExtendedWorklogDayType, ExtendedWorklogType, WorklogType } from 'types/WorkLogTypes';

import GanttChart from '../GanttChart';

import { WorklogDayBox, WorklogDayHeader } from './styles';
interface PredefinedData {
  date?: Dayjs;
  startTime?: Dayjs;
  endTime?: Dayjs;
}

interface WorklogDayCardProps {
  data: ExtendedWorklogDayType;
  isGanttChartVisible: boolean;
  isSelected?: (id: string) => boolean;
  onApproveWorklog?: (data: WorklogType) => void;
  onEditWorklog: (data: WorklogType) => void;
  onDeleteWorkLog: (workLogId: Array<string>) => void;
  onUnsyncWorkLog?: (workLogId: string) => void;
  setPredefinedData?: (data: PredefinedData) => void;
  showAddWorklog?: () => void;
}

const WorklogDayCard = ({
  data,
  isSelected = () => false,
  onApproveWorklog = () => {},
  onEditWorklog,
  onDeleteWorkLog,
  isGanttChartVisible,
  onUnsyncWorkLog,
  setPredefinedData,
  showAddWorklog,
}: WorklogDayCardProps) => {
  const theme = useTheme();

  dayjs.extend(advancedFormat);
  dayjs.extend(isBetween);

  const totalHours = data.worklogs?.reduce((totalhrs: number, worklog: ExtendedWorklogType) => {
    if (worklog.groupedIds) {
      return totalhrs + worklog.worklog_hours * worklog.groupedIds.length;
    }
    return totalhrs + worklog.worklog_hours;
  }, 0);

  function flagOverlapWorklogs(logs: WorklogType[]) {
    return logs
      .map((logA) => ({
        ...logA,
        isOverlapped: logs.some(
          (logB: WorklogType) =>
            logA.slip_id !== logB.slip_id && // Skip comparing the same log
            (dayjs(logA.start_time).isBetween(logB.start_time, logB.end_time, null, '[)') ||
              dayjs(logA.end_time).isBetween(logB.start_time, logB.end_time, null, '(]')),
        ),
      }))
      .sort((a, b) => dayjs(a.end_time).diff(dayjs(b.start_time)));
  }

  // Calculate billable hours by job_type = 'TimeBilled'
  const billableHours = data.worklogs.reduce((totalhrs: number, worklog: WorklogType) => {
    if (worklog.worklog_type === 'WorkLog' && worklog.job?.job_type !== 'Internal') {
      return totalhrs + worklog.worklog_hours;
    } else {
      return totalhrs;
    }
  }, 0);

  const worklogData = {
    totalHours: totalHours,
    worklogs: flagOverlapWorklogs(data?.worklogs),
    worklogDate: data?.date,
    hasDateRange: data?.startDate && data?.endDate,
  };

  return (
    <WorklogDayBox>
      <WorklogDayHeader>
        <Box display="flex" alignItems="flex-end">
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            DATE
          </Typography>
          {data.startDate ? (
            <Typography variant="subtitle1">
              {dayjs(data.startDate).format('Do MMMM YYYY')} - {dayjs(data.endDate).format('Do MMMM YYYY')}
            </Typography>
          ) : (
            <Typography variant="subtitle1">{dayjs(data.date).format('dddd, Do MMMM YYYY')}</Typography>
          )}
        </Box>
        <Box display="flex" alignItems="flex-end" gap={theme.spacing(2)}>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              BILLABLE HRS
            </Typography>
            <Typography variant="subtitle1">{billableHours}</Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              TOTAL HRS
            </Typography>
            <Typography variant="subtitle1">{totalHours}</Typography>
          </Box>
        </Box>
      </WorklogDayHeader>
      {isGanttChartVisible && <GanttChart worklogData={worklogData} />}
      {worklogData.worklogs?.length &&
        worklogData.worklogs.map((worklog: ExtendedWorklogType) => (
          <WorkLogCard
            key={worklog.slip_id}
            worklog={worklog}
            isSelected={isSelected}
            onApproveWorklog={onApproveWorklog}
            onEditWorklog={onEditWorklog}
            onDeleteWorkLog={onDeleteWorkLog}
            onUnsyncWorkLog={onUnsyncWorkLog}
          />
        ))}
      {/* hide add worklog card button option for date range worklogday card */}
      {!worklogData.hasDateRange && showAddWorklog && setPredefinedData && (
        <AddWorklogCard data={data} setPredefinedData={setPredefinedData} showAddWorklog={showAddWorklog} />
      )}
    </WorklogDayBox>
  );
};

export default WorklogDayCard;
