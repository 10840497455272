export const MuiOutlinedInput = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        fontSize: '12px',
        minHeight: 'none',
        borderRadius: '2px',
        border: '1px solid #F2F2F2',
        '&:hover': {
          border: '1px solid #9E9E9E',
        },
        '&.Mui-focused': {
          border: '1px solid #9E9E9E',
        },
        '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
          fontSize: '23px',
          color: '#9E9E9E',
          '&: hover': {
            backgroundColor: 'transparent',
          },
        },
        '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
          fontSize: '23px',
          '&: hover': {
            backgroundColor: 'transparent',
          },
        },
        '& .MuiAutocomplete-endAdornment': {
          top: '0px',
          height: '100%',
          color: '#9E9E9E',
        },
        '& .MuiButtonBase-root': {
          height: '100%',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&.Mui-readOnly': {
          '&:hover': {
            border: '1px solid #F2F2F2',
          },
          '&.Mui-focused': {
            border: '1px solid #F2F2F2',
          },
        },
      },
    },
  },
};
