import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const MuiSelect = {
  MuiSelect: {
    defaultProps: {
      IconComponent: ExpandMoreIcon,
    },
    styleOverrides: {
      fontSize: '12px',
      root: {
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiButtonBase-root': {
          height: '32px',
          width: '32px',
        },
      },
    },
  },
};
