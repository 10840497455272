import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';
import { RootState } from 'store';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx';

import { ArrowForwardIosSharp, IosShare as ExportIcon } from '@mui/icons-material';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import {
  FilterButtons,
  FilterPanelContainer,
  HeaderUnderline,
  ItemListContainer,
  PageContainer,
} from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import { useLazyGetBusinessInsightsQuery } from 'services/busmanApi/businessInsightsEndpoints';
import { getPreferncesFromLocalStorage, setPreferencesInLocalStorage } from 'utils/localStorage';

interface BannerProps {
  title: string;
  categoryType: string;
  hours: number;
  billable: number;
}

interface ExportDataType {
  Employee?: string;
  Activity?: string;
  Job?: string;
  Hours: number | null;
  Billables: string;
}

interface DataTypes {
  insight_category_type: string;
  insight_category_name: string;
  insight_category_id: string;
  total_hours: number;
  billable_amount: number;
  insight_sub_category?: DataTypes[] | null;
}

interface TeamState {
  [team_name: string]: boolean;
}

interface ParamType {
  levelOne: string;
  levelTwo: string;
  levelThree: string;
  startDate: string;
  endDate: string;
}

const BusinessInsights = () => {
  const theme = useTheme();
  const initialLevels = getPreferncesFromLocalStorage('businessInsights') || {
    levelOne: 'Jobs',
    levelTwo: 'Employees',
    levelThree: 'Activities',
  };
  const [searchValue, setSearchValue] = React.useState('');
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const [invalidDate, setInvalideDate] = React.useState(false);
  const [sortBy, setSortBy] = React.useState('CATEGORY');
  const [sortAscending, setsortAscending] = React.useState<boolean>(false);

  const [params, setParams] = React.useState({
    levelOne: initialLevels?.levelOne,
    levelTwo: initialLevels?.levelTwo,
    levelThree: initialLevels?.levelThree,
  });
  const [openAccordions, setopenAccordions] = React.useState<TeamState>({});
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const [data, setData] = React.useState<DataTypes[] | null>(null);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const [fetchInsights, { isFetching }] = useLazyGetBusinessInsightsQuery();

  const totalHours =
    !isFetching && data && data.reduce((acc: number, category: DataTypes) => acc + category.total_hours, 0);

  const totalBillable =
    !isFetching &&
    data &&
    data.reduce((acc: number, category: DataTypes) => {
      return acc + Number(category.billable_amount);
    }, 0);

  const sortData = (categoryData: DataTypes[] | null | undefined) => {
    if (!categoryData) return null;
    const sortedData = [...categoryData].sort((a, b) => {
      if (sortBy === 'HOURS') {
        return Number(b.total_hours) - Number(a.total_hours);
      }
      if (sortBy === 'BILLABLE') {
        return Number(b.billable_amount) - Number(a.billable_amount);
      }
      return a.insight_category_name.localeCompare(b.insight_category_name);
    });
    return sortAscending ? sortedData : sortedData.reverse();
  };

  const filteredData = useMemo(() => {
    if (!data) return null;
    if (!searchValue) return sortData([...data]);

    const searchInCategories = (categories: DataTypes[], search: string) => {
      return categories.reduce((matchingCategories: DataTypes[], category: DataTypes) => {
        const subCategories = category.insight_sub_category
          ? searchInCategories(category.insight_sub_category, search)
          : [];
        const categoryMatches = category.insight_category_name.toLowerCase().includes(search.toLowerCase());

        if (categoryMatches) {
          matchingCategories.push({
            ...category,
            insight_sub_category: category.insight_sub_category,
          });
        } else if (subCategories.length > 0) {
          matchingCategories.push({
            ...category,
            insight_sub_category: subCategories,
          });
        }

        return matchingCategories;
      }, []);
    };

    const searchData = searchInCategories(data, searchValue);
    return sortData(searchData);
  }, [data, searchValue, sortBy, sortAscending]);

  const handleFromDateChange = (date: Dayjs | null) => {
    if (dayjs(date).isAfter(toDate)) setInvalideDate(true);
    if (!dayjs(date).isAfter(toDate) && invalidDate) setInvalideDate(false);
    if (dayjs(date).isBefore(dayjs(toDate).subtract(1, 'month'))) setToDate(dayjs(date).add(1, 'month'));

    setFromDate(date);
  };

  const handleToDateChange = (date: Dayjs | null) => {
    if (dayjs(fromDate).isAfter(date)) setInvalideDate(true);
    if (!dayjs(fromDate).isAfter(date) && invalidDate) setInvalideDate(false);
    if (dayjs(date).isAfter(dayjs(fromDate).add(1, 'month'))) setFromDate(dayjs(date).subtract(1, 'month'));

    setToDate(date);
  };

  const handleSortOptionClick = (sortOption: string) => {
    if (sortOption === sortBy) {
      return setsortAscending(!sortAscending);
    }
    setSortBy(sortOption);
    setsortAscending(!sortAscending);
  };

  const handleFetchInsights = async (newParams: ParamType) => {
    const result = await fetchInsights(newParams);

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error Fetching Insights' });
    }
    if ('data' in result) {
      setData(result.data.data as DataTypes[]);
      setopenAccordions({});
    }
  };

  const handleSearch = () => {
    setPreferencesInLocalStorage('businessInsights', {
      levelOne: params.levelOne,
      levelTwo: params.levelTwo,
      levelThree: params.levelThree,
    });

    handleFetchInsights({
      ...params,
      startDate: dayjs(fromDate).format('YYYY-MM-DD'),
      endDate: dayjs(toDate).format('YYYY-MM-DD'),
    });
  };

  const getColumnName = (type: string) => {
    switch (type) {
      case 'Activities':
        return 'Activity';
      case 'Employees':
        return 'Employee';
      case 'Jobs':
        return 'Job';
      default:
        return type;
    }
  };

  const transformData = (jobData: DataTypes[], name: string, type: string) => {
    const result: ExportDataType[] = [];

    jobData.forEach((employee: DataTypes) => {
      employee?.insight_sub_category?.forEach((activity: DataTypes) => {
        result.push({
          [getColumnName(type)]: name,
          [getColumnName(employee.insight_category_type)]: employee.insight_category_name,
          [getColumnName(activity.insight_category_type)]: activity.insight_category_name,
          Hours: activity.total_hours,
          Billables: `$${activity.billable_amount}`,
        });
      });
    });

    return result;
  };

  const handleExport = () => {
    if (!data) return;

    const transformedData = data.map((jobInsight) => ({
      name: jobInsight.insight_category_name,
      totalHrs: jobInsight.total_hours,
      totalBillAmount: jobInsight.billable_amount,
      data: jobInsight.insight_sub_category
        ? transformData(
            jobInsight.insight_sub_category,
            jobInsight.insight_category_name,
            jobInsight.insight_category_type,
          )
        : [],
    }));

    const workbook = XLSX.utils.book_new();

    transformedData.forEach(({ name, data: empData, totalHrs, totalBillAmount }) => {
      // Add blank row
      empData.push({
        [getColumnName(initialLevels.levelTwo)]: '',
        [getColumnName(initialLevels.levelThree)]: '',
        Billables: '',
        Hours: null,
      });

      // Add blank row
      empData.push({
        [getColumnName(initialLevels.levelTwo)]: '',
        [getColumnName(initialLevels.levelThree)]: 'Total',
        Billables: `$ ${totalBillAmount.toString()}`,
        Hours: totalHrs,
      });

      const sheet = XLSX.utils.json_to_sheet(empData);

      // Adjust cell width for specific columns
      sheet['!cols'] = [{ width: 30 }, { width: 30 }, { width: 30 }, { width: 10 }, { width: 30 }];

      XLSX.utils.book_append_sheet(workbook, sheet, name);
    });

    const fileName = `BI-by${initialLevels.levelOne}-${dayjs(fromDate).format('D MMM')}-${dayjs(toDate).format(
      'D MMM',
    )}.xlsx`;

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const newData = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(newData, fileName);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setopenAccordions({});
  };

  const handleSearchChange = (event: { target: { value: string } }) => {
    setSearchValue(event.target.value);
  };

  const handleLevel1Change = (value: string) => {
    if (value === params.levelTwo) {
      setParams({ ...params, levelOne: value, levelTwo: params.levelOne });
    }
    if (value === params.levelThree) {
      setParams({ ...params, levelThree: params.levelOne, levelOne: value });
    }
  };

  const handleLevel2Change = (value: string) => {
    if (value === params.levelOne) {
      setParams({ ...params, levelOne: params.levelTwo, levelTwo: value });
    }
    if (value === params.levelThree) {
      setParams({ ...params, levelThree: params.levelTwo, levelTwo: value });
    }
  };

  const handleLevel3Change = (value: string) => {
    if (value === params.levelTwo) {
      setParams({ ...params, levelTwo: params.levelThree, levelThree: value });
    }
    if (value === params.levelOne) {
      setParams({ ...params, levelOne: params.levelThree, levelThree: value });
    }
  };

  React.useEffect(() => {
    if (searchValue === '') return setopenAccordions({});
    filteredData?.forEach((category) => {
      setopenAccordions((prevState) => ({ ...prevState, [category.insight_category_name]: true }));
      category.insight_sub_category?.forEach((subCategory: any) => {
        setopenAccordions((prevState) => ({ ...prevState, [subCategory.insight_category_name]: true }));
      });
    });
  }, [searchValue, data]);

  React.useEffect(() => {
    handleFetchInsights({
      ...params,
      startDate: dayjs(fromDate).format('YYYY-MM-DD'),
      endDate: dayjs(toDate).format('YYYY-MM-DD'),
    });
  }, []);

  React.useEffect(() => {
    setsortAscending(!sortAscending);
  }, [sortBy]);

  const ItemCard = ({ title, categoryType, hours, billable }: BannerProps) => {
    const isLevelOne = categoryType === initialLevels.levelOne;
    const isLevelThree = categoryType === initialLevels.levelThree;
    const mobilePadding = isMobileScreen ? theme.spacing(3) : theme.spacing(4);
    return (
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: isLevelOne ? theme.palette.background.default : theme.palette.background.paper,
          paddingRight: isLevelThree ? mobilePadding : '0',
        }}>
        <GridItem
          title={title}
          seenOrIsHeading={isLevelThree}
          breakpoint={isMobileScreen ? 5.9 : 8}
          customStyles={{ fontSize: '12px' }}
        />
        <GridItem
          title={hours}
          seenOrIsHeading={isLevelThree}
          breakpoint={isMobileScreen ? 3 : 2}
          customStyles={{ fontSize: '12px' }}
        />
        <GridItem
          title={`$${billable}`}
          seenOrIsHeading={isLevelThree}
          breakpoint={isMobileScreen ? 3 : 2}
          customStyles={{ fontSize: '12px' }}
        />
      </Grid>
    );
  };

  const categoryAccordion = (categoryData: DataTypes) => {
    if (!categoryData) return null;
    const isLevelOne = categoryData.insight_category_type === initialLevels?.levelOne;
    const isLevelTwo = categoryData.insight_category_type === initialLevels?.levelTwo;
    return (
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        key={categoryData.insight_category_id + categoryData.insight_category_name + categoryData.total_hours}
        disableGutters
        elevation={0}
        expanded={openAccordions[categoryData.insight_category_name] || false}
        square
        onClick={(event) => {
          event.stopPropagation();
          setopenAccordions({
            ...openAccordions,
            [categoryData.insight_category_name]: !openAccordions[categoryData.insight_category_name],
          });
        }}
        sx={{
          borderBottom: 'none',
          marginBottom: theme.spacing(1),
          border: isLevelTwo ? `1px solid ${theme.palette.background.default}` : 'none',
        }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
          sx={{
            display: 'flex',
            backgroundColor: isLevelOne ? theme.palette.background.default : theme.palette.background.paper,
            paddingRight: isMobileScreen ? theme.spacing(1) : theme.spacing(2),
            borderRadius: '2px',
            border: isLevelTwo ? 'none' : `1px solid ${theme.palette.background.default}`,
            maxHeight: '40px',
            position: 'sticky',
            top: '0',
            zIndex: '999',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
          }}>
          <ItemCard
            key={categoryData.insight_category_id}
            categoryType={categoryData.insight_category_type}
            title={categoryData.insight_category_name}
            hours={categoryData.total_hours}
            billable={categoryData.billable_amount}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingInline: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: isLevelTwo ? '16px' : 0,
          }}>
          {isLevelOne &&
            sortData(categoryData.insight_sub_category)?.map((category) => {
              return categoryAccordion(category);
            })}
          {isLevelTwo &&
            sortData(categoryData.insight_sub_category)?.map((subCategory) => {
              return (
                <ItemCard
                  key={subCategory.insight_category_id}
                  categoryType={subCategory.insight_category_type}
                  title={subCategory.insight_category_name}
                  hours={subCategory.total_hours}
                  billable={subCategory.billable_amount}
                />
              );
            })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const LevelSelect = React.useCallback(
    ({
      level,
      handleLevelChange,
      title,
    }: {
      level: string;
      handleLevelChange: (value: string) => void;
      title: string;
    }) => {
      const options = ['Employees', 'Jobs', 'Activities'];
      return (
        <Box>
          <Typography gutterBottom variant="body2" sx={{ color: theme.palette.primary.light }}>
            {title}
          </Typography>
          <Select
            value={level}
            onChange={(event) => {
              handleLevelChange(event.target.value as string);
            }}
            sx={{
              fontSize: '12px',
              height: '32px',
              width: '100%',
            }}>
            {options.map((name) => (
              <MenuItem key={name} value={name}>
                <Fade in={true} key={name} timeout={600}>
                  <Typography variant="subtitle1">{name}</Typography>
                </Fade>
              </MenuItem>
            ))}
          </Select>
        </Box>
      );
    },
    [],
  );

  const CustomGridItem = ({
    title,
    mdBreakPoint,
    smBreakPoint,
  }: {
    title: string;
    mdBreakPoint: number;
    smBreakPoint: number;
  }) => {
    return (
      <Grid
        onClick={() => handleSortOptionClick(title)}
        item
        md={mdBreakPoint}
        sm={smBreakPoint}
        xs={smBreakPoint}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          justifyContent: 'start',
          cursor: 'pointer',
        }}>
        <Tooltip title={`Sort: ${title.toLocaleLowerCase()}` || ''}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.disabled, verticalAlign: 'baseline', marginTop: '1px' }}>
            {title}
          </Typography>
        </Tooltip>
        {sortBy === title && (
          <SortIcon
            sx={{
              fontSize: '15px',
              color: theme.palette.text.disabled,
              rotate: sortAscending ? '0' : '180deg',
            }}
          />
        )}
      </Grid>
    );
  };

  return (
    <>
      <Loader open={isFetching} />
      <SnackBar message={message} onClose={() => setMessage(null)} />
      <PageContainer>
        {filterPanel && (
          <FilterPanelContainer>
            {!isMobileScreen && (
              <Typography gutterBottom sx={{ mb: theme.spacing(4) }} variant="h4">
                Business Insights
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography variant="body2" gutterBottom>
                FROM
              </Typography>
              <DatePicker onChange={handleFromDateChange} value={fromDate} />
              <Typography variant="body2" gutterBottom mt={1}>
                TO
              </Typography>
              <DatePicker minDate={dayjs(fromDate)} onChange={handleToDateChange} value={toDate} />
            </Box>
            {invalidDate && (
              <Typography
                gutterBottom
                sx={{
                  color: theme.palette.busmanColors.busmanRed,
                }}
                variant={'body2'}>
                Invalid date. From date cannot be after To date.
              </Typography>
            )}
            <Box display={'flex'} flexDirection={'column'} gap={'16px'} mt={theme.spacing(6)}>
              <LevelSelect level={params.levelOne} handleLevelChange={handleLevel1Change} title="LEVEL 1" />
              <LevelSelect level={params.levelTwo} handleLevelChange={handleLevel2Change} title="LEVEL 2" />
              <LevelSelect level={params.levelThree} handleLevelChange={handleLevel3Change} title="LEVEL 3" />
            </Box>
            <FilterButtons sx={{ marginLeft: 'auto' }}>
              <CustomButton
                text="Export"
                variant="contained_grey"
                width="medium"
                endIcon={<ExportIcon style={{ fontSize: 18 }} />}
                onClick={() => handleExport()}
                customSx={{ justifyContent: 'space-around' }}
              />
              <CustomButton text="Search" variant="contained" width="medium" onClick={() => handleSearch()} />
            </FilterButtons>
          </FilterPanelContainer>
        )}
        <ItemListContainer sx={{ padding: 0 }}>
          <HeaderUnderline
            sx={{ height: '56px', justifyContent: 'space-between', gap: theme.spacing(3), alignItems: 'center' }}>
            <Box
              onMouseLeave={() => {
                if (!searchValue || searchValue === '') setSearchOpen(false);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                backgroundColor: theme.palette.background.paper,
                height: '32px',
                border: searchOpen ? `1px solid ${theme.palette.background.default}` : 'none',
                width: searchOpen ? '178px' : '50px',
              }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignitems: 'center',
                  justifyContent: 'center',
                  padding: '3px',
                  borderRadius: '2px',
                }}
                onMouseEnter={() => setSearchOpen(true)}>
                <SearchIcon />
              </Box>

              <OutlinedInput
                value={searchValue}
                onChange={handleSearchChange}
                placeholder="Search"
                inputRef={(input) => input && searchOpen && input.focus()}
                endAdornment={
                  <InputAdornment position="end">
                    {searchValue && (
                      <IconButton onClick={handleClearSearch} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                sx={{
                  height: '32px',
                  transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  width: searchOpen ? '100%' : '0',
                  border: 'none',
                  '&.Mui-focused': {
                    border: 'none',
                  },
                  '&:hover': {
                    border: 'none',
                  },
                }}
              />
            </Box>
            <Box display={'flex'} gap={theme.spacing(3)}>
              <Box display={'flex'} gap={theme.spacing(2)}>
                <Typography variant="body2">TOTAL HOURS</Typography>
                <Typography variant="body1">{totalHours}</Typography>
              </Box>
              <Box display={'flex'} gap={theme.spacing(2)}>
                <Typography variant="body2">BILLABLE</Typography>
                <Typography variant="body1">${totalBillable && totalBillable.toFixed(2)}</Typography>
              </Box>
            </Box>
          </HeaderUnderline>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
              paddingInline: theme.spacing(2),
            }}>
            {!isFetching && data && data.length === 0 && (
              <Typography variant="subtitle2" sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
                No data available...
              </Typography>
            )}
            {!isFetching && data && data.length !== 0 && (
              <>
                <Grid
                  container
                  display="flex"
                  mt={theme.spacing(2)}
                  paddingRight={theme.spacing(2)}
                  paddingLeft={theme.spacing(2)}>
                  <CustomGridItem title="CATEGORY" mdBreakPoint={8} smBreakPoint={6} />
                  <CustomGridItem title="HOURS" mdBreakPoint={2} smBreakPoint={3} />
                  <CustomGridItem title="BILLABLE" mdBreakPoint={2} smBreakPoint={3} />
                </Grid>
                {filteredData && filteredData.map((categoryOne: DataTypes) => categoryAccordion(categoryOne))}
              </>
            )}
          </Box>
        </ItemListContainer>
      </PageContainer>
    </>
  );
};

export default BusinessInsights;
