import { busmanApiSlice } from './busmanApiSlice';
import { CreateQuoteResponse, GetAllQuotesResponse, GetQuoteByIdResponse } from './types';

export const quotesEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetches all quotes
    getQuotes: builder.query({
      query: () => '/quotes/',
      providesTags: ['Quote'],
      transformResponse: (response: GetAllQuotesResponse) => response.data,
    }),

    //Fetch Quotes by ID
    getQuotesById: builder.query({
      query: (id) => `quotes/${id}/`,
      providesTags: ['QuoteById'],
      transformResponse: (response: GetQuoteByIdResponse) => response.data,
    }),

    // Update quote
    updateQuotes: builder.mutation({
      query: (updatedQuote) => {
        return {
          url: `quotes/${updatedQuote.id}/`,
          method: 'put',
          body: updatedQuote,
        };
      },
      invalidatesTags: ['Quote', 'QuoteById'],
    }),

    // Create New Quote
    createQuote: builder.mutation({
      query: (newQuote) => {
        return {
          url: 'quotes/create/',
          method: 'post',
          body: newQuote,
        };
      },
      invalidatesTags: ['Quote'],
      transformResponse: (response: CreateQuoteResponse) => response.data,
    }),

    // Delete Quote
    deleteQuote: builder.mutation({
      query: (id) => {
        return {
          url: `quotes/${id}/`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Quote'],
    }),

    // Duplicate Quote
    duplicateQuote: builder.mutation({
      query: (id) => {
        return {
          url: `quotes/${id}/duplicate`,
          method: 'post',
        };
      },
      invalidatesTags: ['Quote'],
      transformResponse: (response: CreateQuoteResponse) => response.data,
    }),
  }),
});

export const {
  useGetQuotesQuery,
  useGetQuotesByIdQuery,
  useUpdateQuotesMutation,
  useCreateQuoteMutation,
  useDeleteQuoteMutation,
  useDuplicateQuoteMutation,
} = quotesEndpoints;
