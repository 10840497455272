// StyledComponents.js
import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const ChangePasswordContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '78px',
  marginTop: theme.spacing(1),
  width: '100%',
  alignItems: 'flex_start',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingLeft: '8px',
    marginTop: '6vh',
    paddingRight: '8px',
  },
}));

export const TextFieldsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.paper,
  height: 'fit-content',
  width: '500px',
  borderRadius: '4px',
  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
}));
