import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import { FilterButtons } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import TimePicker from 'components/TimePicker';
import { useUpdateWorklogMutation } from 'services/busmanApi';
import { PayrollCategoryTypes } from 'types/WorkLogTypes';
import { CalculateTotalHours, formatDate, getBusinessDaysInRange } from 'utils/date';

import { RootState } from '../../store';

import { Header } from './styles';
import { EditLeaveLogProps, LeavelogTypes } from './types';

const EditLeaveLog = ({
  leaveLog,
  close,
  refetch,
  setRecentlyEdited,
  showMessage,
  leaveCategories,
  updateApprovalList = () => {},
}: EditLeaveLogProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [formValues, setFormValues] = React.useState<LeavelogTypes>({
    ...leaveLog,
    leaveCategory: leaveLog.payroll_category,
    date: dayjs(leaveLog.date),
    startDate: leaveLog?.startDate ? dayjs(leaveLog?.startDate) : null,
    endDate: leaveLog?.endDate ? dayjs(leaveLog?.endDate) : null,
    startTime: dayjs(leaveLog?.start_time),
    endTime: dayjs(leaveLog?.end_time),
  });
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedEmployee } = useSelector((state: RootState) => state.employee);
  const [updateWorklog, { isLoading }] = useUpdateWorklogMutation();
  const isManagerView = location.pathname.startsWith('/employeeworklogs') || location.pathname.startsWith('/jobs');
  const isMultipleLeave = leaveLog?.startDate;
  const logOwner = isManagerView ? leaveLog.employee : user;

  const totalLoggedHours = React.useMemo(() => {
    if (isMultipleLeave) {
      const totalHrs = logOwner.payroll_details?.employment_status === 'FullTime' ? 8 : 4;

      return totalHrs;
    }
    return CalculateTotalHours(formValues?.startTime, formValues?.endTime, 0);
  }, [isMultipleLeave, logOwner, formValues?.endTime, formValues?.startTime]);

  const totalDays = React.useMemo(() => {
    if (isMultipleLeave && formValues?.startDate && formValues?.endDate) {
      const leaveDays = getBusinessDaysInRange(formValues?.startDate, formValues?.endDate);
      return leaveDays?.length;
    }

    return 0;
  }, [isMultipleLeave, formValues?.startDate, formValues?.endDate]);

  const handleAutoCompleteChange = (key: string, value: PayrollCategoryTypes | null) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleChange = (key: string, value: string | number) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const hasValidDate = () => {
    if (!dayjs(formValues.date).isValid()) {
      setError('Error: Invalid Date');

      return false;
    }

    const currentDate = dayjs();
    const selectedDate = dayjs(formValues.date).format('YYYY-MM-DD');

    const diff = currentDate.diff(selectedDate, 'month');

    if (diff >= 12 || diff <= -12) {
      setError('Error: Date should be within 12 months');
      return false;
    }

    return true;
  };

  const hasValidTime = () => {
    if (!dayjs(formValues.startTime).isValid() || !dayjs(formValues.endTime).isValid()) {
      setError('Error: Invalid Time!');
      return false;
    }

    if (!formValues.endTime?.isSameOrAfter(formValues.startTime)) {
      setError('Error: End time cannot be before start time !');
      return false;
    }
    return true;
  };

  const validateDate = () => {
    if (formValues?.startDate && formValues?.endDate) return true;

    if (!hasValidDate() || !hasValidTime()) return false;

    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateDate()) return;

    if (totalLoggedHours === 0) {
      setError('Error: No hours has been logged !');
      return;
    }

    if (formValues.leaveCategory === null) {
      setError('Error: Please select a payroll category !');
      return;
    }

    const updatedFormData = {
      activity_id: null,
      break_time: 0,
      client_id: null,
      // selected employee id to be used while creating log by manager for their employee
      employee_id: !isManagerView ? user?.user_id : selectedEmployee?.user_id,
      end_time: formatDate(formValues.endTime, formValues.date),
      job_id: null,
      mapped_category_id: formValues.leaveCategory?.mapped_category_id,
      slip_id: isMultipleLeave ? leaveLog.groupedIds : [leaveLog.slip_id],
      start_time: formatDate(formValues.startTime, formValues.date),
      worklog_date: formValues.date?.format('YYYY-MM-DD'),
      worklog_description: formValues.description,
      worklog_hours: totalLoggedHours,
      worklog_type: 'LeaveLog',
    };

    const result = await updateWorklog(updatedFormData);

    if ('error' in result) {
      setError('Error updating leavelog !');
    }

    if ('data' in result) {
      if (!isMultipleLeave) updateApprovalList([leaveLog.slip_id], result?.data?.data);
      refetch();
      close();
      if (setRecentlyEdited)
        setRecentlyEdited({ date: result.data.data.worklog_date, slip_id: result.data.data.slip_id, edited: false });
      showMessage({ type: 'success', msg: 'Leavelog updated' });
    }
  };

  const setDate = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, date: value });
  };

  const setStartTime = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, startTime: value });
  };

  const setEndTime = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, endTime: value });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        height: 'fit-content',
        width: '500px',
        position: 'sticky',
        top: theme.spacing(1),
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          maxWidth: '100vw',
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
        },
      }}>
      <Loader open={isLoading} />
      <Header>
        <Typography variant="h4"> Edit Leave </Typography>
        <CloseIcon
          onClick={() => close()}
          sx={{
            color: '#9E9E9E',
            ':hover': {
              color: theme.palette.primary.main,
              cursor: 'pointer',
            },
          }}
        />
      </Header>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2, gap: theme.spacing(1) }}>
        <Typography variant="body2" gutterBottom>
          LEAVE TYPE
        </Typography>
        <Autocomplete
          size="small"
          value={formValues.leaveCategory}
          options={leaveCategories}
          getOptionLabel={(option: PayrollCategoryTypes) => `${option?.category_name}`}
          isOptionEqualToValue={(option, value) => option.category_name === value.category_name}
          onChange={(event, value) => handleAutoCompleteChange('leaveCategory', value)}
          ListboxProps={{
            style: {
              fontSize: '12px',
            },
          }}
          popupIcon={<ExpandMoreIcon fontSize="small" />}
          renderInput={(params) => <TextField {...params} required />}
        />

        {!isMultipleLeave && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              DATE
            </Typography>
            <DatePicker allowMaxDate={true} onChange={setDate} value={formValues.date} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', mt: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="body2" gutterBottom>
                  START TIME
                </Typography>
                <TimePicker onChange={setStartTime} value={formValues.startTime} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="body2" gutterBottom>
                  END TIME
                </Typography>
                <TimePicker onChange={setEndTime} value={formValues.endTime} />
              </Box>
            </Box>
          </Box>
        )}
        {isMultipleLeave && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
                FROM
              </Typography>
              <DatePicker disabled value={formValues.startDate || null} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
                TO
              </Typography>
              <DatePicker disabled value={formValues.endDate || null} />
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              {!isMultipleLeave ? 'TOTAL HOURS' : 'HOURS PER DAY'}
            </Typography>
            <TextField
              size="small"
              type="number"
              value={totalLoggedHours}
              disabled
              sx={{ width: isMultipleLeave ? '100%' : '50%', backgroundColor: theme.palette.background.default }}
            />
          </Box>
          {isMultipleLeave && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
                TOTAL LEAVE DAYS
              </Typography>
              <TextField
                size="small"
                type="number"
                disabled
                value={totalDays}
                sx={{ width: '100%', backgroundColor: theme.palette.background.default }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
            DESCRIPTION
          </Typography>
          <TextField
            required
            rows={4}
            fullWidth
            multiline
            name="description"
            sx={{ height: '100px', mb: 2 }}
            inputProps={{ maxLength: 255 }}
            value={formValues.description}
            onChange={(e) => handleChange('description', e.target.value)}
            helperText={`${formValues.description.length}/${255}`}
            FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
          />
        </Box>
        {error && (
          <Typography
            variant="subtitle2_italic"
            gutterBottom
            sx={{
              color: 'red',
              mb: 1,
              width: '100%',
              textAlign: 'center',
            }}>
            {error}
          </Typography>
        )}
        <FilterButtons sx={{ marginLeft: 'auto', mt: 0 }}>
          <CustomButton type="submit" variant="contained" width="medium" text="Submit" />
        </FilterButtons>
      </Box>
    </Box>
  );
};

export default EditLeaveLog;
