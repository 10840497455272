import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  user: null | any;
  token: null | string;
  isAuthenticated: boolean;
}

export const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ user: any; access_token: string | null }>) => {
      state.user = action.payload.user;
      state.token = action.payload.access_token;
      state.isAuthenticated = true;
    },
    resetCredentials: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
      localStorage.removeItem('userPref');
    },
  },
});

export const { actions: authActions, reducer } = slice;

export default slice.reducer;
