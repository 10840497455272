// JobDetailStyles.tsx
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: 'none',
  borderRadius: '2px',
  mb: theme.spacing(1),
  pr: theme.spacing(1),
}));

export const DateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '45%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const DateRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: 'auto',
    marginTop: theme.spacing(0),
    gap: theme.spacing(2),
  },
}));

export const modalStyles = {
  display: 'flex',
  justifyContent: 'center',
  top: '5%',
  width: '100%',
  height: '90%',
  overflowY: 'auto',
};
export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const DocButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '132px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const SectionHeader = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const SortButton = styled(Button)(({ theme }) => ({
  width: '152px',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const WorklogsBox = styled(Box)(({ theme }) => ({
  flexGrow: 2,
  display: 'flex',
  maxWidth: '496px',
  flexDirection: 'column',
  height: '95vh',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    height: 'fit-content',
  },
  [theme.breakpoints.down('md')]: {
    width: '99% ',
    maxWidth: '100%',
  },
}));

export const WorklogFilter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  flexGrow: 2,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '180px',
  width: '496px',
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    justifyContent: 'space-between',
    width: '100%',
    maxHeight: 'none',
    height: 'fit-content',
  },
  [theme.breakpoints.down('md')]: {
    width: '100% ',
    maxWidth: '100%',
  },
}));

export const JobDetailContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxHeight: '95vh',
  marginBottom: theme.spacing(4),
  width: '608px',
  maxWidth: '608px',
  overflowY: 'auto',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    minHeight: '100dvh',
    height: 'fit-content',
    maxHeight: '',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: 'fit-content',
    minHeight: '95dvh',
    maxHeight: '',
    maxWidth: '100%',
  },
}));

export const JobContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '78px',
  marginTop: theme.spacing(1),
  width: '100%',
  gap: theme.spacing(3),
}));
