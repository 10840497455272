import * as React from 'react';
import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

import { DataTitle, IndexDetail, ProductivityButton } from './styles';

interface ProductivityDetailProps {
  month: string;
  startDate: string;
  endDate: string;
  totalHours: number;
  leaveHours: number;
  billableHours: number;
  productiveHours: number;
  unProductiveHours: number;
}

const ProductivityDetail = ({ data }: { data: ProductivityDetailProps }) => {
  const theme = useTheme();
  const [fadeIn, setFadeIn] = useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFadeIn(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Zoom in={fadeIn} timeout={500}>
        <IndexDetail>
          <DataTitle>
            <ProductivityButton variant="contained" sx={{ backgroundColor: theme.palette.primary.main }} />
            <Typography variant="subtitle2" sx={{ mr: 4 }}>
              TOTAL
            </Typography>
          </DataTitle>
          <Typography variant="h4">{data.totalHours}h</Typography>
        </IndexDetail>
      </Zoom>
      <Zoom in={fadeIn} style={{ transitionDelay: fadeIn ? '100ms' : '0ms' }} timeout={500}>
        <IndexDetail>
          <DataTitle>
            <ProductivityButton
              variant="contained"
              sx={{ backgroundColor: theme.palette.busmanColors.busmanPrimary }}
            />
            <Typography variant="subtitle2" sx={{ mr: 4 }}>
              BILLABLE
            </Typography>
          </DataTitle>
          <Typography variant="h4">{data.billableHours}h </Typography>
        </IndexDetail>
      </Zoom>
      <Zoom in={fadeIn} style={{ transitionDelay: fadeIn ? '200ms' : '0ms' }} timeout={500}>
        <IndexDetail>
          <DataTitle>
            <ProductivityButton variant="contained" sx={{ backgroundColor: theme.palette.busmanColors.busmanGreen }} />
            <Typography variant="subtitle2" sx={{ mr: 4 }}>
              PRODUCTIVE
            </Typography>
          </DataTitle>
          <Typography variant="h4">{data.productiveHours}h </Typography>
        </IndexDetail>
      </Zoom>
      <Zoom in={fadeIn} style={{ transitionDelay: fadeIn ? '300ms' : '0ms' }} timeout={500}>
        <IndexDetail>
          <DataTitle>
            <ProductivityButton variant="contained" sx={{ backgroundColor: theme.palette.busmanColors.busmanRed }} />
            <Typography variant="subtitle2" sx={{ mr: 4 }}>
              LEAVE
            </Typography>
          </DataTitle>
          <Typography variant="h4">{data.leaveHours}h</Typography>
        </IndexDetail>
      </Zoom>
    </Box>
  );
};

export default ProductivityDetail;
