const linearInterpolation = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

export const GenerateColorShades = (startColor: string, endColor: string, count: number, opacity: number) => {
  const startR = parseInt(startColor.slice(1, 3), 16);
  const startG = parseInt(startColor.slice(3, 5), 16);
  const startB = parseInt(startColor.slice(5, 7), 16);

  const endR = parseInt(endColor.slice(1, 3), 16);
  const endG = parseInt(endColor.slice(3, 5), 16);
  const endB = parseInt(endColor.slice(5, 7), 16);

  const colorArray = [];

  if (count === 1) {
    const color = `rgba(${startR}, ${startG}, ${startB}, ${opacity})`;
    colorArray.push(color);
    return colorArray;
  }

  for (let i = 0; i < count; i++) {
    const t = i / (count - 1);
    const r = Math.round(linearInterpolation(startR, endR, t));
    const g = Math.round(linearInterpolation(startG, endG, t));
    const b = Math.round(linearInterpolation(startB, endB, t));
    const color = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    colorArray.push(color);
  }

  return colorArray;
};

export const leaveColors = ['#FF6347', '#CD5C5C', '#9B111E', '#FF4500'];
