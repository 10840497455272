import * as React from 'react';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import CustomButton from 'components/Button';
import { FilterButtons, HeaderUnderline } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import PasswordTextField from 'components/PasswordTextField';
import SnackBar, { MessageType } from 'components/SnackBar';
import { useChangePasswordMutation } from 'services/busmanApi';

import { ChangePasswordContainer, TextFieldsBox } from './styles';

const LogIn = () => {
  const theme = useTheme();
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [message, setMessage] = React.useState<MessageType | null>(null);

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const resetAllStates = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    if (newPassword === currentPassword) {
      setPasswordError('New password cannot be same as old password');
      return;
    }

    // TODO: Add hashing password using bcryptjs
    // // Generate a salt
    // const saltRounds = 10;
    // const hashPassword = await bcrypt.hash(newPassword, salt);

    const params = {
      old_password: currentPassword,
      new_password: newPassword,
    };

    const result = await changePassword(params);

    if ('error' in result) setMessage({ type: 'error', msg: 'Error changing password' });

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'Password changed successfully' });
      resetAllStates();
    }
  };

  return (
    <ChangePasswordContainer>
      <Loader open={isLoading} />
      <TextFieldsBox>
        {!isMobileScreen && (
          <HeaderUnderline>
            <Typography component="h4" variant="h5">
              Change Password
            </Typography>
          </HeaderUnderline>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',

            width: '100%',
            gap: theme.spacing(2),
            padding: theme.spacing(2),
          }}>
          <Typography component="h4" variant="h6">
            Password change instructions
          </Typography>
          <Box>
            <Typography variant="body2" sx={{ color: '#9E9E9E' }}>
              ENTER CURRENT PASSWORD
            </Typography>
            <PasswordTextField
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ color: '#9E9E9E' }}>
              ENTER NEW PASSWORD
            </Typography>
            <PasswordTextField
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ color: '#9E9E9E' }}>
              CONFIRM NEW PASSWORD
            </Typography>
            <PasswordTextField
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Box>
          {passwordError && (
            <Typography variant="subtitle1" sx={{ color: 'red' }}>
              {passwordError}
            </Typography>
          )}
          <FilterButtons sx={{ marginLeft: 'auto', mt: 0 }}>
            <CustomButton type="submit" variant="contained" width="large" text="Save New Password" />
          </FilterButtons>
        </Box>
      </TextFieldsBox>
      <SnackBar message={message} onClose={() => setMessage(null)} />
    </ChangePasswordContainer>
  );
};

export default LogIn;
