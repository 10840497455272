import { busmanApiSlice } from './busmanApiSlice';
import { GetPayrollCategoryResponse } from './types';

export const payrollEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetches all Busman payroll categories
    getBusmanPayrollCategories: builder.query({
      query: () => 'payroll/busmancategories/',
      transformResponse: (response: GetPayrollCategoryResponse) => response.data,
      providesTags: ['Category'],
    }),

    // Update Busman payroll categories
    updateCategories: builder.mutation({
      query: (updatedCategories) => {
        return {
          url: 'payroll/busmancategories/',
          method: 'put',
          body: updatedCategories,
        };
      },
      invalidatesTags: ['Category'],
    }),

    // Update MYOB Leave Category
    updateLeaveCategories: builder.mutation({
      query: (updatedCategories) => {
        return {
          url: 'payroll/leavelogactivities/',
          method: 'post',
          body: updatedCategories,
        };
      },
      invalidatesTags: ['Category'],
    }),

    // Fetches all MYOB payroll categories
    getMYOBPayrollCategories: builder.query({
      query: () => 'payroll/categories/',
      transformResponse: (response: GetPayrollCategoryResponse) => response.data,
    }),
  }),
});

export const {
  useGetBusmanPayrollCategoriesQuery,
  useUpdateCategoriesMutation,
  useUpdateLeaveCategoriesMutation,
  useGetMYOBPayrollCategoriesQuery,
} = payrollEndpoints;
