import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterPanelProps {
  filterPanel: boolean;
}

const initialState: FilterPanelProps = {
  filterPanel: true,
};

const filterPanelSlice = createSlice({
  name: 'filterPanel',
  initialState,
  reducers: {
    setFilterPanelState: (state, action: PayloadAction<boolean>) => {
      state.filterPanel = action.payload;
    },
  },
});

export const { actions: filterPanelActions, reducer } = filterPanelSlice;

export default filterPanelSlice.reducer;
