import React from 'react';

import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DocDescription, DocHeader } from '../Styles/PDFstyles';

interface DocSummaryHeaderProps {
  breakdownType: string;
  invoicedDate: string;
  invoiceDesc: string;
  handleSetFormValues: (arg1: string, arg2: string) => void;
  gstCode?: string;
}

const DocSummaryHeader = ({
  breakdownType,
  invoicedDate,
  invoiceDesc,
  handleSetFormValues,
  gstCode,
}: DocSummaryHeaderProps) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          ...DocHeader,
          borderBottom: `solid 1px ${theme.palette.background.default}`,
          paddingBottom: theme.spacing(2),
        }}>
        <Box>
          <Typography variant="h4" textAlign={'left'}>
            {breakdownType === 'summary' ? 'Summary Documentation' : 'Detailed Documentation'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" textAlign={'right'}>
            TAX STATUS
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} textAlign={'right'}>
            {gstCode ? gstCode : 'NO GST'}
          </Typography>
        </Box>
      </Box>
      <Box sx={DocDescription}>
        <TextField
          size="small"
          InputProps={{
            multiline: true,
          }}
          variant="outlined"
          value={invoicedDate}
          onChange={(event) => handleSetFormValues('invoicedDate', event.target.value)}
          sx={{
            '& fieldset': { border: `1px solid ${theme.palette.background.default}` },
          }}
          inputProps={{
            style: {
              minWidth: '180px',
              fontSize: '10px',
            },
          }}
        />
        <TextField
          size="small"
          InputProps={{
            multiline: true,
          }}
          variant="outlined"
          value={invoiceDesc}
          onChange={(event) => handleSetFormValues('invoiceDesc', event.target.value)}
          sx={{
            width: '100%',
            '& fieldset': { border: `1px solid ${theme.palette.background.default}` },
          }}
          inputProps={{
            style: {
              fontSize: '10px',
            },
          }}
        />
      </Box>
    </>
  );
};

export default DocSummaryHeader;
