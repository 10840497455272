import { Member, Team } from 'services/busmanApi/types';

const getAllTeamMembers = (team: Team) => {
  const uniqueMembers = new Map();

  const processMembers = (members: Member[]) => {
    members.forEach((member: Member) => {
      const memberId = member.member_id;
      if (!uniqueMembers.has(memberId) && !member.is_team) {
        uniqueMembers.set(memberId, member);
      }
      if (member.is_team && member.team.members) {
        processMembers(member.team.members);
      }
    });
  };

  processMembers(team.members);
  return uniqueMembers;
};

export { getAllTeamMembers };
