import { Box, Grid, styled } from '@mui/material';

export const NotificationsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  height: '95vh',
  maxWidth: '928px',
  overflowY: 'scroll',
  padding: theme.spacing(2),
}));

export const NotificationBanner = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  alignItems: 'center',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid',

  borderColor: theme.palette.background.default,
  cursor: 'pointer',
  width: '100%',
  minHeight: '64px',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));
