import { AppBar, Box, ListItemButton, styled } from '@mui/material';
import MuiDivider from '@mui/material/Divider';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  color: theme.palette.primary.light,
  border: '1px solid',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const IconListBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
}));

export const MobAppBar = styled(AppBar)(() => ({
  display: 'flex',
  position: 'fixed',
  flexDirection: 'row',
  height: '6vh',
  width: '100vw',
  boxShadow: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  top: 0,
  left: 0,
  paddingRight: '8px',
  zIndex: 1300,
  '@media print': {
    display: 'none',
  },
}));

export const ListItemBtn = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  backgroundColor: 'transparent',
  width: '100%',
  paddingInline: theme.spacing(3),
  color: theme.palette.primary.light,
  '&:hover': {
    color: theme.palette.primary.contrastText,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
  },
  minHeight: 48,
  justifyContent: 'left',
}));

export const MenuContent = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'space-between',
}));
