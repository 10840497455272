import * as React from 'react';

import { Alert as MuiAlert, Snackbar } from '@mui/material';

export interface MessageType {
  type: string;
  msg: string;
}

interface SnackBarComponentProps {
  message: MessageType | null;
  onClose: () => void;
  snackbarOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

const SnackBarComponent = ({
  message,
  onClose,
  snackbarOrigin = { vertical: 'bottom', horizontal: 'left' },
}: SnackBarComponentProps) => {
  return (
    <Snackbar anchorOrigin={snackbarOrigin} open={!!message?.msg} autoHideDuration={3000} onClose={onClose}>
      {message && message.type === 'error' ? (
        <MuiAlert severity="error" sx={{ width: '100%' }}>
          {message.msg}
        </MuiAlert>
      ) : (
        <MuiAlert severity="success" sx={{ width: '100%' }}>
          {message?.msg}
        </MuiAlert>
      )}
    </Snackbar>
  );
};

export default SnackBarComponent;
