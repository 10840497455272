import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import { FilterButtons } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import TimePicker from 'components/TimePicker';
import { useAddWorklogMutation } from 'services/busmanApi';
import { PayrollCategoryTypes } from 'types/WorkLogTypes';
import { CalculateTotalHours, formatDate, getBusinessDaysInRange } from 'utils/date';

import { RootState } from '../../store';

import { ToggleButton, ToggleButtonPanel } from './styles';
import { LeavelogTypes, NewLeaveLogProps } from './types';

const NewLeaveLog = ({
  close,
  refetch,
  setRecentlyEdited,
  showMessage,
  leaveCategories,
  preDefinedData,
}: NewLeaveLogProps) => {
  dayjs.extend(isSameOrAfter);
  const theme = useTheme();
  const location = useLocation();
  const [alignment, setAlignment] = React.useState('Hours');
  const [leaveDateList, setLeaveDateList] = React.useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [formValues, setFormValues] = React.useState<LeavelogTypes>({
    leaveCategory: leaveCategories[0],
    date: dayjs(),
    startDate: preDefinedData?.date ? preDefinedData.date : dayjs(),
    endDate: preDefinedData?.date ? preDefinedData.date.add(1, 'day') : dayjs().add(1, 'day'),
    startTime: dayjs(dayjs().set('hour', 8).format('YYYY-MM-DD HH')),
    endTime: dayjs(dayjs().set('hour', 16).format('YYYY-MM-DD HH')),
    description: '',
    ...preDefinedData,
  });
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedEmployee } = useSelector((state: RootState) => state.employee);
  const [addWorklog, { isLoading }] = useAddWorklogMutation();
  const isManagerView = location.pathname.startsWith('/employeeworklogs');
  const logOwner = isManagerView ? selectedEmployee : user;

  const totalLoggedHours = React.useMemo(() => {
    if (alignment === 'Days') {
      const totalHrs = logOwner.payroll_details?.employment_status === 'FullTime' ? 8 : 4;
      return totalHrs;
    }
    return CalculateTotalHours(formValues?.startTime, formValues?.endTime, 0);
  }, [alignment, logOwner, formValues?.endTime, formValues?.startTime]);

  const appliedLeaveDays = React.useMemo(() => {
    if (alignment === 'Days' && formValues?.endDate) {
      const totalLeaves = getBusinessDaysInRange(formValues?.startDate, formValues?.endDate);
      setLeaveDateList(totalLeaves);
      return totalLeaves?.length;
    }
    setLeaveDateList(formValues.date ? [formValues.date?.format('YYYY-MM-DD')] : []);
    return 0;
  }, [alignment, formValues.date, formValues?.startDate, formValues?.endDate]);

  const handleAutoCompleteChange = (key: string, value: PayrollCategoryTypes | null) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleChange = (key: string, value: string | number) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleChangeAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }

    if (error) setError(null);
  };

  const hasValidDate = () => {
    if (!dayjs(formValues.date).isValid()) {
      setError('Error: Invalid Date');

      return false;
    }

    const currentDate = dayjs();
    const selectedDate = dayjs(formValues.date).format('YYYY-MM-DD');

    const diff = currentDate.diff(selectedDate, 'month');

    if (diff >= 12 || diff <= -12) {
      setError('Error: Date should be within 12 months');
      return false;
    }

    return true;
  };

  const hasValidTime = () => {
    if (!dayjs(formValues.startTime).isValid() || !dayjs(formValues.endTime).isValid()) {
      setError('Error: Invalid Time!');
      return false;
    }

    if (!formValues.endTime?.isSameOrAfter(formValues.startTime)) {
      setError('Error: End time cannot be before start time !');
      return false;
    }
    return true;
  };

  const validateDate = () => {
    if (alignment === 'Days') return true;

    if (!hasValidDate() || !hasValidTime()) return false;

    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateDate()) return;

    if (totalLoggedHours === 0) {
      setError('Error: No hours has been logged !');
      return;
    }

    if (formValues.leaveCategory === null) {
      setError('Error: Please select a payroll category !');
      return;
    }

    const newLogData = {
      activity_id: null,
      break_time: 0,
      client_id: null,
      // selected employee id to be used while creating log by manager for their employee
      employee_id: !isManagerView ? user?.user_id : selectedEmployee?.user_id,
      end_time: formatDate(formValues.endTime, alignment === 'Hours' ? formValues.date : formValues.startDate),
      job_id: null,
      mapped_category_id: formValues.leaveCategory?.mapped_category_id,
      start_time: formatDate(formValues.startTime, alignment === 'Hours' ? formValues.date : formValues.startDate),
      worklog_date: leaveDateList,
      worklog_description: formValues.description,
      worklog_hours: totalLoggedHours,
      worklog_type: 'LeaveLog',
    };

    const result = await addWorklog(newLogData);

    if ('error' in result) {
      setError('Error adding Leavelog!');
    }

    if ('data' in result) {
      refetch();
      close();
      if (setRecentlyEdited)
        setRecentlyEdited({ date: result.data.data.worklog_date, slip_id: result.data.data.slip_id, edited: false });
      showMessage({ type: 'success', msg: 'New Leavelog added' });
    }
  };

  const setStartTime = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, startTime: value });
  };

  const setEndTime = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, endTime: value });
  };

  const setDate = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, date: value });
  };

  const setStartDate = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, startDate: value });
  };

  const setEndDate = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, endDate: value });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
      <Loader open={isLoading} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <ToggleButtonPanel value={alignment} exclusive onChange={handleChangeAlignment}>
          <ToggleButton value="Hours">
            <Typography sx={{ fontSize: '11px' }} variant="body2">
              Hours
            </Typography>
          </ToggleButton>
          <ToggleButton value="Days">
            <Typography sx={{ fontSize: '11px' }} variant="body2">
              Days
            </Typography>
          </ToggleButton>
        </ToggleButtonPanel>
        <Typography variant="body2" gutterBottom>
          LEAVE TYPE
        </Typography>
        <Autocomplete
          size="small"
          value={formValues.leaveCategory}
          options={leaveCategories}
          getOptionLabel={(option: PayrollCategoryTypes) => `${option?.category_name}`}
          isOptionEqualToValue={(option, value) => option.category_name === value.category_name}
          onChange={(event, value) => handleAutoCompleteChange('leaveCategory', value)}
          ListboxProps={{
            style: {
              fontSize: '12px',
            },
          }}
          popupIcon={<ExpandMoreIcon fontSize="small" />}
          renderInput={(params) => <TextField {...params} required />}
        />
      </Box>
      {alignment === 'Hours' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
            DATE
          </Typography>
          <DatePicker allowMaxDate={true} onChange={setDate} value={formValues.date} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', mt: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2" gutterBottom>
                START TIME
              </Typography>
              <TimePicker onChange={setStartTime} value={formValues.startTime} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography variant="body2" gutterBottom>
                END TIME
              </Typography>
              <TimePicker onChange={setEndTime} value={formValues.endTime} />
            </Box>
          </Box>
        </Box>
      )}
      {/* bulk leave */}
      {alignment === 'Days' && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              FROM
            </Typography>
            <DatePicker allowMaxDate={true} onChange={setStartDate} value={formValues.startDate} />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              TO
            </Typography>
            <DatePicker
              allowMaxDate={true}
              minDate={formValues.startDate?.add(1, 'day')}
              onChange={setEndDate}
              value={formValues.endDate}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
            {alignment === 'Hours' ? 'TOTAL HOURS' : 'HOURS PER DAY'}
          </Typography>
          <TextField
            size="small"
            type="number"
            disabled
            value={totalLoggedHours}
            sx={{ width: alignment === 'Hours' ? '50%' : '100%', backgroundColor: theme.palette.background.default }}
          />
        </Box>

        {alignment === 'Days' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              TOTAL LEAVE DAYS
            </Typography>
            <TextField
              size="small"
              type="number"
              value={appliedLeaveDays}
              disabled
              sx={{ backgroundColor: theme.palette.background.default }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          DESCRIPTION
        </Typography>
        <TextField
          required
          rows={4}
          fullWidth
          multiline
          name="description"
          sx={{ height: '100px', mb: 2 }}
          value={formValues.description}
          inputProps={{ maxLength: 255 }}
          onChange={(e) => handleChange('description', e.target.value)}
          helperText={`${formValues.description.length}/${255}`}
          FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
        />
      </Box>
      {error && (
        <Typography
          variant="subtitle2_italic"
          gutterBottom
          sx={{
            color: 'red',
            mb: 1,
            width: '100%',
            textAlign: 'center',
          }}>
          {error}
        </Typography>
      )}
      <FilterButtons sx={{ marginLeft: 'auto', mt: 0 }}>
        <CustomButton type="submit" variant="contained" width="medium" text="Submit" />
      </FilterButtons>
    </Box>
  );
};

export default NewLeaveLog;
