import { Box, styled } from '@mui/material';

export const DashboardSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: 'auto',
  width: '496px',
  marginBottom: theme.spacing(2),
  borderRadius: '2px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const ProductivityBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.background.default}`,
  height: '192px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
}));

export const DoughnutChartContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '158px',
  height: '158px',
}));

export const LeaveStat = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '64px',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.background.default}`,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
