import * as React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider as OriginalThemeProvider } from '@mui/material/styles';

import { selectTheme } from './slice/selectors';

export default function ThemeProvider(props: { children: React.ReactNode }) {
  const theme = useSelector(selectTheme);
  return <OriginalThemeProvider theme={theme}>{React.Children.only(props.children)}</OriginalThemeProvider>;
}
