import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import {
  ArrowForwardIosSharp,
  Clear as ClearIcon,
  ManageAccounts as ManageAccountsIcon,
  Search as SearchIcon,
  SwapVert as SwapVertIcon,
} from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  PaletteColor,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import {
  FilterButtons,
  FilterPanelContainer,
  HeaderUnderline,
  NoDataBox,
  PageContainer,
  WarningBanner,
} from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import { employeeActions } from 'features/Employee/EmployeeSlice';
import { useGetTeamsQuery, useGetTeamWorklogCountQuery } from 'services/busmanApi';
import { Employee, EmployeeCountDetail, Member, Team, TeamCount } from 'services/busmanApi/types';
import { getPreferncesFromLocalStorage, setPreferencesInLocalStorage } from 'utils/localStorage';

import { RootState } from '../../store';

import { PendingEmployeesBox } from './styles';

interface TeamState {
  [team_name: string]: boolean;
}

const WORKLOG_STATUS = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  SYNCED: 'Synced',
};

function WorklogManagerPage() {
  const theme = useTheme();
  const initialOpenTeams: TeamState = getPreferncesFromLocalStorage('openTeam');
  const [sortAscending, setSortAscending] = React.useState(true);
  const [invalidDate, setInvalideDate] = React.useState(false);
  const [openTeam, setOpenTeam] = React.useState<TeamState>(initialOpenTeams || {});
  const [searchText, setSearchText] = React.useState('');
  const [view, setView] = React.useState('All');
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const { user } = useSelector((state: RootState) => state.auth);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md')) && !isMobileScreen;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = {
    startDate: fromDate?.format('YYYY-MM-DD'),
    endDate: toDate?.format('YYYY-MM-DD'),
  };

  const { data: teams, isFetching } = useGetTeamsQuery(user?.user_id);
  const { data: teamWorklogCount, isFetching: isTeamWorklogCountFetching } = useGetTeamWorklogCountQuery(params, {
    skip: invalidDate,
  });

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, employee: Employee) => {
    event.stopPropagation();
    dispatch(employeeActions.setSelectedEmployee(employee));
    navigate(`/UserDetail/${employee.user_id}`);
  };

  const handleSelectEmployee = (event: React.MouseEvent<HTMLDivElement>, employee: Employee) => {
    dispatch(employeeActions.setSelectedEmployee(employee));
    navigate(`/employeeworklogs/${employee.user_id}`);
  };

  const checkSearchedName = (team: Team): boolean => {
    // Check if the name exists in the direct team members
    const isDirectEmployee = team.members.some((member: Member) => {
      return !member.is_team && member.employee?.full_name?.toLowerCase().includes(searchText.toLowerCase());
    });

    if (isDirectEmployee) {
      return true;
    }

    // Search for the name in each sub-team
    const isFoundInSubTeams = team.members
      .filter((member) => member.is_team && member.team)
      .some((subTeamMember) => checkSearchedName(subTeamMember.team));

    return isFoundInSubTeams;
  };

  const renderStatusChip = (count: number, status: string, team = true) => {
    const chipColor = () => {
      switch (status) {
        case WORKLOG_STATUS.APPROVED:
          return theme.palette.busmanColors.busmanGreen as PaletteColor['main'];
        case WORKLOG_STATUS.PENDING:
          return theme.palette.busmanColors.busmanPrimary as PaletteColor['main'];
        case WORKLOG_STATUS.SYNCED:
          return theme.palette.busmanColors.lightGrey as PaletteColor['main'];
        default:
          return theme.palette.busmanColors.darkGrey as PaletteColor['main'];
      }
    };

    if (count === 0 && team) return null;

    return (
      <Chip
        size="small"
        label={team && !isMobileScreen ? status : ''}
        variant="outlined"
        sx={{
          color: chipColor(),
          border: team && !isMobileScreen ? `1px solid ${chipColor()}` : 'none',
          padding: theme.spacing(1),
          '.MuiChip-avatar': { width: 'auto' },
          '.MuiChip-label': { pr: 0 },
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.5rem',
          },
        }}
        avatar={
          <Avatar sx={{ bgcolor: 'transparent' }}>
            <Typography color={chipColor()} variant="h6">
              {count}
            </Typography>
          </Avatar>
        }
      />
    );
  };

  const renderEmployee = (employee: Employee, teamName: string) => {
    const hasSearchText = employee.full_name?.toLowerCase().includes(searchText.toLowerCase());

    const employeeCounts = teamWorklogCount?.teams
      .find((a: TeamCount) => a?.team_name === teamName)
      ?.member_count.find((member: EmployeeCountDetail) => member?.employee_id === employee.user_id);

    return hasSearchText ? (
      <Box
        key={employee.user_id}
        id={employee.user_id}
        sx={{
          cursor: 'pointer',
          borderRadius: '2px',
          border: 1,
          borderColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: theme.spacing(1),
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        }}
        onClick={(e) => handleSelectEmployee(e, employee)}>
        <Typography variant="h6">{employee.full_name}</Typography>
        <Box sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
          <Stack display={'flex'} direction="row" alignItems="center" spacing={1} justifySelf="right">
            {renderStatusChip(employeeCounts?.pending || 0, WORKLOG_STATUS.PENDING, false)}
            {renderStatusChip(employeeCounts?.approved || 0, WORKLOG_STATUS.APPROVED, false)}
            {renderStatusChip(employeeCounts?.synced || 0, WORKLOG_STATUS.SYNCED, false)}
          </Stack>
          <Tooltip title="Edit profile">
            <IconButton
              sx={{
                padding: 0,
                ':hover': {
                  backgroundColor: 'transparant',
                },
              }}
              onClick={(e) => handleMenuClick(e, employee)}>
              <ManageAccountsIcon />
            </IconButton>
          </Tooltip>{' '}
        </Box>
      </Box>
    ) : null;
  };

  const handleFromDateChange = (date: Dayjs | null) => {
    if (dayjs(date).isAfter(toDate)) setInvalideDate(true);
    if (!dayjs(date).isAfter(toDate) && invalidDate) setInvalideDate(false);

    setFromDate(date);
  };

  const handleToDateChange = (date: Dayjs | null) => {
    if (dayjs(fromDate).isAfter(date)) setInvalideDate(true);
    if (!dayjs(fromDate).isAfter(date) && invalidDate) setInvalideDate(false);

    setToDate(date);
  };

  const calculateTeamTotals = (team: Team) => {
    let pendingCount = 0;
    let approvedCount = 0;
    let syncedCount = 0;

    if (!team.members || team.members.length === 0) return { pending: 0, approved: 0, synced: 0 };

    team.members.forEach((member: Member) => {
      if (member.is_team && member.team) {
        const subTeamCounts = calculateTeamTotals(member.team);
        pendingCount += subTeamCounts.pending;
        approvedCount += subTeamCounts.approved;
        syncedCount += subTeamCounts.synced;
      } else {
        const employeeCounts = teamWorklogCount?.teams
          .find((a: TeamCount) => a?.team_name === team.team_name)
          ?.member_count.find(
            (memberCount: EmployeeCountDetail) => memberCount?.employee_id === member.employee.user_id,
          );
        if (employeeCounts) {
          pendingCount += employeeCounts.pending;
          approvedCount += employeeCounts.approved;
          syncedCount += employeeCounts.synced;
        }
      }
    });

    return { pending: pendingCount, approved: approvedCount, synced: syncedCount };
  };

  const trackSearchInTeams = [];
  const renderTeam = (team: Team, isSubTeam = false) => {
    const sortedTeams =
      team.members &&
      [...team.members].sort((a: Member, b: Member) => {
        const nameA = a.is_team ? a.team.team_name : a.employee.full_name || '';
        const nameB = b.is_team ? b.team.team_name : b.employee.full_name || '';

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });

    if (searchText && !checkSearchedName(team)) {
      return null;
    }

    trackSearchInTeams.push(team.team_name);

    const selectedTeamCounts = calculateTeamTotals(team);

    return (
      <Accordion
        disableGutters
        elevation={0}
        expanded={searchText ? !!searchText : openTeam[team.team_name]}
        square
        key={team.team_name}
        onClick={(event) => {
          event.stopPropagation();
          setOpenTeam({ ...openTeam, [team.team_name]: !openTeam[team.team_name] });
        }}
        sx={{
          borderBottom: 'none',
          marginBottom: isSubTeam ? theme.spacing(1) : 0,
        }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
          sx={{
            display: 'flex',
            backgroundColor: isSubTeam ? theme.palette.background.default : theme.palette.background.paper,
            paddingInline: theme.spacing(2),
            borderRadius: '2px',
            borderBottom: isSubTeam ? 0 : 1,
            borderColor: theme.palette.background.default,
            maxHeight: '40px',
            position: 'sticky',
            top: '0',
            zIndex: '999',
            '&.Mui-expanded': {
              maxHeight: '40px',
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingRight: theme.spacing(2),
            },
          }}>
          <Typography variant="h4" pt={1} pb={1} mr={1}>
            {team.team_name}
          </Typography>
          {teamWorklogCount && selectedTeamCounts && !openTeam[team.team_name] && (
            <Stack display={'flex'} direction="row" alignItems="center" spacing={1} justifySelf="right">
              {renderStatusChip(selectedTeamCounts.pending, WORKLOG_STATUS.PENDING)}
              {renderStatusChip(selectedTeamCounts.approved, WORKLOG_STATUS.APPROVED)}
              {renderStatusChip(selectedTeamCounts.synced, WORKLOG_STATUS.SYNCED)}
            </Stack>
          )}
        </AccordionSummary>
        {!team?.members?.length ? (
          <AccordionDetails sx={{ paddingInline: theme.spacing(2) }}>
            <Typography variant="h6">No Members</Typography>
          </AccordionDetails>
        ) : (
          <AccordionDetails sx={{ paddingInline: isSubTeam ? 0 : theme.spacing(2) }}>
            {sortedTeams.map((member: Member) => {
              if (member.is_team) {
                return renderTeam(member.team, true);
              }

              return renderEmployee(member.employee, team.team_name);
            })}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  let sortedTeams: Team[] = [];
  if (teams && teams.length) {
    sortedTeams = [...teams];
    sortedTeams.sort((a, b) => a.team_name.localeCompare(b.team_name));
    sortedTeams = sortedTeams.map((team: Team) => {
      if (team.members.length) {
        const filteredTeams = team.members.filter((member: Member) => member.is_team);

        if (filteredTeams.length) {
          return {
            ...team,
            members: [
              ...team.members.filter((member: Member) => !member.is_team),
              ...filteredTeams.sort((a, b) => a.team.team_name.localeCompare(b.team.team_name)),
            ],
          };
        }
      }
      return team;
    });

    if (!sortAscending) {
      sortedTeams = sortedTeams.reverse();
    }
  }

  const WorklogWarning = () => {
    const handleClick = () => {
      setFromDate(dayjs(teamWorklogCount?.pending_worklog_date));
    };

    return (
      <>
        {!isTeamWorklogCountFetching && (
          <Tooltip title="Adjust date range">
            <WarningBanner onClick={handleClick}>
              {!isTabletScreen && <WarningAmberIcon />}
              <Typography
                variant="body1"
                sx={{ color: 'inherit', maxHeight: '48px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                Selected date range is missing pending worklogs -{' '}
                {dayjs(teamWorklogCount?.pending_worklog_date).format('DD/MM/YYYY')}
              </Typography>
              {!isTabletScreen && (
                <Typography variant="body1" sx={{ color: 'inherit', fontWeight: 'bold' }}>
                  Adjust
                </Typography>
              )}
            </WarningBanner>
          </Tooltip>
        )}
      </>
    );
  };

  // Save the open teams accordion state to localStorage
  React.useEffect(() => {
    if (openTeam && Object.keys(openTeam).length) {
      setPreferencesInLocalStorage('openTeam', openTeam);
    }
  }, [openTeam]);

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busman" content="EEA-Worklogmanager" />
      </Helmet>
      <Loader open={isFetching || isTeamWorklogCountFetching} />
      <PageContainer>
        {filterPanel && (
          <FilterPanelContainer>
            <FormControl>
              {!isMobileScreen && (
                <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
                  Worklog Manager
                </Typography>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <FormControl>
                  <OutlinedInput
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search Employee"
                    endAdornment={
                      <InputAdornment position="end">
                        {searchText ? (
                          <IconButton onClick={handleClearSearch} edge="end">
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    }
                    sx={{
                      height: '32px',
                      mb: theme.spacing(4),
                    }}
                  />
                </FormControl>

                <Typography variant="body2" gutterBottom>
                  VIEW
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={view}
                  sx={{ height: '32px' }}
                  onChange={(newSelection) => {
                    setView(newSelection.target.value);
                  }}>
                  <MenuItem key="all-teams" value="All">
                    All
                  </MenuItem>
                  {sortedTeams &&
                    sortedTeams.map((team: Team) => {
                      return (
                        <MenuItem key={team.team_name} value={team.team_name}>
                          {team.team_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: theme.spacing(4),
                  }}>
                  <Typography variant="body2" gutterBottom>
                    FROM
                  </Typography>
                  <DatePicker onChange={handleFromDateChange} value={fromDate} minDate={dayjs().subtract(3, 'month')} />
                  <Typography variant="body2" gutterBottom mt={1}>
                    TO
                  </Typography>
                  <DatePicker minDate={dayjs().subtract(3, 'month')} onChange={handleToDateChange} value={toDate} />
                </Box>
              </Box>
              <FilterButtons sx={{ marginLeft: 'auto', mb: theme.spacing(3) }}>
                <CustomButton
                  disabled={!teams || teams.length < 2 || view !== 'All'}
                  onClick={() => setSortAscending(!sortAscending)}
                  text={sortAscending ? 'Descending' : 'Ascending'}
                  variant="contained_grey"
                  endIcon={<SwapVertIcon />}
                  width="medium"
                />
              </FilterButtons>
            </FormControl>
            {invalidDate && (
              <Typography
                gutterBottom
                sx={{
                  color: theme.palette.busmanColors.busmanRed,
                }}
                variant={'body2'}>
                Invalid date. From date cannot be after To date.
              </Typography>
            )}
            {!invalidDate && !isFetching && teamWorklogCount?.pending_worklog_date && <WorklogWarning />}
          </FilterPanelContainer>
        )}
        {sortedTeams && !isFetching && sortedTeams.length === 0 ? (
          <NoDataBox>
            <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
              No teams...
            </Typography>
          </NoDataBox>
        ) : (
          <PendingEmployeesBox sx={{ overflow: 'auto' }}>
            <HeaderUnderline>
              <Typography variant="h4">Teams</Typography>
            </HeaderUnderline>
            <>
              <Box display="flex" flexDirection="column">
                {sortedTeams?.map((team: Team) => {
                  return (view === 'All' || view === team.team_name) && renderTeam(team);
                })}
              </Box>
              {searchText && trackSearchInTeams.length === 0 && (
                <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
                  No results found...
                </Typography>
              )}
            </>
          </PendingEmployeesBox>
        )}
      </PageContainer>
    </>
  );
}

export default WorklogManagerPage;
