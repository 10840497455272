import { AccordionSummary, styled } from '@mui/material';
import { Box } from '@mui/material';

export const NewCommentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginTop: theme.spacing(1),
  gap: theme.spacing(1),
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
}));
