/* 
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

import { WorklogType } from 'types/WorkLogTypes';
import { GenerateColorShades, leaveColors } from 'utils/color';

import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

import { GanttChartContainer } from './styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels, TimeScale);

// Tooltip Pointer Config
Tooltip.positioners.average = (elements: any, eventPosition: { x: number; y: number }) => {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
  };
};

const groupBy = (items: any[], key: string | number) =>
  items.reduce(
    (result: { [x: string]: any }, item: { [x: string]: string | number }) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

interface GanttChartProps {
  worklogData: {
    totalHours: number;
    worklogs: WorklogType[];
    worklogDate: string;
  };
}

const GanttChart = ({ worklogData }: GanttChartProps) => {
  const [yAxis, setyAxis] = useState(true);

  const { worklogs } = worklogData;
  const labels = [...new Set(worklogs.map((worklog: WorklogType) => worklog?.date))];
  const uniqueActivities = [...new Set(worklogs.map((worklog: WorklogType) => worklog?.activity?.activity_name))];
  const nonLeaveColors = GenerateColorShades('#9531FF', '#5CB85C', uniqueActivities.length, 0.7);
  const nonLeaveColorsOpaque = GenerateColorShades('#9531FF', '#5CB85C', uniqueActivities.length, 1);

  const worklogsInfo = worklogs.map((item: WorklogType) => {
    const activityIndex = uniqueActivities.indexOf(item?.activity?.activity_name);
    const worklogColor =
      item.worklog_type === 'WorkLog'
        ? yAxis
          ? nonLeaveColors[activityIndex]
          : nonLeaveColorsOpaque[activityIndex]
        : leaveColors[activityIndex];
    if (item.job) {
      return {
        job: item?.job,
        jobId: item.job?.job_id,
        jobName: item.job?.job_name,
        colorTag: worklogColor,
      };
    }

    // Use Activity Id as job_id
    return {
      job: item?.activity,
      jobId: item?.activity?.activity_id,
      jobName: item?.activity?.activity_name,
      colorTag: worklogColor,
    };
  });

  const duplicateWorklogs = groupBy(
    worklogsInfo.filter((worklog: any) => worklog !== undefined),
    'jobId',
  );

  const dayStartTime = worklogs
    .map((worklog: WorklogType) => new Date(worklog.start_time))
    .reduce((earliest: Date, current: Date) => {
      return current < earliest ? current : earliest;
    })
    .getTime();

  const dayEndTime = worklogs
    .map((worklog: WorklogType) => new Date(worklog.end_time))
    .reduce((latest: Date, current: Date) => {
      return current > latest ? current : latest;
    })
    .getTime();

  const dayTotalHours = (dayEndTime - dayStartTime) / 1000 / 3600;

  // const intervalBreakdownValue =
  //   dayTotalHours > 0 && dayTotalHours < 3.5
  //     ? 15 * 60 * 1000
  //     : dayTotalHours > 3.5 && dayTotalHours <= 7
  //     ? 30 * 60 * 1000
  //     : 60 * 60 * 1000;

  const STEP_SIZE = dayTotalHours > 0 && dayTotalHours < 3.5 ? 15 : dayTotalHours > 3.5 && dayTotalHours <= 7 ? 30 : 1;

  // const dayIntervalCount = Math.ceil((dayEndTime - dayStartTime) / intervalBreakdownValue);
  // const dayIntervalArray = Array.from(
  //   { length: dayIntervalCount + 1 },
  //   (_, i) => new Date(dayStartTime + i * intervalBreakdownValue),
  // );

  // const maxLength = dayIntervalArray.length;

  const savedTime: string[][] = [];

  const datasets = worklogs.map((worklog: WorklogType, index: number) => {
    const data: Array<Array<string>> = [];
    const jobId = worklog?.job?.job_id;
    const clientJob = worklog?.activity?.activity_name;
    const jobDate = worklog?.date;
    const jobNumber =
      worklog?.worklog_type === 'WorkLog' ? worklog?.job?.job_number : worklog?.payroll_category.category_name;

    const timeStart = worklog.start_time;
    const timeEnd = worklog.end_time;

    data[labels.indexOf(jobDate)] = [
      timeStart,
      timeEnd,
      `${dayjs(timeStart).format('h:mm')} - ${dayjs(timeEnd).format('h:mm')}`,
      clientJob,
    ];

    savedTime.push([timeStart, timeEnd]);

    let color;
    if (worklogsInfo !== undefined) {
      if (duplicateWorklogs[jobId]?.length > 1) {
        color = duplicateWorklogs[jobId][0]?.colorTag;
      } else {
        color = worklogsInfo[index]?.colorTag;
      }
    }

    return {
      label: jobNumber,
      data: data,
      backgroundColor: color,
      hoverBackgroundColor: color,
      datalabels: {
        formatter: () => clientJob,
      },
      toolTip: clientJob,
      borderRadius: {
        topLeft: 3,
        topRight: 3,
        bottomLeft: 3,
        bottomRight: 3,
      },
      borderSkipped: false,
      maxBarThickness: 30,
    };
  });

  const data = {
    labels,
    datasets: datasets,
  };

  //ONCLICK HANDLER FOR CHART LEGEND LABELS
  // const handleLegendClick = (e: any, legendItem: any, legend: any) => {
  //   const ci = legend.chart;
  //   const duplicateChartJobs = datasets
  //     .filter((dataset) => dataset.label === legendItem.text)
  //     .map((item) => datasets.indexOf(item));
  //   const jobName = legendItem.text;

  // datasets.forEach((dataset, index) => {
  //   if (dataset.label === jobName) {
  //     if (ci.isDatasetVisible(index)) {
  //       ci.hide(index);
  //       legendItem.hidden = true;
  //     } else {
  //       ci.show(index);
  //       legendItem.hidden = false;
  //     }
  //   }
  // });
  // };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    plugins: {
      tooltip: {
        callbacks: {
          title: (items) => data.datasets[items[0].datasetIndex].data[0][3],
          afterBody: (items) => data.datasets[items[0].datasetIndex].data[items[0].dataIndex][2],
          label: (item) => item.dataset.label,
        },
      },
      legend: {
        display: true,
        position: 'top' as const,
        align: 'start',
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          useBorderRadius: true,
          filter: (legendItem, chartData) => {
            const map = chartData.datasets.map((dataset) => dataset.label);
            return legendItem.datasetIndex === map.indexOf(legendItem.text);
          },
        },
        // onClick: handleLegendClick,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: true,
        },
        min: dayStartTime,
        max: dayEndTime,
        ticks: {
          maxTicksLimit: 9,
          stepSize: STEP_SIZE,
        },
        type: 'time',
        time: {
          unit: STEP_SIZE !== 1 ? 'minute' : 'hour',
          displayFormats: {
            minute: ' h:mm',
            hour: ' h:mm',
          },
        },
        stacked: false,
      },
      y: {
        stacked: yAxis,
        display: false,
      },
    },
  };

  // const ganttChartHeight = datasets.length > 6 ? datasets.length * 3 : 18;
  const chartHeight = worklogs.length > 2 ? worklogs.length * 65 : 185;

  return (
    <GanttChartContainer
      onMouseOver={() => {
        setyAxis(false);
      }}
      onMouseLeave={() => setyAxis(true)}
      sx={{ height: yAxis ? '150px' : `${chartHeight}px` }}>
      <Bar data={data} options={options} width={'100%'} height={'100%'} />
    </GanttChartContainer>
  );
};

export default GanttChart;
