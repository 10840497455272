import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalPageContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  padding: 40,
  background: 'rgba(40, 40, 40, 0.80)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  display: 'inline-flex',
  backdropFilter: 'blur(3px)',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 999,
  trasition: '1s ease',
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: '2px',
  gap: theme.spacing(2),
  marginLeft: '78px',
  width: '419px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: '0',
  },
}));
