import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  maxWidth: '500px',
  minWidth: '350px',
  height: '150px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  gap: theme.spacing(3),
}));

export const DNFModalContainer = styled(Box)(() => ({
  width: '100%',
  backgroundColor: 'rgba(40, 40, 40, 0.80)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));
