import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos, Search } from '@mui/icons-material';
import { Grid, InputAdornment, OutlinedInput, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FilterPanelContainer, ItemListContainer, PageContainer } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import { useGetClientsQuery } from 'services/busmanApi';
import { sortLists } from 'utils/sort';

import { RootState } from '../../store';

import { Client, ClientsList } from './styles';

const ClientsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isFetching } = useGetClientsQuery({});
  const { clients } = useSelector((state: RootState) => state.worklogs);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const [inputText, setInputText] = React.useState('');

  const handleClientClick = (clientID: string) => {
    navigate(`/clients/${clientID}`);
  };

  const filteredClients = sortLists([...clients], 'client_name').filter((data) => {
    if (inputText === '') {
      return true;
    }

    return data?.client_name?.toLowerCase().includes(inputText.toLowerCase());
  });

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Clients" />
      </Helmet>
      <Loader open={isFetching} />
      <PageContainer>
        {filterPanel && (
          <FilterPanelContainer>
            {!isMobileScreen && (
              <Typography gutterBottom sx={{ mb: theme.spacing(2) }} variant="h4">
                Clients
              </Typography>
            )}
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              onChange={(event) => setInputText(event.target.value)}
              placeholder="Search Client"
              sx={{
                height: '32px',
              }}
              value={inputText}
            />
          </FilterPanelContainer>
        )}
        <ItemListContainer>
          <Grid container display="flex" flexDirection="row">
            <GridItem title="CLIENT" seenOrIsHeading={true} breakpoint={2.5} />
          </Grid>
          <ClientsList>
            {filteredClients.map((client) => {
              return (
                <Client key={client.client_id} onClick={() => handleClientClick(client.client_id)}>
                  <GridItem title={client.client_name} seenOrIsHeading={false} breakpoint={2.5} />
                  <Grid
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    item
                    justifyContent="end"
                    md={2}
                    sm={4}
                    xs={2}>
                    <ArrowForwardIos
                      sx={{ color: theme.palette.text.disabled, fontSize: '0.8rem', marginRight: theme.spacing(2) }}
                    />
                  </Grid>
                </Client>
              );
            })}
          </ClientsList>
        </ItemListContainer>
      </PageContainer>
    </>
  );
};

export default ClientsPage;
