import { Box, Modal, styled } from '@mui/material';

export const ModalPageContainer = styled(Modal)(() => ({
  width: '100%',
  height: '100%',
  padding: 40,
  background: 'rgba(40, 40, 40, 0.80)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  display: 'inline-flex',
  backdropFilter: 'blur(3px)',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 999,
  trasition: '1s ease',
}));

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
}));
