import React, { useState } from 'react';

import { Search } from '@mui/icons-material';
import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

import CustomButton from 'components/Button';
import { FilterButtons } from 'components/GlobalStyles/styles';
import { MessageType } from 'components/SnackBar';
import { useAddTeamMemberMutation } from 'services/busmanApi/teamsEndpoints';
import { Member, Team } from 'services/busmanApi/types';
import { UserType } from 'types/WorkLogTypes';

import { AddMembersModal, CloseButton, EmployeeList, ModalPageContainer, SectionHeader, TeamMemberBox } from './style';

interface AddEmployeeModalProps {
  employeeList?: UserType[];
  onClose: () => void;
  pod?: Team | undefined;
  setMessage: React.Dispatch<React.SetStateAction<MessageType | null>>;
}

const AddEmployeeModal = ({ employeeList, onClose, pod, setMessage }: AddEmployeeModalProps) => {
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [addMember] = useAddTeamMemberMutation();

  const existingMembers = pod?.members.map((member: Member) => !member.is_team && member.employee.user_id);

  const filteredEmployeeList =
    employeeList &&
    employeeList
      .filter((employee: UserType) => employee.full_name.toLowerCase().includes(searchInput.toLowerCase()))
      .filter((employee: UserType) => !existingMembers?.includes(employee.user_id));

  const handleAddEmployee = () => {
    setError(null);
    const newMemberData = {
      child_id: [...selectedEmployees],
      parent_id: pod?.team_id,
      is_team: false,
    };
    addMember(newMemberData).then((response) => {
      if ('error' in response) {
        setError('An error occurred while adding team member');
        setMessage({ type: 'error', msg: 'Error adding team member' });
      } else {
        setMessage({ type: 'success', msg: 'Team member added successfully!' });
        onClose();
      }
    });
  };

  const handleSelectEmployee = (selectedEmployee: string) => {
    if (selectedEmployees.includes(selectedEmployee))
      setSelectedEmployees([...selectedEmployees].filter((employee) => employee !== selectedEmployee));
    else setSelectedEmployees([...selectedEmployees, selectedEmployee]);
  };

  const renderEmployeeList = () => {
    return (
      <>
        {!employeeList?.length || (filteredEmployeeList && !filteredEmployeeList.length) ? (
          <SectionHeader>
            {filteredEmployeeList && !filteredEmployeeList.length && (
              <Typography>No employees match search..</Typography>
            )}
            {!employeeList?.length && <Typography>Loading Employees...</Typography>}
          </SectionHeader>
        ) : (
          <EmployeeList>
            {filteredEmployeeList &&
              filteredEmployeeList.map((employee: UserType) => {
                return (
                  <TeamMemberBox
                    key={employee.full_name}
                    onClick={() => handleSelectEmployee(employee.user_id)}
                    sx={
                      selectedEmployees.includes(employee.user_id)
                        ? { backgroundColor: theme.palette.busmanColors.busmanGreen }
                        : {}
                    }>
                    <Typography>{employee.full_name}</Typography>
                  </TeamMemberBox>
                );
              })}
          </EmployeeList>
        )}
      </>
    );
  };

  return (
    <ModalPageContainer open onClose={onClose}>
      <AddMembersModal onClick={(e) => e.stopPropagation()}>
        <SectionHeader>
          <Typography variant="h5">
            Add Employees to <strong>{pod?.team_name}</strong> team.
          </Typography>
          <CloseButton onClick={() => onClose()} />
        </SectionHeader>
        {error !== null && (
          <Typography variant="body1" sx={{ color: theme.palette.busmanColors.busmanRed }}>
            {error}
          </Typography>
        )}

        <Box sx={{ padding: '16px 16px 0 16px', width: '100%' }}>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            onChange={(event) => setSearchInput(event.target.value)}
            placeholder="Search Employee"
            sx={{
              height: '32px',
              mb: theme.spacing(1),
              width: '100%',
            }}
            value={searchInput}
          />
        </Box>
        {renderEmployeeList()}

        <FilterButtons sx={{ marginLeft: 'auto', padding: '16px', marginTop: 'auto' }}>
          <CustomButton variant="contained" text={'Add Employee'} width="medium" onClick={() => handleAddEmployee()} />
        </FilterButtons>
      </AddMembersModal>
    </ModalPageContainer>
  );
};

export default AddEmployeeModal;
