import { AppBar, Box, styled } from '@mui/material';
import MuiDivider from '@mui/material/Divider';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  color: theme.palette.primary.light,
  border: '1px solid',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const IconListBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  height: '95vh',
  width: '60px',
  top: theme.spacing(1),
  left: theme.spacing(1),
  borderRadius: '2px',
  zIndex: 1300,
  '@media print': {
    display: 'none',
  },
}));
