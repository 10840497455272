import { WorklogDayType, WorklogType } from 'types/WorkLogTypes';

// Group logs by date
export const regroupLogsByDate = (logs: WorklogType[]) => {
  const regroupedLogs = logs.reduce((result: WorklogDayType[], log: WorklogType) => {
    const existingEntryIndex = result.findIndex((entry: WorklogDayType) => entry.date === log.date);
    if (existingEntryIndex !== -1) {
      result[existingEntryIndex].worklogs.push(log);
    } else {
      result.push({ date: log.date, worklogs: [log] });
    }
    return result;
  }, []);

  return regroupedLogs;
};
