import React, { useState } from 'react';

import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Modal,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { ActivitiesProps } from 'types/WorkLogTypes';
import { sortLists } from 'utils/sort';

import { ActivitiesContainer, ActivitiesList, Header, modalStyles } from './styles';

interface ActivityModalProps {
  activities: Array<ActivitiesProps>;
  multipleSelection?: boolean;
  onClose: () => void;
  onSelect?: (value: ActivitiesProps) => void;
  onSelectMultiple?: (value: ActivitiesProps[]) => void;
  open?: boolean;
}

const SearchActivityModal = ({
  activities,
  multipleSelection = true,
  open = false,
  onClose,
  onSelect = () => {},
  onSelectMultiple = () => {},
}: ActivityModalProps) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [filteredActivities, setFilteredActivities] = useState<ActivitiesProps[]>(activities);
  const [selectedActivities, setSelectedActivities] = useState<ActivitiesProps[]>([]);

  const handleSearchChange = (event: { target: { value: string } }) => {
    setSearchValue(event.target.value);
    const filtered = activities.filter((activity: ActivitiesProps) =>
      activity.activity_name.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setFilteredActivities(filtered);
  };

  const handleClose = () => {
    setSelectedActivities([]);
    setSearchValue('');
    setFilteredActivities(activities);
    onClose();
  };

  const handleSelect = (activity: ActivitiesProps) => {
    if (!multipleSelection) {
      onSelect(activity);
      handleClose();
      return;
    }
    if (selectedActivities.includes(activity)) {
      setSelectedActivities(selectedActivities.filter((item) => item !== activity));
      return;
    }
    setSelectedActivities([...selectedActivities, activity]);
  };

  const handleAddActivities = () => {
    onSelectMultiple(selectedActivities);
    handleClose();
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setFilteredActivities(activities);
  };

  React.useEffect(() => {
    setFilteredActivities(activities);
  }, [activities]);
  const renderStatusChip = (activity: ActivitiesProps) => {
    return (
      <Chip
        size="small"
        label={activity.activity_name}
        variant="outlined"
        onDelete={() => handleSelect(activity)}
        sx={{
          color: theme.palette.busmanColors.busmanPrimary,
          borderColor: theme.palette.busmanColors.busmanPrimary,
          fontSize: '11px',
          mb: '4px',
          mr: '4px',
          '& .MuiChip-deleteIcon': {
            color: theme.palette.busmanColors.busmanPrimary,
          },
        }}
      />
    );
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        sx={modalStyles}
        aria-labelledby="activity-modal-title"
        aria-describedby="activity-modal-description">
        <ActivitiesContainer>
          <Header>
            <Typography variant="h6">Select Activity</Typography>
          </Header>
          <Box sx={{ padding: theme.spacing(2) }}>
            <OutlinedInput
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search Activities"
              inputRef={(input) => input && input.focus()}
              endAdornment={
                <InputAdornment position="end">
                  {searchValue ? (
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              }
              sx={{
                height: '32px',
                mb: theme.spacing(2),
                width: '100%',
              }}
            />
            <Box sx={{ mb: theme.spacing(2), gap: '4px' }}>
              {selectedActivities.map((activity: ActivitiesProps) => renderStatusChip(activity))}
            </Box>
            <ActivitiesList>
              {filteredActivities.length > 0 ? (
                sortLists([...filteredActivities], 'activity_name').map((activity: ActivitiesProps) => (
                  <ListItem
                    key={activity.activity_id}
                    onClick={() => handleSelect(activity)}
                    sx={{
                      color: selectedActivities.includes(activity) ? theme.palette.primary.contrastText : '',
                      backgroundColor: selectedActivities.includes(activity)
                        ? theme.palette.busmanColors.busmanPrimary
                        : '',
                      borderBottom: `1px solid ${theme.palette.background.default}`,
                      cursor: 'pointer',
                      ':hover': {
                        bgcolor: selectedActivities.includes(activity) ? '' : theme.palette.background.default,
                        cursor: 'pointer',
                        fontColor: theme.palette.primary.dark,
                      },
                    }}>
                    <ListItemText primary={activity.activity_name} />
                  </ListItem>
                ))
              ) : (
                <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
                  No results found...
                </Typography>
              )}
            </ActivitiesList>
            {multipleSelection && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <CustomButton
                  variant="contained_grey"
                  text="Add Activities"
                  onClick={handleAddActivities}
                  width="large"
                />
              </Box>
            )}
          </Box>
        </ActivitiesContainer>
      </Modal>
    </div>
  );
};

export default SearchActivityModal;
