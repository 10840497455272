import { Box, styled } from '@mui/material';

export const GanttChartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1),
  border: '2px solid',
  borderColor: theme.palette.background.paper,
  borderRadius: '2px',
  height: '150px',
  transition: 'height .5s ease',
}));
