import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import CustomButton from 'components/Button';

import { ModalContainer, ModalPageContainer } from './styles';

interface SuccessModalProps {
  purpose?: string;
  message: string;
  url: string;
}

const SuccessModal = ({ purpose, message, url }: SuccessModalProps) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(url);
  };

  return (
    <ModalPageContainer>
      <ModalContainer>
        <Typography variant="h3">Success!</Typography>
        <Typography sx={{ textAlign: 'center' }} variant="h5">
          {message}
        </Typography>
        <CustomButton variant="contained" text={purpose || ''} onClick={handleSubmit} />
      </ModalContainer>
    </ModalPageContainer>
  );
};

export default SuccessModal;
