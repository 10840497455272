import React, { useEffect, useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { Send as SendIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { TaggingListType } from 'types/WorkLogTypes';
import { DESCRIPTION_LIMIT } from 'utils/constants';

import { EditBtnContainer, UserTagContainer } from './style';

interface UserTaggingProps {
  setNewComment: (value: string) => void;
  setTaggedUsers: (tags: (string | null)[]) => void;
  commentValue: string;
  readOnly?: boolean;
  editMode?: boolean;
  handleCommentUpdate: () => void;
  handleCancel?: () => void;
  existingComment?: boolean;
  empID?: string;
}

const UserTagging = ({
  setNewComment,
  setTaggedUsers,
  commentValue,
  readOnly = true,
  editMode = false,
  handleCommentUpdate,
  handleCancel,
  existingComment,
  empID,
}: UserTaggingProps) => {
  const theme = useTheme();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { tagsList } = useSelector((state: RootState) => state.worklogs);

  // const isManagerOrAdmin = user?.user_role === 'A' || user?.user_role === 'M';

  const taggableUsersList = tagsList
    .filter((tag: TaggingListType) => {
      return (tag.user_role === 'A' || tag.user_role === 'M' || tag.user_id === empID) && tag.user_id !== user?.user_id;
    })
    .map((tag: TaggingListType) => {
      return {
        name: tag.username,
        display: tag.username,
        id: tag.user_id,
      };
    });

  const onInputChange = (value: string) => {
    setNewComment(value);
    const tags = value
      .replace(/\n/g, '')
      .split(' ')
      .filter((word) => word.includes('@'));
    const result = tags.map((displayName) => {
      const displayNameWithoutAt = displayName.substring(1);
      const matchingItem = taggableUsersList.find((item) => {
        return item.display === displayNameWithoutAt;
      });

      return matchingItem ? matchingItem.id : null;
    });

    setTaggedUsers(result);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [commentValue]);

  const mentionStyles = {
    control: {
      fontFamily: theme.typography.body1,
      fontSize: 11,
    },

    '&multiLine': {
      width: editMode ? '100%' : '99%',
      border: 'none',
      control: {
        fontFamily: theme.typography.body1,
        minHeight: editMode ? 'none' : '72px',
      },
      highlighter: {
        display: 'block',
        padding: 16,
        width: '100%',
        borderRadius: '2px',
        lineHeight: '18px',
        visibility: 'show',
        userSelect: 'none',
        pointerEvents: 'none',
        border: 'none',
        color: theme.palette.busmanColors.busmanPrimary,
        overflow: 'visible ',
      },
      input: {
        padding: 16,
        border: 'none',
        width: '100%',
        borderRadius: '2px',
        // marginLeft: 'none',
        lineHeight: '18px',
        display: 'block',
        outline: 'none',
        marginTop: '0',
        marginLeft: '.2px',
      },
    },
    suggestions: {
      zIndex: 999,
      border: 'none',
      borderRadius: '5px',
      list: {
        backgroundColor: theme.palette.background.default,
        fontSize: 14,
        border: 'none',
        borderRadius: '2px',
        maxHeight: '200px',
        overflowX: 'hidden',
      },
      item: {
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.busmanColors.lightGrey}`,
        '&focused': {
          backgroundColor: theme.palette.busmanColors.busmanPrimary,
          color: 'white',
        },
      },
    },
  };

  const readOnlyStyles = {
    control: {
      fontFamily: theme.typography.body1,
      fontSize: 11,
    },

    '&multiLine': {
      control: {
        fontFamily: theme.typography.body1,
        overflow: 'visible ',
      },
      highlighter: {
        display: 'block',
        border: 'none',
        width: '100%',
        borderRadius: '2px',
        lineHeight: '18px',
        userSelect: 'none',
        pointerEvents: 'none',
        color: 'white',
        overflow: 'visible ',
        zIndex: 10,
      },
      input: {
        display: 'block',
        padding: 0,
        border: 'none',
        width: '100%',
        height: 'auto',
        borderRadius: '2px',
        lineHeight: '18px',
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: 1,
        marginTop: '0',
        marginLeft: '.2px',
      },
    },
    suggestions: {
      zIndex: 90,
      list: {
        backgroundColor: theme.palette.background.default,
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: theme.palette.busmanColors.busmanBlue,
        },
      },
    },
  };

  const expression = `@(${user.username.replace(/\(/g, '\\(').replace(/\)/g, '\\)')})`;
  const regex = new RegExp(expression);

  return (
    <UserTagContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          padding: 0,
          border: readOnly ? '1px solid #F2F2F2' : 'none',
          marginLeft: existingComment ? '0' : theme.spacing(1),
          marginTop: existingComment ? '4px' : 0,
          width: editMode ? '100%' : '98.5%',
          alignItems: 'flex-end',
          paddingRight: theme.spacing(1),
          paddingBottom: existingComment ? 0 : theme.spacing(1),
          backgroundColor: readOnly ? theme.palette.background.paper : '',
        }}>
        <MentionsInput
          maxLength={255}
          allowSpaceInQuery
          inputRef={textareaRef}
          placeholder="Write a comment"
          value={commentValue || ''}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              if (e.shiftKey) {
                e.preventDefault();
                onInputChange(`${commentValue}\n`);
                return;
              }
              if (e.ctrlKey) handleCommentUpdate();
              else {
                e.preventDefault();
                onInputChange(`${commentValue}\n\n`);
              }
            }
          }}
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          style={readOnly ? mentionStyles : readOnlyStyles}
          readOnly={!readOnly}>
          {/* Current User Tag  */}
          <Mention
            style={{
              backgroundColor: theme.palette.busmanColors.busmanPrimary,
              borderRadius: '10px',
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
            trigger={'@'}
            markup={'@__display__ \u200d'}
            regex={regex}
            data={taggableUsersList}
            displayTransform={(display) => {
              const name = display
                .split('.')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
              return ` @${name}  `;
            }}
            appendSpaceOnAdd
          />
          {/* All other tags  */}
          <Mention
            style={{
              color: 'white',
              backgroundColor: theme.palette.busmanColors.lightGrey,
              opacity: 0.3,
              borderRadius: '10px',
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
            trigger={'@'}
            markup={'@__display__ \u200d'}
            data={[]}
            displayTransform={(display) => {
              const name = display
                .split('.')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
              return ` @${name}  `;
            }}
            appendSpaceOnAdd
          />
        </MentionsInput>
        {!existingComment && (
          <CustomButton
            disabled={commentValue.length === 0}
            variant="contained"
            text={<SendIcon sx={{ fontSize: '16px' }} />}
            width="small"
            toolTip="Submit Comment"
            onClick={() => handleCommentUpdate()}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        {editMode && (
          <EditBtnContainer>
            <Button
              variant="contained"
              onClick={handleCommentUpdate}
              sx={{
                height: '22px',
                minWidth: '40px',
                maxWidth: '50px',
                justifySelf: 'left',
              }}>
              Save
            </Button>
            <Button
              variant="contained_red"
              onClick={handleCancel}
              sx={{
                height: '22px',
                minWidth: '40px',
                maxWidth: '50px',
              }}>
              Cancel
            </Button>
          </EditBtnContainer>
        )}
        {readOnly && (
          <Typography
            variant="h6"
            sx={{
              color: '#D9534F',
              textAlign: 'right',
              width: '100%',
            }}>{`${commentValue.length}/${DESCRIPTION_LIMIT}`}</Typography>
        )}
      </Box>
    </UserTagContainer>
  );
};

export default UserTagging;
