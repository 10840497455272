import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import {
  EventBusy as AnnualLeaveIcon,
  Sick as SickLeaveIcon,
  WorkspacePremium as LongServiceLeaveIcon,
} from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { PageContainer } from 'components/GlobalStyles/styles';
import { HeaderUnderline } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import ProductivityChart from 'components/ProductivityChart';
import ProductivityDetail from 'components/ProductivityDetail';
import { useGetProductivityQuery } from 'services/busmanApi';

import { RootState } from '../../store';

import { DashboardSection, DoughnutChartContainer, LeaveStat, ProductivityBox } from './styles';

interface LeaveTypes {
  total_leaves: number;
  year_to_date: number;
  is_assigned: boolean;
  category_name: string;
}

interface LeaveCategoryTypes {
  category_id: string;
  mapped_category_id: string;
  category_name: string;
  category_type: string;
}

interface AvailableLeaveDataType {
  availableLeaves: number;
  name: string;
}

function HomePage() {
  const theme = useTheme();
  const [leaveUnit, setleaveUnit] = React.useState('hours');
  const [month, setMonth] = React.useState(dayjs().format('MMMM'));
  const { user } = useSelector((state: RootState) => state.auth);
  const userEmploymentStatus = user.payroll_details.employment_status;
  dayjs.extend(localeData);

  const params = {
    empID: user?.user_id,
    month: month,
  };

  const { data, error, isFetching } = useGetProductivityQuery(params);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setleaveUnit(newAlignment);
  };

  const renderUnitToggleBtn = () => {
    return (
      <ToggleButtonGroup color="primary" value={leaveUnit} exclusive onChange={handleChange} aria-label="Unit">
        <ToggleButton value="hours" sx={{ fontSize: '10px', height: '12px' }}>
          Hours
        </ToggleButton>
        <ToggleButton value="days" sx={{ fontSize: '10px', height: '12px' }}>
          Days
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const renderLeaveIcon = (leaveType: string) => {
    switch (leaveType) {
      case 'Personal Leave':
        return <SickLeaveIcon sx={{ color: theme.palette.primary.light, fontSize: '18px' }} />;
      case 'Annual Leave':
        return <AnnualLeaveIcon sx={{ color: theme.palette.primary.light, fontSize: '18px' }} />;
      case 'Long Service Leave':
        return <LongServiceLeaveIcon sx={{ color: theme.palette.primary.light, fontSize: '18px' }} />;
      default:
        return '';
    }
  };

  const getLeaveType = (name: string) => {
    const leaveCategory = name.split(' ')[0];
    switch (leaveCategory) {
      case 'Sick':
        return 'Personal Leave';
      case 'Holiday':
        return 'Annual Leave';
      case 'Long':
        return 'Long Service Leave';
      default:
        return '';
    }
  };

  const convertToDays = (totalHours: number) => {
    const hoursPerDay = userEmploymentStatus === 'FullTime' ? 8 : 4;
    const days = Math.round(totalHours / hoursPerDay);
    return days;
  };

  const renderLeaves = () => {
    if (userEmploymentStatus === 'Casual') return null; // No leaves for casual employee

    const eligibleEntitlements = user?.payroll_details?.leaves_category?.map(
      (category: LeaveCategoryTypes) => category.category_name,
    );

    const availableLeavesData: Array<AvailableLeaveDataType> = user?.leaves.map((leave: LeaveTypes) => {
      return {
        availableLeaves: leave.total_leaves,
        name: getLeaveType(leave.category_name),
      };
    });

    const userLeaveEntitlements = availableLeavesData.filter((availableLeave) =>
      eligibleEntitlements.includes(availableLeave.name),
    );

    return userLeaveEntitlements.map(({ availableLeaves, name }: AvailableLeaveDataType) => {
      return (
        <LeaveStat key={name}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: theme.spacing(2),
            }}>
            {renderLeaveIcon(name)}
            <Typography variant="subtitle2" sx={{ mr: 4, alignSelf: 'center' }}>
              {name}
            </Typography>
          </Box>
          <Typography variant="h4">
            {leaveUnit === 'hours' ? availableLeaves : convertToDays(availableLeaves)}
          </Typography>
        </LeaveStat>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Home" />
      </Helmet>
      <Loader open={isFetching} />
      <PageContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DashboardSection>
            <HeaderUnderline>
              <Typography variant="h6">My Hours</Typography>
              {/* <MoreHorizIcon sx={{ color: theme.palette.primary.light }} /> */}
            </HeaderUnderline>
            <Box sx={{ padding: 3 }}>
              {/* TODO: To be able to toggle between my hours and myteam hours */}
              {/* <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleChangeAlignment}
                  sx={{
                    width: '100%',
                    mb: 3,
                    border: `4px solid ${theme.palette.background.default}`,
                    backgroundColor: theme.palette.background.default,
                  }}>
                  <ToggleButton value="myHours">My Hours</ToggleButton>
                  <ToggleButton value="myTeamHours">My Team</ToggleButton>
                </ToggleButtonGroup> */}
              <Typography variant="body2" sx={{ color: theme.palette.primary.light }}>
                VIEW
              </Typography>
              <FormControl sx={{ minWidth: '250px', mb: 3 }}>
                <Select
                  value={month}
                  onChange={(event) => {
                    setMonth(event.target.value);
                  }}
                  sx={{
                    fontSize: '12px',
                    height: '32px',
                    width: '184px',
                  }}>
                  {dayjs.months().map((name) => {
                    return (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {!isFetching && data && (
                <>
                  {data.totalHours ? (
                    <>
                      <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }}>
                        {dayjs(data.startDate).format('ddd, D MMM')}
                        &nbsp; - &nbsp;
                        {dayjs(data.endDate).format('ddd, D MMM YYYY')}
                      </Typography>
                      <ProductivityBox>
                        <DoughnutChartContainer>
                          <ProductivityChart chartStats={data} />
                        </DoughnutChartContainer>
                        <ProductivityDetail data={data} />
                      </ProductivityBox>
                    </>
                  ) : (
                    <Typography variant="subtitle1" sx={{ color: theme.palette.busmanColors.busmanRed }}>
                      No data available for this month
                    </Typography>
                  )}
                </>
              )}
              {error && (
                <Typography variant="subtitle1" sx={{ color: theme.palette.busmanColors.busmanRed }}>
                  Error Fetching data.
                </Typography>
              )}
            </Box>
          </DashboardSection>
          {userEmploymentStatus !== 'Casual' && (
            <DashboardSection>
              <HeaderUnderline>
                <Typography variant="h6">Leave</Typography>
              </HeaderUnderline>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  padding: theme.spacing(2),
                  gap: theme.spacing(1),
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <Typography variant="subtitle2" sx={{ color: theme.palette.primary.light }}>
                      THIS MONTH
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main }}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  </Box>
                  {renderUnitToggleBtn()}
                </Box>
                {renderLeaves()}
              </Box>
            </DashboardSection>
          )}
        </Box>
      </PageContainer>
    </>
  );
}

export default HomePage;
