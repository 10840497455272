import { ThemeType } from './slice/types';

export const isSystemDark = window?.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)')?.matches : undefined;

export const saveTheme = (theme: ThemeType) => {
  return window.localStorage && localStorage.setItem('selectedTheme', theme);
};

export function getThemeFromStorage(): ThemeType | null {
  return window.localStorage ? (localStorage.getItem('selectedTheme') as ThemeType) || null : null;
}
