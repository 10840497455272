import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';

import { DNFModalContainer, ErrorContainer } from './styles';

interface DataNotFoundProps {
  errorMessage: string;
  url: string;
}

const DataNotFound = ({ errorMessage, url }: DataNotFoundProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/${url}`);
  };
  return (
    <>
      <DNFModalContainer>
        <ErrorContainer>
          <Typography variant="h3" sx={{ color: theme.palette.busmanColors.busmanRed }}>
            {errorMessage}
          </Typography>
          <CustomButton variant="contained_red" text="GO BACK" width="large" onClick={handleNavigate} />
        </ErrorContainer>
      </DNFModalContainer>
    </>
  );
};

export default DataNotFound;
