import { BMIconLight } from 'images';

import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const LoginContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.busmanColors.gradient,
  // background: theme.palette.background.default,
  zIndex: 999,
}));

export const LoginForm = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  height: 'fit-content',
  padding: theme.spacing(2),
  borderRadius: '4px',
  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
  width: '496px',
}));

export const LoginRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing(2),
}));

export const BusminLogo = styled('img')(() => ({
  width: '115px',
}));

export const MaskBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  WebkitMaskImage: `url(${BMIconLight})`,
  maskImage: `url(${BMIconLight})`,
  WebkitMaskRepeat: 'no-repeat',
  maskRepeat: 'no-repeat',
  WebkitMaskSize: 'cover', // Adjust the size of the mask image
  maskSize: 'cover', // Adjust the size of the mask image
  backgroundPosition: 'left', // Adjust the position of the mask image
  zIndex: 1,
}));
