import React, { useState } from 'react';

import { Box, TextField, Typography, useTheme } from '@mui/material';

import CustomButton from 'components/Button';
import { FilterButtons } from 'components/GlobalStyles/styles';
import { MessageType } from 'components/SnackBar';
import { useAddTeamMemberMutation, useAddTeamMutation } from 'services/busmanApi/teamsEndpoints';
import { Team } from 'services/busmanApi/types';
import { UserType } from 'types/WorkLogTypes';

import { AddPodModal, CloseButton, EmployeeList, ModalPageContainer, SectionHeader, TeamMemberBox } from './style';

interface AddNewPodProps {
  managerId: string;
  team: Team | undefined;
  onClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<MessageType | null>>;
  employeeList?: UserType[];
}

const AddNewPod = ({ team, onClose, setMessage, employeeList }: AddNewPodProps) => {
  const theme = useTheme();
  const [podName, setPodName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addPod] = useAddTeamMemberMutation();
  const [addTeam] = useAddTeamMutation();
  const [managerError, setManagerError] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);

  const filteredEmployeeList =
    employeeList &&
    employeeList
      .filter((employee) => employee.user_role === 'M' || employee.user_role === 'A')
      .filter((employee) => employee.full_name.toLocaleLowerCase().includes(searchInput.toLowerCase()));

  const handleAddNewPod = async () => {
    if (podName === '') return setErrorMessage('Pod name is required');
    if (selectedEmployees.length === 0) return setManagerError('Please select at least one manager');
    const newTeamData = {
      team_name: podName,
      manager_id: selectedEmployees,
    };
    const result = await addTeam(newTeamData);
    if ('data' in result) {
      const newPodData = {
        child_id: result.data.data.team_id,
        parent_id: team && team.team_id,
        is_team: true,
      };

      const response: any = await addPod(newPodData);
      if ('error' in response) {
        setErrorMessage(response.error.data.errors);
        setMessage({ type: 'Error', msg: 'Error Adding Pod' });
      } else {
        setMessage({ type: 'success', msg: 'Pod added successfully!' });
        onClose();
      }
    } else {
      setErrorMessage('Team with this name already exists');
      setMessage({ type: 'Error', msg: 'Error creating Pod' });
    }
  };

  const handleSelectEmployee = (selectedEmployee: string) => {
    if (selectedEmployees.includes(selectedEmployee))
      setSelectedEmployees([...selectedEmployees].filter((employee) => employee !== selectedEmployee));
    else setSelectedEmployees([...selectedEmployees, selectedEmployee]);
  };

  const renderEmployeeList = () => {
    return (
      <>
        {filteredEmployeeList && filteredEmployeeList.length < 1 ? (
          <SectionHeader sx={{ borderBottom: 'none' }}>
            <Typography variant="body1">No employee matches search.</Typography>
          </SectionHeader>
        ) : (
          <EmployeeList>
            {filteredEmployeeList &&
              filteredEmployeeList.map((employee) => {
                return (
                  <TeamMemberBox
                    key={employee.user_id}
                    onClick={() => handleSelectEmployee(employee.user_id)}
                    sx={
                      selectedEmployees.includes(employee.user_id)
                        ? { backgroundColor: theme.palette.busmanColors.busmanGreen }
                        : {}
                    }>
                    <Typography>{employee.full_name}</Typography>
                  </TeamMemberBox>
                );
              })}
          </EmployeeList>
        )}
      </>
    );
  };

  return (
    <ModalPageContainer open onClose={onClose}>
      <AddPodModal onClick={(e) => e.stopPropagation()}>
        <SectionHeader>
          <Typography variant="h5">
            Add Pod to <strong>{team && team.team_name}</strong>
          </Typography>
          <CloseButton onClick={() => onClose()} />
        </SectionHeader>

        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', padding: '8px 16px 16px 16px' }}>
          <Typography variant="body2">NEW POD NAME</Typography>
          <TextField
            value={podName}
            fullWidth
            onChange={(event) => setPodName(event.target.value)}
            sx={{
              '.MuiOutlinedInput-input': { p: 1 },
            }}></TextField>
          {errorMessage && (
            <Typography sx={{ color: theme.palette.busmanColors.busmanRed }} variant="body2">
              *{errorMessage}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', padding: '8px 16px 0px 16px' }}>
          <Typography variant="body2">ADD MANAGERS</Typography>
          <TextField
            value={searchInput}
            fullWidth
            placeholder="Search Managers"
            onChange={(event) => setSearchInput(event.target.value)}
            sx={{
              '.MuiOutlinedInput-input': { p: 1 },
            }}></TextField>
          {managerError !== null && (
            <Typography sx={{ color: theme.palette.busmanColors.busmanRed }} variant="body2">
              *{managerError}
            </Typography>
          )}
        </Box>
        {renderEmployeeList()}
        <FilterButtons sx={{ marginLeft: 'auto', padding: '16px' }}>
          <CustomButton variant="contained" text={'Add Pod'} width="medium" onClick={() => handleAddNewPod()} />
        </FilterButtons>
      </AddPodModal>
    </ModalPageContainer>
  );
};

export default AddNewPod;
