import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { ArrowForwardIos, Search } from '@mui/icons-material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { FilterButtons, FilterPanelContainer, ItemListContainer, PageContainer } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import NewJob from 'features/NewJob';
import { useGetActiveJobsQuery, useGetAllJobsQuery, useGetManagerListQuery } from 'services/busmanApi';
import { JobProps } from 'types/WorkLogTypes';

import { RootState } from '../../store';

import { Job } from './styles';

const JobsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const statuses = ['All', 'InProgress', 'Completed'];
  const activeStatuses = ['Active', 'Inactive', 'All'];
  const [addNewJob, setAddNewJob] = React.useState<boolean>(false);
  const [inputText, setInputText] = React.useState('');
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const [status, setStatus] = React.useState<string>(statuses[0]);
  const [activeInactive, setActiveInactive] = React.useState('Active');
  const { clients, jobs } = useSelector((state: RootState) => state.worklogs);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const jobsResult = useGetActiveJobsQuery({});
  const { data: jobManagers } = useGetManagerListQuery({});
  const { data: allJobs, isFetching: isFetchingAllJobs } = useGetAllJobsQuery(
    {},
    { skip: activeInactive === 'Active' },
  );

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const handleJobClick = (selectedJob: JobProps) => {
    navigate(`/jobs/${selectedJob?.job_id}`);
  };

  const jobList = React.useMemo(() => {
    if (activeInactive === 'Active') return jobs;

    const inActiveJobList = allJobs?.filter((job) => !job.is_active);

    return activeInactive === 'Inactive' ? inActiveJobList : allJobs;
  }, [activeInactive, jobs, allJobs]);

  const filteredJobs = jobList
    ?.filter((data) => {
      if (inputText === '') {
        return true;
      } else {
        return data?.job_number.toLowerCase().includes(inputText.toLowerCase());
      }
    })
    .filter((data) => {
      if (status === statuses[0]) {
        return true;
      } else {
        return data?.job_status === status;
      }
    })
    .sort((a, b) => {
      return a.job_number.localeCompare(b.job_number);
    });

  const isFetching = jobsResult.isFetching;

  const renderStatusChip = (jobStatus: string, count?: number | undefined) => {
    const chipColor = () => {
      switch (jobStatus) {
        case 'Approved':
          return theme.palette.busmanColors.busmanGreen;
        case 'Pending':
          return theme.palette.busmanColors.busmanPrimary;
        case 'Synced':
          return theme.palette.busmanColors.lightGrey;
        case 'In Progress':
          return theme.palette.busmanColors.busmanPrimary;
        case 'Completed':
          return theme.palette.busmanColors.busmanGreen;
      }
    };

    if (jobStatus === 'InProgress') jobStatus = 'In Progress';

    return (
      <Chip
        size="small"
        label={count !== undefined ? count : jobStatus.toUpperCase()}
        variant="outlined"
        sx={{
          color: chipColor(),
          border: !isMobileScreen ? `1px solid ${chipColor()}` : 'none',
          fontSize: '10px',
          // padding: theme.spacing(0.5),
          '.MuiChip-avatar': { width: 'auto' },
          // '.MuiChip-label': { pr: 0 },
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.5rem',
          },
        }}
      />
    );
  };

  const renderJobs = () => {
    const rowRenderer = ({ index, style, data }: ListChildComponentProps<JobProps[]>) => {
      const job = data[index];
      const jobClient = clients.find((client) => client.client_id === job.client?.client_id);
      return (
        <Job
          container
          key={job.job_number}
          onClick={() => handleJobClick(job)}
          style={style}
          sx={{ border: 1, borderColor: theme.palette.background.default }}>
          <GridItem title={job.job_number} seenOrIsHeading={false} breakpoint={2} />
          <GridItem title={jobClient?.client_name} seenOrIsHeading={false} breakpoint={2} />
          <GridItem title={job.job_description} seenOrIsHeading={false} breakpoint={4.5} />
          <GridItem title={renderStatusChip(job.job_status)} seenOrIsHeading={false} breakpoint={2} />
          <GridItem
            title={
              <Checkbox
                checked={job.is_active}
                checkedIcon={
                  <SquareRoundedIcon
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      padding: '1px',
                      color: theme.palette.busmanColors.busmanPrimary,
                    }}
                  />
                }
                sx={{
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiSvgIcon-root': {
                    width: '15px',
                    height: '15px',
                    color: theme.palette.busmanColors.busmanPrimary,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: '3px',
                  },
                }}
              />
            }
            breakpoint={1}
            seenOrIsHeading={false}
          />

          <GridItem
            title={<ArrowForwardIos sx={{ color: theme.palette.text.disabled, fontSize: '0.8rem' }} />}
            seenOrIsHeading={false}
            breakpoint={0.5}
          />
        </Job>
      );
    };

    return (
      <ItemListContainer
        sx={{
          [theme.breakpoints.down('lg')]: {
            display: addNewJob ? 'none' : '',
          },
        }}>
        <Grid container display="flex" flexDirection="row">
          <GridItem title={'JOB NUMBER'} seenOrIsHeading={true} breakpoint={2} />
          <GridItem title={'CLIENT'} seenOrIsHeading={true} breakpoint={2} />
          <GridItem title={'DESCRIPTION'} seenOrIsHeading={true} breakpoint={4.5} />
          <GridItem title={'STATUS'} seenOrIsHeading={true} breakpoint={2} />
          <GridItem title={'ACTIVE'} seenOrIsHeading={true} breakpoint={1} />
        </Grid>
        {filteredJobs && filteredJobs?.length === 0 && (
          <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
            No results found...
          </Typography>
        )}
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <FixedSizeList
              height={height - 16}
              width={width}
              itemCount={filteredJobs?.length || 0}
              itemData={filteredJobs}
              itemSize={72}
              className="no-scrollbars">
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </ItemListContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Jobs" />
      </Helmet>
      <Loader open={isFetching || isFetchingAllJobs} />
      <PageContainer>
        <>
          {filterPanel && !addNewJob && (
            <FilterPanelContainer>
              {!isMobileScreen && (
                <Typography
                  gutterBottom
                  sx={{
                    mb: theme.spacing(2),
                  }}
                  variant="h4">
                  Jobs
                </Typography>
              )}
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
                onChange={(event) => setInputText(event.target.value)}
                placeholder="Search Job Number"
                sx={{
                  height: '32px',
                  mb: theme.spacing(2),
                }}
                value={inputText}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                {/* <Typography gutterBottom variant="body2">
                  CLIENT
                </Typography>
                <Select
                  defaultValue="All"
                  onChange={(newSelection) => {
                    setClient(newSelection.target.value);
                  }}
                  sx={{
                    height: '32px',
                    mb: theme.spacing(2),
                    [theme.breakpoints.down('md')]: {
                      mb: 0,
                      width: '100px',
                    },
                  }}
                  value={client}>
                  <MenuItem value={'All'}>All</MenuItem>
                  {clients.map((client) => {
                    return (
                      <MenuItem key={client.client_id} value={client.client_id}>
                        {client.client_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}
                <Typography gutterBottom variant="body2">
                  STATUS
                </Typography>
                <Select
                  onChange={(newSelection) => {
                    setStatus(newSelection.target.value);
                  }}
                  sx={{
                    height: '32px',
                    mb: theme.spacing(2),
                    [theme.breakpoints.down('md')]: {
                      mb: 0,
                      width: '100px',
                    },
                  }}
                  value={status}>
                  {statuses.map((jobStatus) => {
                    return (
                      <MenuItem key={jobStatus} value={jobStatus}>
                        {jobStatus}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Typography gutterBottom variant="body2">
                  ACTIVE/INACTIVE
                </Typography>
                <Select
                  onChange={(newSelection) => {
                    setActiveInactive(newSelection.target.value);
                  }}
                  sx={{
                    height: '32px',
                    mb: theme.spacing(2),
                    [theme.breakpoints.down('md')]: {
                      mb: 0,
                      width: '100px',
                    },
                  }}
                  value={activeInactive}>
                  {activeStatuses.map((jobStatus) => {
                    return (
                      <MenuItem key={jobStatus} value={jobStatus}>
                        {jobStatus}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* {activeInactive === 'Inactive' && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Typography variant="body2" gutterBottom>
                      FROM
                    </Typography>
                    <DatePicker allowMaxDate onChange={handleFromDateChange} value={fromDate} />
                    <Typography variant="body2" gutterBottom mt={1}>
                      TO
                    </Typography>
                    <DatePicker allowMaxDate onChange={handleToDateChange} value={toDate} />
                  </Box>
                )} */}
              </Box>
              <FilterButtons sx={{ marginLeft: 'auto' }}>
                <CustomButton
                  variant="contained"
                  onClick={() => {
                    setAddNewJob(true);
                  }}
                  width="medium"
                  text="New Job"
                />
              </FilterButtons>
            </FilterPanelContainer>
          )}
          {renderJobs()}
          {addNewJob && (
            <NewJob
              close={() => setAddNewJob(false)}
              showMessage={handleOpenSnackbar}
              jobManagers={jobManagers || []}
            />
          )}
        </>
      </PageContainer>
      <SnackBar message={message} onClose={handleCloseSnackbar} />
    </>
  );
};

export default JobsPage;
