import { Box, styled } from '@mui/material';

export const NoWorklogsBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[2],
  display: 'flex',
  minHeight: '48px',
  maxWidth: '650px',
  borderRadius: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
}));

export const RecentlyUpdatedWorklogsBox = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(92, 184, 92, 0.3)',
  color: theme.palette.busmanColors.busmanGreen,
  alignItems: 'center',
  borderRadius: '2px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  animation: 'fadeIn 0.2s ease-in',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
