/**
 *
 * ThemePage
 *
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

export default function ThemePage() {
  return (
    <>
      <Helmet>
        <title>EEA-Busman theme</title>
        <meta name="Busmin" content="EEA-Busman Theme" />
      </Helmet>
      <Container sx={{ mt: 2 }}>
        <Typography variant="h1">EEA-BUSMAN Components</Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h3" gutterBottom>
            Button
          </Typography>
          <Grid container spacing={2} sx={{ ml: 2 }}>
            <Grid item>
              <Button variant="contained">Contained</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disabled>
                Disabled
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained_grey">Contained Blue</Button>
            </Grid>
            <Grid item>
              <Button variant="contained_red">Contained Red</Button>
            </Grid>
            <Grid item>
              <Button variant="contained_lightBlue">Contained Light Blue</Button>
            </Grid>
            <Grid item>
              <Button variant="contained_orange">Contained Orange</Button>
            </Grid>
            <Grid item>
              <Button variant="contained_grey">Contained Grey</Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h3" gutterBottom>
            Typography
          </Typography>
          <Box sx={{ ml: 4 }}>
            <Typography variant="h4" gutterBottom>
              h4. Heading
            </Typography>
            <Typography variant="h5" gutterBottom>
              h5. Heading
            </Typography>
            <Typography variant="h6" gutterBottom>
              h6. Heading
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            </Typography>
            <Typography variant="subtitle2_italic">
              subtitle2_italic. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            </Typography>
            <Typography variant="body1" gutterBottom>
              body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit,
              quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat
              deleniti? Eum quasi quidem quibusdam.
            </Typography>
            <Typography variant="body2" gutterBottom>
              body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit,
              quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat
              deleniti? Eum quasi quidem quibusdam.
            </Typography>
            <Typography variant="button" display="block" gutterBottom>
              button text
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              caption text
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              overline text
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
}
