// StyledComponents.js
import { styled } from '@mui/material';
import { Box, Typography } from '@mui/material';

export const BadgeContainer = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  backgroundColor: theme.palette.error.main,
  minHeight: '12px',
  maxWidth: '12px',
  minWidth: '12px',
  position: 'absolute',
  right: '25%',
  top: '12%',
}));

export const BadgeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '8px',
}));
