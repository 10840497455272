import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import {
  useAddWorklogCommentMutation,
  useDeleteWorklogCommentMutation,
  useGetWorklogCommentsQuery,
  useUpdateWorklogCommentMutation,
} from 'services/busmanApi';
import { ActivitiesProps, ExtendedWorklogType, JobProps, WorklogType } from 'types/WorkLogTypes';

import Comments from '../../features/Comments';
import { RootState } from '../../store';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

import { WorkLogCardContainer } from './styles';

type ActivityType = {
  activity_id: string;
  activity_name: string;
  rate: string;
};

interface WorkLogCardProps {
  worklog: ExtendedWorklogType;
  isSelected: (id: string) => boolean;
  isJobManagerOrAdmin?: boolean;
  openComment?: boolean;
  onApproveWorklog: (data: WorklogType) => void;
  onEditWorklog: (data: WorklogType) => void;
  onDeleteWorkLog: (workLogIds: Array<string>) => void;
  onUnsyncWorkLog?: (workLogId: string) => void;
}

const WorkLogCard = ({
  worklog,
  isSelected,
  isJobManagerOrAdmin,
  onApproveWorklog,
  onEditWorklog,
  onDeleteWorkLog,
  openComment,
  onUnsyncWorkLog,
}: WorkLogCardProps) => {
  const {
    activity,
    break_time: breakTime,
    client,
    job,
    date,
    description,
    employee,
    end_time: endTime,
    isOverlapped,
    payroll_category: payrollCategory,
    start_time: startTime,
    status,
    slip_id: slipID,
    worklog_hours: worklogHours,
    worklog_type: worklogType,
    comments,
  } = worklog;
  const [modalView, setModalView] = React.useState<string | null>(null);
  const [showComments, setShowComments] = React.useState(openComment || false);
  const [commentCount, setCommentCount] = React.useState<number>(comments);

  const theme = useTheme();
  const location = useLocation();
  const selected = isSelected(slipID);
  const { user } = useSelector((state: RootState) => state.auth);
  const { jobs } = useSelector((state: RootState) => state.worklogs);

  const [addLogComment] = useAddWorklogCommentMutation();
  const [deleteLogComment] = useDeleteWorklogCommentMutation();
  const [updateLogComment] = useUpdateWorklogCommentMutation();

  const isMyWorklogPage = location.pathname.startsWith('/worklogs');
  const hideUnSyncButton = user.user_role !== 'A' || isMyWorklogPage || worklog?.groupedIds?.length;
  const isJobDetailPage = location.pathname.startsWith('/job');
  const hideAllButtons = isJobDetailPage && !isJobManagerOrAdmin;
  const groupedLogCount = worklog?.groupedIds ? worklog?.groupedIds?.length : 0;

  const disableApprove = employee.user_id === user.user_id && user.user_role !== 'A'; // prevent managers to approve their own log

  const isRecommended = React.useMemo(() => {
    let recommended = null;
    if (jobs.length && job && employee) {
      const userActivities = employee?.activities || [];

      const jobActivities = jobs.find((item: JobProps) => item.job_id === job.job_id)?.activities;
      const recommendedUserActivity = userActivities.some(
        (userActivity: ActivityType) => userActivity.activity_id === activity.activity_id,
      );
      const recommendedJobActivity = jobActivities?.some(
        (jobActivity: ActivitiesProps) => jobActivity.activity_id === activity.activity_id,
      );

      recommended = recommendedUserActivity && recommendedJobActivity;
    }
    return recommended;
  }, [activity, employee, job, jobs]);

  const checkWorklogStatus = () => {
    if (status === 'Billed') {
      return theme.palette.primary.light;
    }
    return status === 'Approved' ? theme.palette.busmanColors.approvedLog : theme.palette.background.paper;
  };

  const addComment = async (newComment: string, taggedUsers?: (string | null)[]) => {
    const result = await addLogComment({
      comment_description: newComment,
      slip_id: slipID,
      tagged_users: taggedUsers,
    });

    if ('data' in result) {
      return 'success';
    }

    return 'error';
  };

  const deleteComment = async (commentId: string) => {
    const result = await deleteLogComment({ commentId, slipID });

    if ('data' in result) {
      return 'success';
    }

    return 'error';
  };

  const updateComment = async (updatedComment: string, commentId: string, updatedTaggedUsers?: (string | null)[]) => {
    const result = await updateLogComment({
      comment_description: updatedComment,
      comment_id: commentId,
      tagged_users: updatedTaggedUsers,
    });

    if ('data' in result) {
      return 'success';
    }

    return 'error';
  };

  const handleShowComments = () => {
    setShowComments(!showComments);
  };

  const handleModalView = (workLogId: string) => {
    if (modalView === 'Delete') {
      const deleteIds = worklog.groupedIds ? worklog.groupedIds : [workLogId];
      onDeleteWorkLog(deleteIds);
    }
    if (modalView === 'Unsync' && onUnsyncWorkLog) onUnsyncWorkLog(workLogId);

    setModalView(null);
  };

  return (
    <>
      <WorkLogCardContainer
        sx={{ backgroundColor: checkWorklogStatus(), border: selected ? '2px solid #9531FF' : 'none' }}
        elevation={0}>
        <Box justifyContent="space-between" alignItems="center" mb={theme.spacing(2)} sx={{ display: 'flex' }}>
          {worklog.startDate ? (
            <Typography variant="h5">
              {dayjs(worklog.startDate).format('D/MM/YYYY')} - {dayjs(worklog.endDate).format('D/MM/YYYY')}
            </Typography>
          ) : (
            <Typography variant="h5">{dayjs(date).format('D/MM/YYYY')}</Typography>
          )}
          <Tooltip title={isOverlapped ? 'Another log found within same time range' : ''} placement="top">
            <Typography
              variant="h5"
              sx={{
                backgroundColor: isOverlapped ? theme.palette.busmanColors.busmanWarn : 'none',
                paddingLeft: 1,
                paddingRight: 1,
              }}>
              {dayjs(startTime).format('LT')} - {dayjs(endTime).format('LT')}
            </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {worklogType === 'LeaveLog' && (
            <Typography variant="h5" sx={{ mb: theme.spacing(1) }}>
              {payrollCategory.category_name}
            </Typography>
          )}
          {worklogType === 'WorkLog' && (
            <>
              {!isJobDetailPage && (
                <Typography variant="h5" sx={{ mb: theme.spacing(1) }}>
                  {client.client_name}
                </Typography>
              )}
              <Box mb={theme.spacing(1)} sx={{ display: 'flex' }}>
                {isJobDetailPage ? (
                  <Typography variant="h6" sx={{ mr: theme.spacing(1) }}>
                    {employee.full_name}
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ mr: theme.spacing(1) }}>
                    {job.job_number}
                  </Typography>
                )}
                <Tooltip
                  title={isRecommended ? '' : 'Not recommended activity for this job or employee'}
                  placement="top">
                  <Typography
                    variant="h6"
                    sx={{
                      fontStyle: 'italic',
                      backgroundColor: isRecommended ? 'none' : theme.palette.busmanColors.busmanWarn,
                      pl: 1,
                      pr: 1,
                    }}>
                    {activity?.activity_name}
                  </Typography>
                </Tooltip>
              </Box>
            </>
          )}
          <TextField
            value={description}
            InputProps={{
              readOnly: true,
              multiline: true,
              fullWidth: true,
            }}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: '2px',
              mb: theme.spacing(2),
              pr: theme.spacing(1),
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ mr: 2 }}>
              {groupedLogCount ? groupedLogCount * worklogHours : worklogHours}h
            </Typography>
            {breakTime > 0 && worklogType === 'WorkLog' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 2,
                  gap: theme.spacing(0.5),
                }}>
                <Tooltip title="Break">
                  <EmojiFoodBeverageIcon
                    sx={{
                      color: status === 'Billed' ? theme.palette.primary.contrastText : theme.palette.primary.light,
                      fontSize: '15.5px',
                    }}
                  />
                </Tooltip>
                <Typography variant="h4">{breakTime}min</Typography>
              </Box>
            )}
            <Tooltip title={showComments ? 'Hide Comments' : 'Show Comments'}>
              <Box display={'flex'} onClick={handleShowComments} sx={{ cursor: 'pointer' }}>
                <ForumOutlinedIcon
                  sx={{
                    color: status === 'Billed' ? theme.palette.primary.contrastText : theme.palette.primary.light,
                    fontSize: '16px',
                  }}
                />
                {commentCount > 0 && (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ml: 1,
                      lineHeight: '14px',
                      alignSelf: 'flex-end',
                      color: status === 'Synced' ? theme.palette.background.paper : theme.palette.text.secondary,
                    }}>
                    {commentCount === 1 ? `${commentCount} Comment` : `${commentCount} Comments `}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            {!hideAllButtons && status !== 'Billed' && (
              <>
                <CustomButton
                  variant="contained_red"
                  text={<CloseIcon />}
                  width="small"
                  onClick={() => setModalView('Delete')}
                  toolTip="Delete Worklog"
                />
                <CustomButton
                  variant="contained_grey"
                  text={<EditIcon />}
                  width="small"
                  onClick={() => onEditWorklog(worklog)}
                  toolTip="Edit Worklog"
                />
                {!isMyWorklogPage && (
                  <CustomButton
                    disabled={disableApprove}
                    variant={status === 'Pending' ? 'contained' : 'contained_orange'}
                    text={status === 'Pending' ? <CheckIcon /> : <UndoIcon />}
                    width="small"
                    onClick={() => onApproveWorklog(worklog)}
                    toolTip={status === 'Pending' ? 'Approve' : 'UnApprove'}
                  />
                )}
              </>
            )}
            {status === 'Billed' && !hideUnSyncButton && (
              <CustomButton
                variant={'contained_orange'}
                text={<UndoIcon />}
                width="small"
                onClick={() => setModalView('Unsync')}
                toolTip="Unsync Worklog"
              />
            )}
          </Box>
          {modalView !== null && (
            <DeleteConfirmationDialog
              title={`Confirm ${modalView}`}
              description={
                modalView === 'Unsync'
                  ? 'Are you sure you want to unsync this Worklog entry? '
                  : `${modalView} Worklog entry?`
              }
              secondaryText={
                modalView === 'Unsync' ? ' This action will change worklog status from synced to approved.' : ''
              }
              onClose={() => setModalView(null)}
              onDelete={() => handleModalView(slipID)}
              buttonText={modalView}
            />
          )}
        </Box>
        {showComments && (
          <Comments
            color={checkWorklogStatus()}
            addNewComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            fetchComments={useGetWorklogCommentsQuery}
            showHeader={false}
            id={worklog.slip_id}
            empID={worklog.employee.user_id}
            updateCommentCount={setCommentCount}
          />
        )}
      </WorkLogCardContainer>
    </>
  );
};

export default WorkLogCard;
