export const ABN = 'ABN 21 143 786 216';

export const USER_ROLES = {
  ADMIN: 'A',
  MANAGER: 'M',
  EMPLOYEE: 'E',
};

export const DESCRIPTION_LIMIT = 255;

export const WORKLOGS_LIMIT = 400;

export const COMPANY_ID = 'd1eed91e-f656-472b-a3cb-7643ba169395';

export const COMPANY_DETAILS = {
  company_name: 'Element Engineering Australia',
  company_address: '5 Carson Rd, Malaga WA 6090',
  company_phone: '+61 8 6262 1111',
  company_email: 'info@elementengineering.com.au',
  company_website: 'https://www.elementengineering.com.au/',
  company_abn: '21 143 786 216',
};

export const TERMS_AND_CONDITIONS =
  'This quote outlines the maximum cost using information supplied at the time of quotation. Any change in the scope will result in a variation or will be time billed in addition to the quoted amount whereby the client will be informed prior. If design objectives cannot be achieved in accordance with relevant standards, the client will be notified and all hours utilised up to this point of discovery will be billed to the client. This quote is subject to Element Engineering Australias terms and conditions available upon request';
