import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivitiesProps, ClientProps, JobProps, TaggingListType } from '../../types/WorkLogTypes';

interface WorkLogProps {
  clients: Array<ClientProps>;
  jobs: Array<JobProps>;
  activities: Array<ActivitiesProps>;
  tagsList: Array<TaggingListType>;
  unsavedQuote: boolean;
  showSaveandExit: boolean;
  navigateURL: string | null;
}

const initialState: WorkLogProps = {
  jobs: [],
  clients: [],
  activities: [],
  tagsList: [],
  unsavedQuote: false,
  showSaveandExit: false,
  navigateURL: null,
};

const worklogSlice = createSlice({
  name: 'worklog',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<ClientProps[]>) => {
      state.clients = action.payload;
    },
    setActivities: (state, action: PayloadAction<ActivitiesProps[]>) => {
      state.activities = action.payload;
    },
    setJobs: (state, action: PayloadAction<JobProps[]>) => {
      state.jobs = action.payload;
    },
    setTagList: (state, action: PayloadAction<TaggingListType[]>) => {
      state.tagsList = action.payload;
    },
    setUnSavedQuotes: (
      state,
      action: PayloadAction<{ unsavedQuote?: boolean; showSaveandExit?: boolean; navigateURL?: string }>,
    ) => {
      const { unsavedQuote, showSaveandExit, navigateURL } = action.payload;
      if (unsavedQuote !== undefined) {
        state.unsavedQuote = unsavedQuote;
      }
      if (showSaveandExit !== undefined) {
        state.showSaveandExit = showSaveandExit;
      }
      if (navigateURL !== undefined) {
        state.navigateURL = navigateURL;
      }
    },
    resetUnsavedQuotes: (state) => {
      state.unsavedQuote = false;
      state.showSaveandExit = false;
      state.navigateURL = null;
    },
  },
});

export const { actions: worklogActions, reducer } = worklogSlice;

export default worklogSlice.reducer;
