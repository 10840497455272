import React, { useState } from 'react';

import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';

import CustomButton from 'components/Button';
import { FilterButtons } from 'components/GlobalStyles/styles';
import { Team } from 'services/busmanApi/types';
import { UserType } from 'types/WorkLogTypes';

import { AddMembersModal, CloseButton, EmployeeList, ModalPageContainer, SectionHeader, TeamMemberBox } from './style';

interface AddManagerProps {
  employeeList?: UserType[];
  team?: Team;
  onClose: () => void;
  updateManagers: (team: Team, managers: string[], addManager: boolean) => void;
}

const AddManagerModal = ({ employeeList, team, onClose, updateManagers }: AddManagerProps) => {
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const existignManagers = team?.managers?.map((manager) => manager.user_id);

  const filteredEmployeeList = employeeList?.filter((employee: UserType) => {
    return (
      (employee.user_role === 'M' || employee.user_role === 'A') &&
      employee.full_name.toLowerCase().includes(searchInput.toLowerCase()) &&
      !existignManagers?.includes(employee.user_id)
    );
  });

  const handleAddManagers = () => {
    setError(null);
    if (selectedEmployees.length === 0) {
      setError('Please select at least one manager to add.');
      return;
    }
    if (team) {
      updateManagers(team, selectedEmployees, true);
      onClose();
    }
  };

  const handleSelectEmployee = (selectedEmployee: string) => {
    if (selectedEmployees.includes(selectedEmployee))
      setSelectedEmployees([...selectedEmployees].filter((employee) => employee !== selectedEmployee));
    else setSelectedEmployees([...selectedEmployees, selectedEmployee]);
  };

  const renderEmployeeList = () => {
    return (
      <>
        {!employeeList?.length || (filteredEmployeeList && !filteredEmployeeList.length) ? (
          <SectionHeader>
            {filteredEmployeeList && !filteredEmployeeList.length && (
              <Typography>No employees match search..</Typography>
            )}
            {!employeeList?.length && <Typography>Loading Employees...</Typography>}
          </SectionHeader>
        ) : (
          <EmployeeList>
            {filteredEmployeeList &&
              filteredEmployeeList.map((employee: UserType) => {
                return (
                  <TeamMemberBox
                    key={employee.full_name}
                    onClick={() => handleSelectEmployee(employee.user_id)}
                    sx={
                      selectedEmployees.includes(employee.user_id)
                        ? { backgroundColor: theme.palette.busmanColors.busmanGreen }
                        : {}
                    }>
                    <Typography>{employee.full_name}</Typography>
                  </TeamMemberBox>
                );
              })}
          </EmployeeList>
        )}
      </>
    );
  };

  return (
    <ModalPageContainer open onClose={onClose}>
      <AddMembersModal onClick={(e) => e.stopPropagation()}>
        <SectionHeader>
          <Typography variant="h5">
            Add Manager(s) to <strong>{team && team.team_name}</strong> team.
          </Typography>
          <CloseButton onClick={() => onClose()} />
        </SectionHeader>
        {error !== null && (
          <Typography variant="body1" sx={{ color: theme.palette.busmanColors.busmanRed }}>
            {error}
          </Typography>
        )}

        <Box sx={{ padding: '16px 16px 16px 16px', width: '100%' }}>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            onChange={(event) => setSearchInput(event.target.value)}
            placeholder="Search Managers"
            sx={{
              height: '32px',
              mb: theme.spacing(1),
              width: '100%',
            }}
            value={searchInput}
          />
        </Box>
        {renderEmployeeList()}

        <FilterButtons sx={{ marginLeft: 'auto', padding: '16px', marginTop: 'auto' }}>
          <CustomButton variant="contained" text={'Add Managers'} width="medium" onClick={() => handleAddManagers()} />
        </FilterButtons>
      </AddMembersModal>
    </ModalPageContainer>
  );
};

export default AddManagerModal;
