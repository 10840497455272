import { Box, styled } from '@mui/material';

export const WorklogDayBox = styled(Box)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(4),
  zIndex: 0,
}));

export const WorklogDayHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));
