import CloseIcon from '@mui/icons-material/Close';
import { AccordionSummary, Box, Grid, styled } from '@mui/material';

export const TeamMemberBanner = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '2px',
  border: `1px solid ${theme.palette.background.default}`,
  height: '48px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const ManagerBanner = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '2px',
  paddingRight: '8px',
  paddingLeft: '16px',
  border: `1px solid ${theme.palette.background.default}`,
  height: '48px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

export const TeamsViewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '608px',
  maxHeight: '95vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '2px',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    height: '100dvh',
  },
}));

export const TeamMemberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 8px 8px 16px',
  borderRadius: '2px',
  border: `1px solid ${theme.palette.background.default}`,
  zIndex: 1,
}));

export const CloseButton = styled(CloseIcon)(({ theme }) => ({
  borderRadius: '50%',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  padding: '4px',
  transition: 'scale .2s ease-in-out',
  '&:hover': {
    scale: '1.2',
    color: theme.palette.busmanColors.busmanRed,
  },
}));

export const OverViewSwitchStyles = {
  backgroundColor: 'white',
  width: '50%',
  height: '100%',
  position: 'relative',
  top: '0',
  zIndex: '1',
  borderRadius: '2px',
  transition: 'transform .5s',
};

export const PerformanceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '336px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '2px',
}));

export const ProductivityBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.background.default}`,
  height: '140px',
  width: '100%',
}));

export const MainTeamContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
  padding: '16px 16px 0px 16px',
}));

export const PodAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '2px',
  borderBottom: 0,
  border: '1px solid',
  borderColor: theme.palette.background.default,
  maxHeight: '40px',
  position: 'sticky',
  top: '0',
  display: 'flex',
  zIndex: 1,
  paddingLeft: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.Mui-expanded': {
    maxHeight: '40px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));
