import { busmanApiSlice } from './busmanApiSlice';
import { GetEmployeeDetailsResponse, GetManagerListResponse, GetTaggingListJobsResponse } from './types';

export const userEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeDetails: builder.query({
      query: (empID) => `/user/?employee_id=${empID}`,
      transformResponse: (response: GetEmployeeDetailsResponse) => response.data,
      providesTags: ['User'],
    }),

    // Update Employee Details
    updateEmployeeDetail: builder.mutation({
      query: (employeeDetails) => {
        return {
          url: 'user/update/',
          method: 'put',
          body: employeeDetails,
        };
      },
      invalidatesTags: ['Team', 'Worklog'],
    }),

    //Fetch all managers
    getManagerList: builder.query({
      query: () => '/user/managersadmins/',
      transformResponse: (response: GetManagerListResponse) => response.data,
    }),

    //Get Tagging List - Worklogs
    getTaggingListWorklogs: builder.query({
      query: () => '/user/worklog/tags/',
      transformResponse: (response: GetTaggingListJobsResponse) => response.data,
    }),

    //Get Tagging List - Jobs
    getTaggingListJobs: builder.query({
      query: () => '/user/job/tags/',
      transformResponse: (response: GetTaggingListJobsResponse) => response.data,
    }),
  }),
});

export const {
  useGetManagerListQuery,
  useGetTaggingListWorklogsQuery,
  useLazyGetTaggingListJobsQuery,
  useLazyGetTaggingListWorklogsQuery,
  useGetTaggingListJobsQuery,
  useUpdateEmployeeDetailMutation,
  useLazyGetEmployeeDetailsQuery,
} = userEndpoints;
