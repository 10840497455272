import { Button, Grid, styled } from '@mui/material';

export const QuotesGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid',
  borderColor: theme.palette.background.default,
  cursor: 'pointer',
  width: '100%',
  minHeight: '64px',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const QuoteButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '132px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
