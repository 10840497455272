export const MuiMenuItem = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '12px',
        minHeight: '32px',
      },
    },
  },
};
