import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { ExtendedWorklogDayType, ExtendedWorklogType, WorklogDayType, WorklogType } from 'types/WorkLogTypes';
import { generateDateRange, SortWorkLogsByAscDate, SortWorkLogsByDescDate } from 'utils/date';

dayjs.extend(isSameOrBefore);

export const findMissingLogDays = (sortedWorklogs: WorklogDayType[], fromDate: Dayjs | null, toDate: Dayjs | null) => {
  const actualLogDates = sortedWorklogs.map(({ date }) => date);
  const selectedDateRange = generateDateRange(fromDate, toDate);
  const publicHolidayData = localStorage.getItem('public-holiday');
  const holidays = publicHolidayData ? JSON.parse(publicHolidayData) : [];
  const parsedHolidayData = holidays.map((holiday: string) => dayjs(holiday).format('MM-DD'));

  const missingLogs = selectedDateRange?.filter(
    (date) =>
      !actualLogDates.includes(date) &&
      dayjs(date).day() !== 0 &&
      dayjs(date).day() !== 6 &&
      !parsedHolidayData.includes(dayjs(date).format('MM-DD')),
  );

  return missingLogs;
};

// Regroup the logs by date
const regroupLogsByDate = (logs: WorklogType[]) => {
  const regroupedLogs = logs.reduce((result: ExtendedWorklogDayType[], log: ExtendedWorklogType) => {
    const existingEntryIndex = result.findIndex((entry: ExtendedWorklogDayType) => entry.date === log.date);
    if (existingEntryIndex !== -1) {
      result[existingEntryIndex].worklogs.push(log);
    } else {
      result.push({ date: log.date, startDate: log.startDate, endDate: log.endDate, worklogs: [log] });
    }
    return result;
  }, []);

  return regroupedLogs;
};

const checkDiff = (current: string, next: string) => {
  const currentDate = dayjs(current);
  const nextDate = dayjs(next);
  const diff = Math.abs(nextDate.diff(currentDate, 'day'));
  const publicHolidayData = localStorage.getItem('public-holiday');
  const holidays = publicHolidayData ? JSON.parse(publicHolidayData) : [];
  const parsedHolidayData = holidays.map((holiday: string) => dayjs(holiday).format('MM-DD'));

  if (diff === 1) return true;

  const isWeekendOrHoliday = (date: Dayjs) =>
    date.day() === 0 || date.day() === 6 || parsedHolidayData.includes(dayjs(date).format('MM-DD'));

  // Check if any inbetween dates are weekends or holidays
  for (let i = 1; i < diff; i++) {
    const inbetweenDate = currentDate.add(i, 'day');

    if (!isWeekendOrHoliday(inbetweenDate)) {
      return false;
    }
  }

  return true;
};

const isSameConsecutiveLeaveLog = (currentLog: any, nextLog: any) => {
  if (
    currentLog.activity.activity_id === nextLog.activity.activity_id &&
    dayjs(currentLog.start_time).hour() === dayjs(nextLog.start_time).hour() &&
    dayjs(currentLog.end_time).hour() === dayjs(nextLog.end_time).hour() &&
    currentLog.status === nextLog.status &&
    currentLog.description === nextLog.description &&
    checkDiff(currentLog.date, nextLog.date)
  )
    return true;

  return false;
};

export const groupLogs = (logs: WorklogDayType[]) => {
  const groupedLogs = [];
  let currentGroup: any = null;
  const allLogs = [...logs]?.sort(SortWorkLogsByAscDate).flatMap((item: WorklogDayType) => item.worklogs);

  for (let i = 0; i < allLogs.length; i++) {
    const currentLog = allLogs[i];
    const nextLog = allLogs[i + 1];

    if (currentLog.worklog_type === 'Worklog') groupedLogs.push(currentLog);

    if (
      currentLog.worklog_type === 'LeaveLog' &&
      nextLog?.worklog_type === 'LeaveLog' &&
      currentLog.activity.activity_id === nextLog.activity.activity_id &&
      isSameConsecutiveLeaveLog(currentLog, nextLog)
    ) {
      if (!currentGroup) {
        currentGroup = {
          ...currentLog,
          startDate: currentLog.date,
          endDate: nextLog.date,
          groupedIds: [currentLog.slip_id, nextLog.slip_id],
        };
      } else {
        currentGroup = {
          ...currentGroup,
          endDate: nextLog.date,
          groupedIds: [...currentGroup.groupedIds, nextLog.slip_id],
        };
      }
    } else if (currentGroup) {
      groupedLogs.push(currentGroup);
      currentGroup = null;
    } else groupedLogs.push(currentLog);
  }

  return regroupLogsByDate(groupedLogs).sort(SortWorkLogsByDescDate);
};
