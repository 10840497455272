/* eslint-disable @typescript-eslint/indent */
/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import { BMIconDark, BMIconLight } from 'images';
import { RootState } from 'store';

import { SvgIconComponent } from '@mui/icons-material';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import WorklogIcon from '@mui/icons-material/AccessTimeFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorklogManagerIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClientsIcon from '@mui/icons-material/Contacts';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import LockIcon from '@mui/icons-material/Lock';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserIcon from '@mui/icons-material/Person';
import QuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/SpaceDashboard';
import TeamIcon from '@mui/icons-material/SupervisorAccount';
import JobIcon from '@mui/icons-material/Work';
import WorkIcon from '@mui/icons-material/Work';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Drawer,
  Fade,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import List from '@mui/material/List';

import BusinessInsightsIcon from 'components/BusinessInsightsIcon';
import MissingLogIcon from 'components/MissingLogIcon';
import { authActions } from 'features/Auth/authSlice';
import { filterPanelActions } from 'features/FilterPanelSlice/filterPanelSlice';

import { ListItemBtn, MenuContent, MobAppBar } from './styles';

interface AppBarIcons {
  label: string;
  Icon: SvgIconComponent;
  permission: string | string[];
}

const MobileAppBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openMenu, setOpenMenu] = React.useState<string>('');
  const { user } = useSelector((state: RootState) => state.auth);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const { selectedEmployee } = useSelector((state: RootState) => state.employee);
  const hideFilter =
    pathname === '/' ||
    pathname === '/changepassword' ||
    pathname.includes('/clients/') ||
    pathname === '/myobconfigure';

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getDrawerContent = (menu: string) => {
    switch (menu) {
      case 'Me':
        return [
          { label: 'Dashboard', Icon: DashboardIcon, permission: 'All' },
          { label: 'Worklogs', Icon: WorklogIcon, permission: 'All' },
        ];

      case 'Client':
        return [
          { label: 'Clients', Icon: ClientsIcon, permission: ['A', 'M'] },
          { label: 'Jobs', Icon: JobIcon, permission: ['A', 'M'] },
          { label: 'Business Insights', Icon: BusinessInsightsIcon, permission: ['A', 'M'] },
        ];

      case 'Teams':
        return [
          { label: 'Teams', Icon: TeamIcon, permission: ['A', 'M'] },
          { label: 'Worklog Manager', Icon: WorklogManagerIcon, permission: ['A', 'M'] },
          { label: 'Missing Logs', Icon: MissingLogIcon, permission: ['A', 'M'] },
        ];

      case 'Setting':
        return [{ label: 'MYOB Configure', Icon: SettingsIcon, permission: ['A'] }];

      case 'Account':
        return [
          { label: 'Change Password', Icon: LockIcon, permission: 'All' },
          { label: 'Logout', Icon: ExitToAppRoundedIcon, permission: 'All' },
        ];

      default:
        return null;
    }
  };

  const appBarTopIcons = [
    { label: 'Me', Icon: UserIcon, permission: 'All' },
    { label: 'Teams', Icon: GroupsIcon, permission: ['A', 'M'] },
    { label: 'Client', Icon: WorkIcon, permission: ['A', 'M'] },
  ];

  const appBarBottomIcons = [
    { label: 'Setting', Icon: SettingsIcon, permission: ['A'] },
    { label: 'Notifications', Icon: NotificationsIcon, permission: 'All' },
    { label: 'Account', Icon: AccountCircleIcon, permission: 'All' },
  ];

  const drawerContent = [
    { label: 'Dashboard', Icon: DashboardIcon, permission: 'All' },
    { label: 'Worklogs', Icon: WorklogIcon, permission: 'All' },
    { label: 'Worklog Manager', Icon: WorklogManagerIcon, permission: ['A', 'M'] },
    { label: 'Missing Logs', Icon: MissingLogIcon, permission: ['A', 'M'] },
    { label: 'Clients', Icon: ClientsIcon, permission: ['A', 'M'] },
    { label: 'Jobs', Icon: JobIcon, permission: ['A', 'M'] },
    { label: 'MYOB Configure', Icon: SettingsIcon, permission: ['A'] },
    { label: 'Notifications', Icon: NotificationsIcon, permission: 'All' },
    { label: 'Change Password', Icon: LockIcon, permission: 'All' },
    { label: 'Logout', Icon: ExitToAppRoundedIcon, permission: 'All' },
    { label: 'Teams', Icon: TeamIcon, permission: ['A', 'M'] },
    { label: 'UserDetail', Icon: ExitToAppRoundedIcon, permission: 'All' },
    { label: 'Business Insights', Icon: BusinessInsightsIcon, permission: ['A', 'M'] },
  ];

  const getUrl = (id: string) => {
    let url;
    switch (id) {
      case 'id-Dashboard':
        url = '/';
        break;
      case 'id-Worklogs':
        url = '/worklogs';
        break;
      case 'id-Notifications':
        url = '/notifications';
        break;
      case 'id-Worklog Manager':
        url = '/worklogmanager';
        break;
      case 'id-Missing Logs':
        url = '/missinglogs';
        break;
      case 'id-Clients':
        url = '/clients';
        break;
      case 'id-Jobs':
        url = '/jobs';
        break;
      case 'id-Cost Tracker':
        url = '/jobs/costs';
        break;
      case 'id-Quotes':
        url = '/quotes';
        break;
      case 'id-MYOB Configure':
        url = '/myobconfigure';
        break;
      case 'id-Teams':
        url = '/teams';
        break;
      case 'id-Change Password':
        url = '/changepassword';
        break;
      case 'id-Business Insights':
        url = '/businessinsights';
        break;
      case 'id-Logout':
        dispatch(authActions.resetCredentials());
        break;
      default:
        url = null;
        break;
    }

    return url;
  };

  const getHeader = () => {
    if (pathname.startsWith('/employeeworklogs') && selectedEmployee) {
      return `${selectedEmployee.full_name}'s Worklogs`;
    }
    return pathname === '/'
      ? 'Dashboard'
      : drawerContent.find(({ label }) => pathname.toLowerCase().includes(label.toLocaleLowerCase().replace(' ', '')))
          ?.label;
  };

  const handleSubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    const url = getUrl(event.currentTarget.id);

    if (url) navigate(url);
    setOpenDrawer(false);
  };

  const RenderListItem = ({ label, Icon, permission }: AppBarIcons) => {
    if (permission === 'All' || permission.includes(user.user_role)) {
      return (
        <ListItemBtn
          key={label}
          id={`id-${label}`}
          onClick={handleSubMenuClick}
          selected={pathname.includes(label.toLocaleLowerCase())}
        >
          <ListItemIcon
            sx={{
              minWidth: '24px',
              marginRight: '12px',
              color: 'inherit',
            }}
          >
            {Icon && <Icon sx={{ padding: 'none', color: 'inherit' }} />}
          </ListItemIcon>
          <ListItemText sx={{ margin: 0 }}>
            <Typography variant="h3">{label}</Typography>
          </ListItemText>
        </ListItemBtn>
      );
    }
    return null;
  };

  const RenderListAccordion = ({ label, Icon, permission }: AppBarIcons) => {
    if (permission === 'All' || permission.includes(user.user_role)) {
      return (
        <Accordion
          key={label}
          disableGutters
          expanded={openMenu === label}
          onClick={(e) => {
            e.stopPropagation();
            setOpenMenu(openMenu === label ? '' : label);
          }}
          sx={{
            backgroundColor: 'transparent',
            color: openMenu === label ? theme.palette.primary.contrastText : theme.palette.primary.light,
            borderBottom: 'none',
            padding: 0,
          }}
        >
          <AccordionSummary
            expandIcon={
              openMenu === label && (
                <ArrowForwardIosSharp
                  sx={{
                    fontSize: '0.9rem',
                    color: openMenu === label ? theme.palette.primary.contrastText : theme.palette.primary.light,
                  }}
                />
              )
            }
            sx={{
              display: 'flex',
              width: '100%',
              paddingInline: theme.spacing(3),
              borderRadius: '2px',
              borderColor: theme.palette.background.default,
              maxHeight: '40px',
              position: 'sticky',
              top: '0',
              zIndex: '999',
              '&.Mui-expanded': {
                maxHeight: '40px',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
              },
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '24px',
                marginRight: '12px',
                color: 'inherit',
              }}
            >
              {Icon && <Icon sx={{ padding: 'none', color: 'inherit' }} />}
            </ListItemIcon>
            <ListItemText sx={{ alignSelf: 'end', margin: 0, height: '100%' }}>
              <Typography variant="h3" color={'inherit'}>
                {label}
              </Typography>
            </ListItemText>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <List sx={{ backgroundColor: '#3B3B3B' }}>
              {getDrawerContent(label)?.map(({ label: itemLabel, Icon: SubIcon, permission: itemPermission }) => {
                if (itemPermission === 'All' || itemPermission.includes(user.user_role)) {
                  return (
                    <ListItemButton
                      key={itemLabel}
                      id={`id-${itemLabel}`}
                      onClick={handleSubMenuClick}
                      sx={{
                        color: theme.palette.primary.light,
                        ':hover': {
                          color: theme.palette.primary.contrastText,
                        },
                        minHeight: 48,
                        justifyContent: 'center',
                        paddingInline: theme.spacing(4),
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '24px',
                          marginRight: '12px',
                          color: theme.palette.primary.light,
                        }}
                      >
                        {SubIcon && <SubIcon sx={{ padding: 'none' }} />}
                      </ListItemIcon>
                      <ListItemText
                        primary={itemLabel}
                        sx={{
                          '& .MuiListItemText-primary': {
                            fontSize: '14px',
                          },
                        }}
                      />
                    </ListItemButton>
                  );
                }
                return null;
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      );
    }
    return null;
  };

  return (
    <MobAppBar
      sx={{
        backgroundColor: openDrawer ? theme.palette.primary.main : theme.palette.background.default,
        borderBottom: openDrawer ? '1px solid #3B3B3B' : '0px solid #3B3B3B',
      }}
    >
      <Box display={'flex'} gap={theme.spacing(2)} alignItems={'center'}>
        <Link to={'/'}>
          <Avatar
            sx={{ width: '6vw', height: '100%', borderRadius: '0', ml: '16px' }}
            alt="Busmin Icon"
            src={openDrawer ? BMIconLight : BMIconDark}
            onClick={() => setOpenDrawer(false)}
          />
        </Link>
        {openDrawer ? (
          <Typography variant="h6" sx={{ color: theme.palette.primary.light, lineHeight: 'auto' }}>
            {user.full_name.toUpperCase()}
          </Typography>
        ) : (
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
            {getHeader()}
          </Typography>
        )}
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={'10px'}>
        {!openDrawer && !hideFilter && (
          <IconButton
            onClick={() => dispatch(filterPanelActions.setFilterPanelState(!filterPanel))}
            sx={{ padding: 0 }}
          >
            {filterPanel ? (
              <FilterAltOutlinedIcon sx={{ color: theme.palette.primary.light, padding: 0, height: '22px' }} />
            ) : (
              <FilterAltOffOutlinedIcon sx={{ color: theme.palette.primary.light, padding: 0, height: '22px' }} />
            )}
          </IconButton>
        )}
        <IconButton sx={{ padding: '0' }}>
          <Hamburger
            toggled={openDrawer}
            color={openDrawer ? theme.palette.primary.light : theme.palette.primary.light}
            toggle={handleOpenDrawer}
            size={18}
          />
        </IconButton>
      </Box>
      <Drawer
        variant="temporary"
        // hideBackdrop
        disableScrollLock
        transitionDuration={{ enter: 300, exit: 300 }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.primary.main,
            height: '94.5dvh',
            width: 'auto',
            top: '6vh',
            left: 0,
            paddingY: theme.spacing(2),
          },
        }}
        anchor="top"
        open={openDrawer}
      >
        <Fade in={openDrawer}>
          <MenuContent>
            <Box>
              {appBarTopIcons.map(({ label, Icon, permission }) => {
                return <RenderListAccordion key={label} label={label} Icon={Icon} permission={permission} />;
              })}
            </Box>
            <Box>
              {appBarBottomIcons.map(({ label, Icon, permission }) => {
                if (label === 'Notifications') {
                  return <RenderListItem key={label} label={label} Icon={Icon} permission={permission} />;
                }
                return <RenderListAccordion key={label} label={label} Icon={Icon} permission={permission} />;
              })}
            </Box>
          </MenuContent>
        </Fade>
      </Drawer>
    </MobAppBar>
  );
};

export default MobileAppBar;
