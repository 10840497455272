import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, styled } from '@mui/material';

export const ModalPageContainer = styled(Modal)(() => ({
  width: '100%',
  height: '100%',
  padding: '40px',
  background: 'rgba(40, 40, 40, 0.80)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  position: 'absolute',
  zIndex: '999',
  backdropFilter: 'blur(2px)',
}));

export const AddMembersModal = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 2,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
  width: '496px',
  maxHeight: 'fit-content',
  minHeight: '479px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const AddPodModal = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 2,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
  width: '496px',
  maxHeight: 'fit-content',
  minHeight: '278px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const AddTeamModal = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 2,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
  width: '496px',
  height: 'fit-content',
  minHeight: '448px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '16px',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const CloseButton = styled(CloseIcon)(({ theme }) => ({
  borderRadius: '50%',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  padding: '4px',
}));

export const TeamMemberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 8px 12px 16px',
  borderRadius: '2px',
  border: `1px solid ${theme.palette.background.default}`,
  backgroundColor: theme.palette.background.default,
}));

export const EmployeeList = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '16px 16px 16px 16px',
  gap: '8px',
  overflowY: 'auto',
  maxHeight: '300px',
}));
