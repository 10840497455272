import * as React from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem } from '@mui/material';

interface CommentCardMenuProps {
  onEdit: () => void;
  onDelete: () => void;
}

const CommentCardMenu = ({ onEdit, onDelete }: CommentCardMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="comment-menu" sx={{ padding: 0 }} onClick={(e) => onMenuClick(e)}>
        <MoreHorizIcon sx={{ height: '16px', width: '16px' }} />
      </IconButton>
      <Menu id="comment-card-menu" anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        <MenuItem onClick={onDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default CommentCardMenu;
