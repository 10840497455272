import { busmanApiSlice } from './busmanApiSlice';
import { GetTeamsResponse, GetTeamWorklogResponse, GetUserListResponse } from './types';

export const teamsEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: () => '/managed/teams/user-list/',
      transformResponse: (response: GetUserListResponse) => response.data,
    }),

    // Fetches all teams
    getTeams: builder.query({
      query: (empID) => `/managed/teams/?manager_id=${empID}`,
      transformResponse: (response: GetTeamsResponse) => response.data,
      providesTags: ['Team'],
    }),

    getTeamWorklogCount: builder.query({
      query: ({ startDate, endDate }) => `/managed/teams/worklogcount/?&start_date=${startDate}&end_date=${endDate}`,
      transformResponse: (response: GetTeamWorklogResponse) => response.data,
      providesTags: ['WorklogCount'],
    }),

    //Create new team
    addTeam: builder.mutation({
      query: (newTeam) => ({
        url: 'managed/teams/',
        method: 'post',
        body: newTeam,
      }),
      invalidatesTags: ['Team'],
    }),

    //Add member to existing team
    addTeamMember: builder.mutation({
      query: (newMember) => ({
        url: 'managed/teams/addmember/',
        method: 'post',
        body: newMember,
      }),
      invalidatesTags: ['Team'],
    }),

    //Add/Update Managers
    updateTeamManager: builder.mutation({
      query: (data) => ({
        url: 'managed/teams/update-manager/',
        method: 'put',
        body: data,
      }),
      invalidatesTags: ['Team'],
    }),

    deleteTeam: builder.mutation({
      query: (teamID) => ({
        url: 'managed/teams/',
        method: 'delete',
        body: { team_id: teamID },
      }),
      invalidatesTags: ['Team'],
    }),

    deleteTeamMember: builder.mutation({
      query: (data) => ({
        url: 'managed/teams/deletemember/',
        method: 'delete',
        body: data,
      }),
      invalidatesTags: ['Team'],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetTeamWorklogCountQuery,
  useGetUserListQuery,
  useAddTeamMemberMutation,
  useAddTeamMutation,
  useUpdateTeamManagerMutation,
  useDeleteTeamMutation,
  useDeleteTeamMemberMutation,
} = teamsEndpoints;
