export const MuiAccordion = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderBottom: '1px solid #F8F9FA',
        '&:before': {
          height: 0,
        },
      },
    },
  },
};
