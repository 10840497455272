import * as React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import UserTagging from 'features/UserTagging';
import { CommentType } from 'types/WorkLogTypes';

import DeleteConfirmationDialog from '../../../components/DeleteConfirmationDialog';
import { RootState } from '../../../store';

import CommentCardMenu from './CommentCardMenu';
import { CommentCardContainer } from './styles';

interface CommentCardProps {
  comment: CommentType;
  deleteComment: (commentId: string) => Promise<string> | void;
  updateComment: (
    updatedComment: string,
    commentId: string,
    updatedTaggedUsers?: (string | null)[],
  ) => Promise<string> | void;
  error: string;
  showError: (errorMsg: string) => void;
  color?: string | null | undefined;
}

const CommentCard = ({ comment, deleteComment, updateComment, error, showError, color }: CommentCardProps) => {
  const theme = useTheme();
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [updatedComment, setUpdatedComment] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [updatedTags, setUpdatedTags] = React.useState<(string | null)[]>([]);
  const openMenu = Boolean(anchorEl);

  const { user } = useSelector((state: RootState) => state.auth);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommentUpdate = async () => {
    if (updatedComment === '') return;

    const result = await updateComment(updatedComment, comment.comment_id, updatedTags);

    if (result === 'error') showError('Error updating comment !');

    if (result === 'success') {
      setEditable(false);
      setUpdatedComment('');
    }
  };

  const handleDeleteComment = async () => {
    setOpenDeleteModal(false);
    const result = await deleteComment(comment.comment_id);

    if (result === 'error') showError('Error deleting comment !');
  };

  const handleCancel = async () => {
    setUpdatedComment('');
    setEditable(false);
  };

  const onClickDelete = () => {
    if (error) showError('');
    setOpenDeleteModal(true);
  };

  const onClickEdit = async () => {
    if (error) showError('');
    setEditable(true);
    setUpdatedComment(comment.comment_description);
  };

  const isValidUser = (id: string) => {
    return user?.user_id === id;
  };

  const commentTimeStamp =
    dayjs(comment.updated_at).format('YYYY-MM-DD HH:mm a') === dayjs(comment.created_at).format('YYYY-MM-DD HH:mm a')
      ? dayjs(comment.created_at).format('YYYY-MM-DD HH:mm a')
      : `${dayjs(comment.updated_at).format('YYYY-MM-DD HH:mm a')} - Edited `;

  return (
    <Box>
      <Box
        key={comment.comment_id}
        sx={{ display: 'flex', width: '100%', alignItem: 'center', mt: theme.spacing(2), gap: theme.spacing(1) }}>
        <AccountCircleIcon sx={{ color: !color || color === '#FFFFFF' ? '#9E9E9E' : '#FFFFFF' }} />
        <CommentCardContainer
          sx={{ backgroundColor: editable ? theme.palette.background.paper : theme.palette.primary.contrastText }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant={editable ? 'subtitle2' : 'subtitle2'}>
              {`${comment.user?.full_name} - ${commentTimeStamp}`}
            </Typography>
            {isValidUser(comment?.user?.user_id) && !editable && (
              <CommentCardMenu onEdit={onClickEdit} onDelete={onClickDelete} />
            )}
          </Box>
          <UserTagging
            setNewComment={setUpdatedComment}
            setTaggedUsers={setUpdatedTags}
            commentValue={editable ? updatedComment : comment.comment_description}
            readOnly={editable ? true : false}
            editMode={editable ? true : false}
            handleCommentUpdate={handleCommentUpdate}
            handleCancel={handleCancel}
            existingComment={true}
          />
        </CommentCardContainer>
      </Box>
      <Menu id="comment-card-menu" anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
        <MenuItem onClick={onClickEdit}>Edit</MenuItem>
        <MenuItem onClick={onClickDelete}>Delete</MenuItem>
      </Menu>
      {openDeleteModal && (
        <DeleteConfirmationDialog
          title="Confirm Deletion"
          description="Delete Comment?"
          onClose={() => setOpenDeleteModal(false)}
          onDelete={() => handleDeleteComment()}
        />
      )}
    </Box>
  );
};

export default CommentCard;
