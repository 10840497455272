import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Add as AddIcon } from '@mui/icons-material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DataNotFound from 'components/DataNotFound';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog';
import { FilterButtons, FilterPanelContainer, HeaderBack, PageContainer } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import InfoDialog from 'components/InfoDialog';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import { employeeActions } from 'features/Employee/EmployeeSlice';
import AddEmployeeModal from 'pages/TeamDetail/Components/AddEmployeeModal';
import AddNewPod from 'pages/TeamDetail/Components/AddNewPod';
import AddNewTeam from 'pages/TeamDetail/Components/AddNewTeamModal';
import { useGetTeamsQuery, useGetUserListQuery } from 'services/busmanApi';
import { useDeleteTeamMemberMutation, useDeleteTeamMutation } from 'services/busmanApi';
import { useUpdateTeamManagerMutation } from 'services/busmanApi';
import { Member, Team } from 'services/busmanApi/types';

import { RootState } from '../../store';

import AddManagerModal from './Components/AddManagerModal';
import { SectionHeader } from './Components/style';
import { CloseButton, MainTeamContainer, PodAccordionSummary, TeamMemberBanner, TeamsViewContainer } from './style';

interface DeleteMemberType {
  team_id?: string;
  member_id?: string;
  is_manager: boolean;
  is_team: boolean;
}

interface DeleteManagerType {
  parentTeam: Team;
  managerID: string;
}

const TeamDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamID } = useParams();
  const [selectedTeamData, setSelectedTeamData] = useState<Team | undefined>();
  const [editMode, setEditMode] = useState(false);
  const [modalView, setModalView] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState<DeleteMemberType>();
  const [selectedManager, setSelectedManager] = useState<DeleteManagerType>();
  const [selectedPod, setSelectedPod] = useState<Team | undefined>();
  const [openTeams, setOpenTeams] = useState<string[]>([]);
  const [message, setMessage] = useState<MessageType | null>(null);
  const [searchText, setSearchText] = useState('');

  const { user } = useSelector((state: RootState) => state.auth);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isAdmin = user?.user_role === 'A';

  const { data: teams, isFetching } = useGetTeamsQuery(user?.user_id);
  const { data: userList } = useGetUserListQuery({});
  const [deleteTeam] = useDeleteTeamMutation();
  const [deleteTeamMember] = useDeleteTeamMemberMutation();
  const [updateManagers] = useUpdateTeamManagerMutation();

  useEffect(() => {
    const filteredTeam = teams?.find((team) => team.team_id === teamID);
    setSelectedTeamData(filteredTeam);
    if (!isFetching && filteredTeam === undefined) {
      setModalView('noTeam');
    }
  }, [teams, teamID, isFetching]);

  const handleSetModalView = (modal: string) => {
    setModalView(modal);
  };

  const handleClickDelete = () => {
    const hasChildTeam = selectedTeamData?.members.some((memeber) => memeber.is_team);
    setModalView(hasChildTeam ? 'Info' : 'deleteTeam');
  };

  const handleCloseModal = () => {
    setModalView('');
  };

  const handleDeleteMember = (member_id: string, is_team: boolean, is_manager: boolean, team_id?: string) => {
    if (is_team) {
      setSelectedEmployee({ team_id: teamID, member_id: member_id, is_manager: is_manager, is_team: is_team });
      setModalView('deletePod');
    } else {
      setSelectedEmployee({ team_id: team_id, member_id: member_id, is_manager: is_manager, is_team: is_team });
      setModalView('deleteMember');
    }
  };

  const handleDeleteManager = (parentTeam: Team, managerID: string) => {
    setSelectedManager({ parentTeam: parentTeam, managerID: managerID });
    setModalView('deleteManager');
  };

  const handleUpdateManagers = async (team: Team, manager_id: string[], addManager: boolean) => {
    if (team.managers.length === 1 && !addManager) {
      handleCloseModal();
      return setMessage({ type: 'error', msg: 'Team must have at least one manager' });
    }
    const body = {
      team_id: team.team_id,
      manager_id: addManager
        ? [...team.managers.map((manager) => manager.user_id), ...manager_id]
        : [...team.managers.filter((manager) => manager.user_id !== manager_id[0]).map((manager) => manager.user_id)],
    };
    const response = await updateManagers(body);

    if ('error' in response) {
      if (addManager) setMessage({ type: 'error', msg: 'Manager was not added' });
      else setMessage({ type: 'error', msg: 'Manager was not deleted' });
    }

    if ('data' in response) {
      if (addManager) setMessage({ type: 'success', msg: 'Manager Succesfully Added' });
      else setMessage({ type: 'success', msg: 'Manager Succesfully Deleted' });
    }

    handleCloseModal();
  };

  const handleDeleteMemberConfirm = async () => {
    const response = await deleteTeamMember(selectedEmployee);

    if ('error' in response) {
      setMessage({ type: 'error', msg: 'Member was not deleted' });
    }

    if ('data' in response) {
      setMessage({ type: 'success', msg: 'Succesfully Deleted' });
    }
    handleCloseModal();
  };

  const handleDeleteTeam = async (team_id: string | undefined) => {
    const response = await deleteTeam(team_id);

    if ('error' in response) {
      setMessage({ type: 'error', msg: 'Team was not deleted' });
    }

    if ('data' in response) {
      setMessage({ type: 'success', msg: 'Team Succesfully Deleted' });
      navigate('/teams');
    }
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const checkSearchedName = (team: Team): boolean => {
    // Check if the name exists in the direct team members
    const isDirectEmployee = team.members.some((member: Member) => {
      return !member.is_team && member.employee?.full_name?.toLowerCase().includes(searchText.toLowerCase());
    });

    if (isDirectEmployee) {
      return true;
    }

    // Search for the name in each sub-team
    const isFoundInSubTeams = team.members
      .filter((member) => member.is_team && member.team)
      .some((subTeamMember) => checkSearchedName(subTeamMember.team));

    return isFoundInSubTeams;
  };

  const handleModalView = () => {
    switch (modalView) {
      case 'noTeam':
        return <DataNotFound errorMessage="Team not found" url="teams" />;
      case 'newTeam':
        return <AddNewTeam onClose={handleCloseModal} setMessage={setMessage} />;
      case 'newPod':
        return (
          <AddNewPod
            managerId={user && user?.user_id}
            team={selectedTeamData}
            onClose={handleCloseModal}
            setMessage={setMessage}
            employeeList={userList}
          />
        );
      case 'newTeamMember':
        return (
          <AddEmployeeModal
            employeeList={userList}
            pod={selectedPod}
            onClose={handleCloseModal}
            setMessage={setMessage}
          />
        );
      case 'addManager':
        return (
          <AddManagerModal
            employeeList={userList}
            team={selectedPod}
            updateManagers={handleUpdateManagers}
            onClose={handleCloseModal}
          />
        );
      case 'deletePod':
        return (
          <DeleteConfirmationDialog
            title="Delete Pod"
            description="Are you sure you want to delete this pod?"
            onClose={handleCloseModal}
            onDelete={() => handleDeleteMemberConfirm()}
          />
        );
      case 'deleteMember':
        return (
          <DeleteConfirmationDialog
            title="Delete Member"
            description="Are you sure you want to delete this member?"
            onClose={handleCloseModal}
            onDelete={() => handleDeleteMemberConfirm()}
          />
        );
      case 'deleteManager':
        return (
          <DeleteConfirmationDialog
            title="Delete Manager"
            description="Are you sure you want to delete this Manager?"
            onClose={handleCloseModal}
            onDelete={() =>
              selectedManager && handleUpdateManagers(selectedManager.parentTeam, [selectedManager.managerID], false)
            }
          />
        );
      case 'deleteTeam':
        return (
          <DeleteConfirmationDialog
            title="Delete Team"
            description="Are you sure you want to delete this team?"
            onClose={handleCloseModal}
            onDelete={() => handleDeleteTeam(teamID)}
          />
        );
      case 'Info':
        return (
          <InfoDialog
            title="Cannot Delete Team"
            description="Please delete child team first in order to proceed with parent team deletion."
            onClose={handleCloseModal}
          />
        );
      default:
        return;
    }
  };

  const getUserRole = (role: string) => {
    switch (role) {
      case 'A':
        return 'Admin';
      case 'M':
        return 'Manager';
      case 'E':
        return 'Employee';
    }
  };

  const renderEmployee = (team: Team, member: Member) => {
    const hasSearchText = member.employee.full_name?.toLowerCase().includes(searchText.toLowerCase());

    if (searchText && !hasSearchText) {
      return null;
    }

    return (
      hasSearchText && (
        <Box
          key={member.employee.full_name}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <TeamMemberBanner container justifyContent="space-between">
            <GridItem title={member.employee.full_name} seenOrIsHeading={false} breakpoint={7} />
            <GridItem title={getUserRole(member.employee.user_role)} seenOrIsHeading={false} breakpoint={3} />
            {editMode ? (
              <CloseButton
                onClick={() => handleDeleteMember(member.employee.user_id, false, false, team.team_id)}
                sx={{
                  color: theme.palette.text.disabled,
                  marginLeft: 'auto',
                  marginRight: theme.spacing(1),
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            ) : (
              <ArrowForwardIos
                onClick={() => {
                  dispatch(employeeActions.setSelectedEmployee(member.employee));
                  navigate(`/userDetail/${member.employee.user_id}`);
                }}
                sx={{
                  color: theme.palette.text.disabled,
                  fontSize: '0.8rem',
                  marginLeft: 'auto',
                  marginRight: theme.spacing(2),
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            )}
          </TeamMemberBanner>
        </Box>
      )
    );
  };

  const renderManagers = (team: Team) => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* {editMode && (
            <ManagerBanner container justifyContent="space-between">
              <Typography variant="h6">Managers</Typography>
              {editMode && (
                <CustomButton
                  variant="contained"
                  text={<AddIcon />}
                  width="small"
                  onClick={() => {
                    setSelectedPod(team);
                    setModalView('addManager');
                  }}
                  toolTip={`Add Manager to ${team.team_name} `}
                />
              )}
            </ManagerBanner>
          )} */}
          {team.managers.length > 0 && (
            <Grid container display="flex" flexDirection="row" justifyContent="flex-start" sx={{ marginBottom: '8px' }}>
              <GridItem title={'MANAGER'} seenOrIsHeading={true} breakpoint={7} />
              <GridItem title={'ROLE'} seenOrIsHeading={true} breakpoint={3} />
            </Grid>
          )}
          {team?.managers.map((manager) => {
            return (
              <TeamMemberBanner container justifyContent="space-between" key={manager.user_id}>
                <GridItem title={manager.full_name} seenOrIsHeading={false} breakpoint={7} />
                <GridItem title={'Manager'} seenOrIsHeading={false} breakpoint={3} />
                {editMode ? (
                  <CloseButton
                    onClick={() => handleDeleteManager(team, manager.user_id)}
                    sx={{
                      color: theme.palette.text.disabled,
                      marginLeft: 'auto',
                      marginRight: theme.spacing(1),
                    }}
                  />
                ) : (
                  <ArrowForwardIos
                    onClick={() => {
                      dispatch(employeeActions.setSelectedEmployee(manager));
                      navigate(`/userDetail/${manager.user_id}`);
                    }}
                    sx={{
                      color: theme.palette.text.disabled,
                      fontSize: '0.8rem',
                      marginLeft: 'auto',
                      marginRight: theme.spacing(2),
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  />
                )}
              </TeamMemberBanner>
            );
          })}
        </Box>
      </>
    );
  };

  const teamContainer = (team: Team) => {
    const sortedMembers: Member[] =
      (team.members && [...team.members].sort((a, b) => (a.is_team === b.is_team ? 0 : a.is_team ? 1 : -1))) || [];
    //Count members that are not subteams
    const directMembers = sortedMembers.filter((member) => !member.is_team);

    if (searchText && !checkSearchedName(team)) {
      return null;
    }
    return (
      <Box key={team.team_id}>
        {selectedTeamData && selectedTeamData.team_name === team.team_name && (
          <MainTeamContainer>
            {selectedTeamData && selectedTeamData.team_name === team.team_name && renderManagers(team)}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}>
              {directMembers.length !== 0 && (
                <Grid container display="flex" flexDirection="row" justifyContent="flex-start">
                  <GridItem title={'TEAM MEMBER'} seenOrIsHeading={true} breakpoint={7} />
                  <GridItem title={'ROLE'} seenOrIsHeading={true} breakpoint={3} />
                </Grid>
              )}

              {team.members.length > 0 &&
                sortedMembers.map((member) => {
                  if (member.is_team) {
                    return teamContainer(member.team);
                  } else {
                    return renderEmployee(team, member);
                  }
                })}
            </Box>
          </MainTeamContainer>
        )}
        {/* RENDER PODS */}
        {selectedTeamData && team.team_name !== selectedTeamData.team_name && (
          <>
            <Accordion
              expanded={searchText ? checkSearchedName(team) : openTeams.includes(team.team_id)}
              disableGutters
              onChange={(e) => {
                e.stopPropagation();
                if (openTeams.includes(team.team_id)) {
                  setOpenTeams(openTeams.filter((openTeamID) => openTeamID !== team.team_id));
                } else {
                  setOpenTeams([...openTeams, team.team_id]);
                }
              }}
              elevation={0}
              square
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderBottom: 'none',
              }}>
              <PodAccordionSummary
                sx={{ paddingRight: editMode ? '8px' : '16px' }}
                expandIcon={!editMode && <ArrowForwardIos sx={{ fontSize: '0.8rem' }} />}>
                <Typography sx={{ alignSelf: 'center' }} variant="h6">
                  {team.team_name}
                </Typography>
                {editMode && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <CustomButton
                      variant="contained_grey"
                      text={'Add Manager'}
                      width="medium"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPod(team);
                        setModalView('addManager');
                      }}
                      toolTip={`Add Manager to ${team.team_name} `}
                    />
                    <CustomButton
                      variant="contained"
                      text={<AddIcon />}
                      width="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPod(team);
                        setModalView('newTeamMember');
                      }}
                      toolTip={`Add Member to ${team.team_name} `}
                    />

                    {/*  // REMOVED UNTIL BACK END EDIT FT COMPLETE // */}
                    {/* <CustomButton
                      variant="contained_grey"
                      text={<EditIcon />}
                      width="small"
                      // onClick={() => onEditWorklog(worklog)}
                      toolTip="Edit Worklog"
                    /> */}
                    {isAdmin && (
                      <CustomButton
                        variant="contained_red"
                        text={<CloseIcon />}
                        width="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMember(team.team_id, true, false, selectedTeamData.team_id);
                        }}
                        toolTip={`Delete ${team.team_name} `}
                      />
                    )}
                  </Box>
                )}
              </PodAccordionSummary>
              <AccordionDetails sx={{ padding: '16px 0px 0px 0px' }}>
                {renderManagers(team)}
                {team.members.length === 0 ? (
                  <Typography sx={{ width: '100%', paddingLeft: '16px' }} variant="body2">
                    NO MEMBERS
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      paddingTop: '8px',
                    }}>
                    <Grid container display="flex" flexDirection="row" justifyContent="flex-start">
                      <GridItem title={'TEAM MEMBER'} seenOrIsHeading={true} breakpoint={7} />
                      <GridItem title={'ROLE'} seenOrIsHeading={true} breakpoint={3} />
                    </Grid>
                    {team &&
                      sortedMembers.map((member) => {
                        if (member.is_team) {
                          return teamContainer(member.team);
                        } else {
                          return <Box key={member.member_id}>{renderEmployee(team, member)}</Box>;
                        }
                      })}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    );
  };

  const TeamViews = () => {
    return (
      <TeamsViewContainer>
        <SectionHeader>
          <Typography variant="h4">{selectedTeamData?.team_name}</Typography>
          {editMode && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomButton
                variant="contained_grey"
                text={'Add Manager'}
                width="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPod(selectedTeamData);
                  setModalView('addManager');
                }}
                toolTip={`Add Manager to ${selectedTeamData?.team_name} `}
              />
              <CustomButton
                variant="contained"
                text={<AddIcon />}
                width="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPod(selectedTeamData);
                  setModalView('newTeamMember');
                }}
                toolTip={`Add Member to ${selectedTeamData?.team_name} `}
              />
              {/*  // REMOVED UNTIL BACK END EDIT FT COMPLETE // */}
              {/* <CustomButton
                variant="contained_grey"
                text={<EditIcon />}
                width="small"
                // onClick={() => onEditWorklog(worklog)}
                toolTip="Edit Worklog"
              /> */}
              {isAdmin && (
                <CustomButton
                  variant="contained_red"
                  text={<CloseIcon />}
                  width="small"
                  onClick={handleClickDelete}
                  toolTip={`Delete ${selectedTeamData?.team_name} `}
                />
              )}
            </Box>
          )}
        </SectionHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '0',
            overflowY: 'auto',
          }}>
          {selectedTeamData && teamContainer(selectedTeamData)}
        </Box>
      </TeamsViewContainer>
    );
  };

  return (
    <>
      {handleModalView()}
      <Loader open={isFetching} />
      <PageContainer>
        {filterPanel && (
          <FilterPanelContainer>
            {!isMobileScreen && (
              <HeaderBack>
                <ArrowBackIos
                  sx={{
                    color: theme.palette.text.disabled,
                    fontSize: '0.8rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/teams')}
                />
                <Typography variant="h4">Teams</Typography>
              </HeaderBack>
            )}
            {/* {OverViewSwitch()} */}
            <OutlinedInput
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Search Employee"
              endAdornment={
                <InputAdornment position="end">
                  {searchText ? (
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              }
              sx={{
                height: '32px',
              }}
            />
            <FilterButtons>
              <CustomButton
                onClick={() => setEditMode(!editMode)}
                variant={'contained_grey'}
                width="100%"
                text={editMode ? 'Save Team' : 'Edit Team'}
              />
              <CustomButton
                onClick={() => handleSetModalView('newPod')}
                width={'100%'}
                variant="contained"
                text={'Add Pod'}
              />
            </FilterButtons>
          </FilterPanelContainer>
        )}
        {TeamViews()}
        {/* {PerformanceView()} */}
      </PageContainer>
      <SnackBar message={message} onClose={() => setMessage(null)} />
    </>
  );
};

export default TeamDetail;
